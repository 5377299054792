import React, { useEffect } from 'react';
import G6 from '@antv/g6';
import { ChartLoading } from '../../utils';
import ErrorBoundary from '../../errorBoundary';
import useGraph from '../hooks/useGraph';
import { defaultNodeSize, defaultLabelCfg, defaultStateStyles } from './constants';
import { renderMinimap, getGraphSize, getGraphId, renderGraph, bindEvents, useProps } from './utils';
import { registerCustomItems } from './customItems';
registerCustomItems();
var defaultNodeStyle = {
    stroke: '#40a9ff',
};
var defaultNodeAnchorPoints = [
    [0, 0.5],
    [1, 0.5],
];
var defaultEdgeStyle = {
    stroke: '#ccc',
    endArrow: {
        path: G6.Arrow.vee(10, 10),
        fill: '#ccc',
    },
};
var defaultLayout = {
    type: 'indented',
    direction: 'LR',
    dropCap: false,
    indent: 250,
    getHeight: function () {
        return 60;
    },
    getWidth: function () {
        return 100;
    },
};
var defaultProps = {
    nodeType: 'card-node',
    edgeType: 'cubic-horizontal',
    behaviors: ['zoom-canvas', 'drag-canvas'],
    nodeSize: defaultNodeSize,
    nodeLabelCfg: defaultLabelCfg,
    nodeAnchorPoints: defaultNodeAnchorPoints,
    layout: defaultLayout,
    nodeStyle: defaultNodeStyle,
    edgeStyle: defaultEdgeStyle,
    nodeStateStyles: defaultStateStyles,
    edgeStateStyles: defaultStateStyles,
    collapseExpand: true,
    autoFit: true,
};
var graphs = {};
var IndentedTree = function (props) {
    var uProps = useProps(props, defaultProps);
    var data = uProps.data, className = uProps.className, style = uProps.style, width = uProps.width, height = uProps.height, _a = uProps.nodeType, nodeType = _a === void 0 ? 'card-node' : _a, _b = uProps.edgeType, edgeType = _b === void 0 ? 'cubic-horizontal' : _b, _c = uProps.behaviors, behaviors = _c === void 0 ? ['zoom-canvas', 'drag-canvas'] : _c, _d = uProps.nodeSize, nodeSize = _d === void 0 ? defaultNodeSize : _d, _e = uProps.nodeLabelCfg, nodeLabelCfg = _e === void 0 ? defaultLabelCfg : _e, _f = uProps.nodeAnchorPoints, nodeAnchorPoints = _f === void 0 ? defaultNodeAnchorPoints : _f, _g = uProps.layout, layout = _g === void 0 ? defaultLayout : _g, minimapCfg = uProps.minimapCfg, _h = uProps.nodeStyle, nodeStyle = _h === void 0 ? defaultNodeStyle : _h, _j = uProps.edgeStyle, edgeStyle = _j === void 0 ? defaultEdgeStyle : _j, _k = uProps.nodeStateStyles, nodeStateStyles = _k === void 0 ? defaultStateStyles : _k, _l = uProps.edgeStateStyles, edgeStateStyles = _l === void 0 ? defaultStateStyles : _l, _m = uProps.collapseExpand, collapseExpand = _m === void 0 ? true : _m, _o = uProps.autoFit, autoFit = _o === void 0 ? true : _o, handleNodeClick = uProps.handleNodeClick, graphRef = uProps.graphRef, onReady = uProps.onReady, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate;
    var graph = React.useRef(null);
    var graphId = getGraphId(graph);
    var container = React.useRef(null);
    useGraph(graphs[graphId], uProps, container);
    useEffect(function () {
        var graphSize = getGraphSize(width, height, container);
        var graph = graphs[graphId];
        if (!graph) {
            graph = new G6.TreeGraph({
                container: container.current,
                width: graphSize[0],
                height: graphSize[1],
                modes: {
                    default: behaviors,
                },
                defaultNode: {
                    type: nodeType,
                    size: nodeSize,
                    style: nodeStyle,
                    anchorPoints: nodeAnchorPoints,
                    labelCfg: nodeLabelCfg,
                },
                defaultEdge: {
                    type: edgeType,
                    style: edgeStyle,
                },
                nodeStateStyles: nodeStateStyles,
                edgeStateStyles: edgeStateStyles,
                layout: layout,
                fitView: autoFit,
            });
            graphs[graphId] = graph;
        }
        if (graphRef) {
            graphRef.current = graph;
        }
        renderMinimap(minimapCfg, graph);
        renderGraph(graph, data);
        if (onReady) {
            onReady(graph);
        }
        if (collapseExpand) {
            var onClick_1 = function (e) {
                var item = e.item;
                if (e.target.get('name') === 'collapse-icon') {
                    graph.updateItem(item, {
                        collapsed: !item.getModel().collapsed,
                    });
                    graph.layout();
                }
                else if (handleNodeClick) {
                    handleNodeClick(item, graph);
                }
            };
            graph.on('node:click', function (e) {
                onClick_1(e);
            });
            graph.on('node:touchstart', function (e) {
                onClick_1(e);
            });
        }
        bindEvents(graph, props);
        return function () {
            if (graphs[graphId]) {
                graphs[graphId].destroy();
                delete graphs[graphId];
            }
        };
    }, []);
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default IndentedTree;
