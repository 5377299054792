var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import G6 from '@antv/g6';
import { ChartLoading } from '../../../utils';
import ErrorBoundary from '../../../errorBoundary';
import useGraph from '../../hooks/useGraph';
import { registerIconNode } from '../customItems';
import { defaultLabelStyle, defaultStateStyles, defaultNodeSize } from '../constants';
import { getGraphSize, processMinimap, getGraphId, renderGraph, getDefaultEdgeArrowCfg, useProps } from '../utils';
var defaultNodeStyle = {
    fill: '#91d5ff',
    stroke: '#40a9ff',
    radius: 2,
};
var defaultLayout = {
    type: 'compactBox',
    direction: 'TB',
    getId: function getId(d) {
        return d.id;
    },
    getHeight: function getHeight() {
        return 16;
    },
    getWidth: function getWidth() {
        return 16;
    },
    getVGap: function getVGap() {
        return 40;
    },
    getHGap: function getHGap() {
        return 70;
    },
};
var defaultProps = {
    animate: true,
    nodeType: 'rect',
    edgeType: 'polyline',
    nodeSize: defaultNodeSize,
    behaviors: ['drag-canvas', 'zoom-canvas'],
    nodeLabelCfg: {
        style: defaultLabelStyle,
    },
    layout: defaultLayout,
    showMarker: false,
    showArrow: true,
    arrowType: 'triangle',
    nodeStateStyles: defaultStateStyles,
    edgeStateStyles: defaultStateStyles,
    autoFit: true,
    style: {
        height: 'inherit',
    },
};
var graphs = {};
var OrganizationalGraph = function (props) {
    var uProps = useProps(props, defaultProps);
    var data = uProps.data, className = uProps.className, style = uProps.style, width = uProps.width, height = uProps.height, _a = uProps.animate, animate = _a === void 0 ? true : _a, _b = uProps.nodeType, nodeType = _b === void 0 ? 'rect' : _b, _c = uProps.edgeType, edgeType = _c === void 0 ? 'polyline' : _c, _d = uProps.nodeSize, nodeSize = _d === void 0 ? defaultNodeSize : _d, _e = uProps.behaviors, behaviors = _e === void 0 ? ['drag-canvas', 'zoom-canvas'] : _e, nodeLabelCfg = uProps.nodeLabelCfg, nodeCfg = uProps.nodeCfg, _f = uProps.layout, layout = _f === void 0 ? defaultLayout : _f, _g = uProps.showMarker, showMarker = _g === void 0 ? false : _g, _h = uProps.showArrow, showArrow = _h === void 0 ? true : _h, _j = uProps.arrowType, arrowType = _j === void 0 ? 'triangle' : _j, minimapCfg = uProps.minimapCfg, edgeCfg = uProps.edgeCfg, markerStyle = uProps.markerStyle, _k = uProps.nodeStateStyles, nodeStateStyles = _k === void 0 ? defaultStateStyles : _k, _l = uProps.edgeStateStyles, edgeStateStyles = _l === void 0 ? defaultStateStyles : _l, _m = uProps.autoFit, autoFit = _m === void 0 ? true : _m, onReady = uProps.onReady, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate;
    var container = React.useRef(null);
    var graph = React.useRef(null);
    var graphId = getGraphId(graph);
    useGraph(graphs[graphId], uProps, container);
    var arrowOffset = (Array.isArray(nodeSize) ? nodeSize[1] : nodeSize) / 2;
    useEffect(function () {
        var graphSize = getGraphSize(width, height, container);
        if (nodeType === 'icon-node') {
            registerIconNode();
        }
        var graph = graphs[graphId];
        if (!graph) {
            graph = new G6.TreeGraph({
                container: container.current,
                width: graphSize[0],
                height: graphSize[1],
                linkCenter: true,
                animate: animate,
                modes: {
                    default: behaviors,
                },
                defaultNode: {
                    type: nodeType,
                    size: nodeSize,
                    labelCfg: nodeLabelCfg,
                    markerStyle: markerStyle,
                    showMarker: showMarker,
                },
                defaultEdge: {
                    type: edgeType,
                },
                nodeStateStyles: nodeStateStyles,
                edgeStateStyles: edgeStateStyles,
                layout: layout,
                fitView: autoFit,
            });
            graphs[graphId] = graph;
        }
        graph.node(function (node) {
            if (typeof nodeCfg === 'function') {
                return nodeCfg(node, graph);
            }
            return {
                style: __assign(__assign({}, defaultNodeStyle), nodeCfg === null || nodeCfg === void 0 ? void 0 : nodeCfg.style),
            };
        });
        graph.edge(function (edge) {
            if (typeof edgeCfg === 'function') {
                return edgeCfg(edge, graph);
            }
            return __assign(__assign({}, edgeCfg), { style: __assign(__assign({ stroke: '#91d5ff' }, (showArrow && getDefaultEdgeArrowCfg(arrowOffset, arrowType, '#91d5ff'))), edgeCfg === null || edgeCfg === void 0 ? void 0 : edgeCfg.style) });
        });
        processMinimap(minimapCfg, graph);
        renderGraph(graph, data);
        if (onReady) {
            onReady(graph);
        }
        return function () {
            if (graphs[graphId]) {
                graphs[graphId].destroy();
                delete graphs[graphId];
            }
        };
    }, []);
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default OrganizationalGraph;
