var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import G6 from '@antv/g6';
import { isFunction } from '@antv/util';
import { createNode } from '../utils';
import { uuid } from '../utils/uuid';
export var processTooltip = function (cfg, graph) {
    if (cfg === void 0) { cfg = {}; }
    if (!graph || graph.destroyed) {
        return;
    }
    var pluginTooltip = graph.get('plugins').filter(function (plugin) { return plugin.get('name') === 'tooltip'; })[0];
    if (pluginTooltip) {
        graph.removePlugin(pluginTooltip);
    }
    // 兼容旧数据
    if (cfg.show || (cfg.show === undefined && Object.keys(cfg).length > 0)) {
        var customContent_1 = cfg.customContent, rest = __rest(cfg, ["customContent"]);
        var _uuid_1 = uuid();
        var tooltip = new G6.Tooltip(__assign(__assign({ offsetX: 10, offsetY: 20, itemTypes: ['node'] }, rest), { getContent: function (e) {
                return isFunction(customContent_1)
                    ? createNode(customContent_1(e.item.getModel()), {
                        className: 'g6-tooltip',
                    }, 'tooltip', _uuid_1)
                    : '';
            }, name: 'tooltip' }));
        graph.addPlugin(tooltip);
    }
};
