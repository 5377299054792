/**
 * 挂载异步数据到全局 data
 */
export var setLevelData = function (graph, data, currentPath) {
    var currentData = graph.get('eventData').getData();
    // 打标时已经做了编码，这直接取值即可
    var path = currentPath.split('-');
    path.shift(); // 根节点没有 path
    var current = currentData;
    path.forEach(function (childrenIndex) {
        current = current.children[Number(childrenIndex)];
    });
    current.children = data;
};
