var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import { defaultCardStyle } from '../constants';
import { getMarkerPosition } from './get-marker-position';
export var createMarker = function (cfg, group, size, suffix) {
    var show = cfg.show, position = cfg.position, collapsed = cfg.collapsed, style = cfg.style;
    if (show) {
        group.addShape('marker', {
            attrs: __assign(__assign(__assign({}, getMarkerPosition(position, size)), { r: 6, cursor: 'pointer', symbol: collapsed ? G6.Marker.expand : G6.Marker.collapse, stroke: defaultCardStyle.stroke, lineWidth: 1, fill: '#fff' }), style),
            defaultCollapsed: false,
            name: suffix ? "collapse-icon-".concat(position) : 'collapse-icon',
            position: position,
        });
    }
};
