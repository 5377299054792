import { useEffect, useState } from 'react';
import { Log } from '../utils';
var useFullscreen = function (el) {
    var _a = useState(false), fullscreen = _a[0], setFullscreen = _a[1];
    var handleFullScreenChange = function () {
        // if exit fullscreen
        if (!document.fullscreenElement) {
            setFullscreen(false);
        }
    };
    var enterFullscreen = function () {
        if (el && el.requestFullscreen) {
            el.requestFullscreen()
                .then(function () {
                setFullscreen(true);
            })
                .catch(function (err) {
                Log.error('requestFullscreen error: ', err);
            });
        }
    };
    var exitFullscreen = function () {
        if (document.exitFullscreen) {
            document
                .exitFullscreen()
                .then(function () {
                setFullscreen(false);
            })
                .catch(function (err) {
                Log.error('exitFullscreen error: ', err);
            });
        }
    };
    var toggleFullscreen = function () {
        // 切换是否全屏
        if (!el) {
            Log.error('need dom');
            return;
        }
        if (!fullscreen) {
            enterFullscreen();
        }
        else {
            exitFullscreen();
        }
    };
    useEffect(function () {
        // 用户按Esc键退出全屏 或者 退出全屏都会触发这个事件
        document.addEventListener('fullscreenchange', handleFullScreenChange, false);
        return function () {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);
    return [fullscreen, toggleFullscreen];
};
export default useFullscreen;
