var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Menu from './components/menu';
export var processMenu = function (cfg, graph) {
    if (cfg === void 0) { cfg = {}; }
    if (!graph || graph.destroyed) {
        return;
    }
    // 兼容旧逻辑
    var pluginMenu = graph.get('plugins').filter(function (plugin) { return plugin.get('name') === 'menu'; })[0];
    if (pluginMenu) {
        graph.removePlugin(pluginMenu);
    }
    if (cfg.show || (cfg.show === undefined && Object.keys(cfg).length > 0)) {
        var menu = new Menu(__assign(__assign({ offsetX: 16 + 10, offsetY: 0, itemTypes: ['node'] }, cfg), { name: 'menu' }));
        graph.addPlugin(menu);
    }
};
