var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MARKER_CLICK, prefix } from '../../constants';
import { createFetchLoading, closeFetchLoading, isType, EventData } from '../../utils';
export var bindEvents = function (params) {
    var graph = params.graph, level = params.level, asyncData = params.asyncData, fetchLoading = params.fetchLoading;
    var changeData = function (data, eventData) {
        if (eventData)
            graph.set('eventData', new EventData(eventData));
        graph.changeData(data);
        if (graph.get('fitCenter')) {
            graph.fitCenter();
        }
    };
    /**
     * 通过递归获取所有关联的需要更新的节点
     * @param nodeId 目标节点ID
     * @param fullEdges 所有的边数据
     * @param expandDirection 展开收起的方向，也会决定递归的方向
     * @returns
     */
    var getNodeTargets = function (nodeId, fullEdges, expandDirection) {
        if (expandDirection === void 0) { expandDirection = 'right'; }
        var allTargets = [];
        var getLinkedId = function (currentId) {
            fullEdges.forEach(function (edge) {
                var source = edge.source, target = edge.target;
                switch (expandDirection) {
                    case 'left':
                        if (target === currentId && !allTargets.includes(source)) {
                            allTargets.push(source);
                            getLinkedId(source);
                        }
                        break;
                    case 'right':
                        if (source === currentId && !allTargets.includes(target)) {
                            allTargets.push(target);
                            getLinkedId(target);
                        }
                        break;
                    default:
                }
            });
        };
        getLinkedId(nodeId);
        // 避免成环的情况
        allTargets = allTargets.filter(function (t) { return t !== nodeId; });
        return allTargets;
    };
    /**
     *
     * @param e 事件对象
     * @param nodeId 当前节点的ID
     * @param collapsed 当前折叠状态，handle时要反着处理
     * @returns
     */
    var handleNodeCollapse = function (e, nodeId, collapsed) { return __awaiter(void 0, void 0, void 0, function () {
        var markerCfg, controlData, _a, _b, fullEdges, fullNodes, item, model, markerCfgValue, allTargets, _c, currentNodes, currentEdges, concatNodes, currentData, _d, asyncNodes_1, asyncEdges_1, getDataByEvent;
        var _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    markerCfg = (_e = graph === null || graph === void 0 ? void 0 : graph['cfg']) === null || _e === void 0 ? void 0 : _e.markerCfg;
                    controlData = graph.get('eventData').getData();
                    _a = controlData !== null && controlData !== void 0 ? controlData : {}, _b = _a.edges, fullEdges = _b === void 0 ? [] : _b, fullNodes = _a.nodes;
                    item = e.item;
                    model = item.getModel();
                    markerCfgValue = typeof markerCfg === 'function' ? markerCfg(item.getModel(), item.get('group')) : markerCfg;
                    allTargets = getNodeTargets(nodeId, fullEdges, (_f = markerCfgValue === null || markerCfgValue === void 0 ? void 0 : markerCfgValue.expandDirection) !== null && _f !== void 0 ? _f : 'right');
                    if (!!collapsed) return [3 /*break*/, 1];
                    // collapse
                    graph.findAll('node', function (node) { return allTargets.includes(node.get('id')); }).forEach(function (node) { return graph.hideItem(node); });
                    fullNodes.forEach(function (node) {
                        var _a = node.collapsedLevel, collapsedLevel = _a === void 0 ? 0 : _a, id = node.id;
                        if (allTargets.includes(id)) {
                            node.collapsedLevel = collapsedLevel + 1;
                        }
                    });
                    return [3 /*break*/, 4];
                case 1:
                    _c = graph.get('data'), currentNodes = _c.nodes, currentEdges = _c.edges;
                    if (!allTargets.length) return [3 /*break*/, 2];
                    // 已经展开过
                    if (graph.findById(allTargets[0])) {
                        allTargets.forEach(function (id) {
                            var n = graph.findById(id);
                            if (n) {
                                var collapsedLevel = n.getModel().collapsedLevel;
                                if (!collapsedLevel || collapsedLevel < 2)
                                    graph.showItem(n);
                            }
                        });
                        fullNodes.forEach(function (node) {
                            var _a = node.collapsedLevel, collapsedLevel = _a === void 0 ? 0 : _a, id = node.id;
                            if (allTargets.includes(id)) {
                                node.collapsedLevel = collapsedLevel - 1;
                            }
                        });
                    }
                    else {
                        concatNodes = void 0;
                        if (level) {
                            concatNodes = fullNodes.filter(function (n) {
                                return allTargets.includes(n.id) && n["".concat(prefix, "_level")] === model["".concat(prefix, "_level")] + 1;
                            });
                            allTargets = concatNodes.map(function (n) { return n.id; });
                        }
                        currentData = {
                            nodes: currentNodes
                                .map(function (n) {
                                if (n.id === nodeId)
                                    n["".concat(prefix, "_children")] = allTargets;
                                return n;
                            })
                                .concat(concatNodes),
                            edges: currentEdges.concat(fullEdges.filter(function (e) { return e.source === nodeId; })),
                        };
                        changeData(currentData);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    if (!asyncData) return [3 /*break*/, 4];
                    createFetchLoading(item.getModel(), fetchLoading);
                    return [4 /*yield*/, asyncData(item.getModel())];
                case 3:
                    _d = _g.sent(), asyncNodes_1 = _d.nodes, asyncEdges_1 = _d.edges;
                    // modify current node collapsed status
                    graph.updateItem(item, {
                        collapsed: false,
                    });
                    graph.refreshItem(item);
                    closeFetchLoading();
                    getDataByEvent = function (nodes, edges) {
                        return {
                            nodes: nodes
                                .map(function (n) {
                                if (n.id === nodeId)
                                    n["".concat(prefix, "_children")] = asyncNodes_1.map(function (n) { return n.id; });
                                return n;
                            })
                                .concat(asyncNodes_1),
                            edges: edges.concat((asyncEdges_1 === null || asyncEdges_1 === void 0 ? void 0 : asyncEdges_1.length) ? asyncEdges_1 : asyncNodes_1.map(function (t) { return ({ source: nodeId, target: t.id }); })),
                        };
                    };
                    changeData(getDataByEvent(currentNodes, currentEdges), getDataByEvent(fullNodes, fullEdges));
                    graph.emit(MARKER_CLICK, e, {
                        type: 'fetch',
                        collapsed: true,
                    });
                    return [2 /*return*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onClick = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var controlData, item, model, nodeId_1, collapsed_1, _a, _b, fullEdges, fullNodes, updateItems_1, updateIds_1, updateNodeTarget_1;
        var _c, _d;
        return __generator(this, function (_e) {
            controlData = graph.get('eventData').getData();
            if ((_c = e.target.get('name')) === null || _c === void 0 ? void 0 : _c.startsWith('collapse-icon')) {
                item = e.item;
                model = item.getModel();
                nodeId_1 = model.id, collapsed_1 = model.collapsed;
                if (!isType(collapsed_1, 'Boolean')) {
                    // @ts-ignore
                    collapsed_1 = (_d = item._cfg.group
                        .getChildren()
                        .find(function (t) { return t.get('name') === 'main-box'; })) === null || _d === void 0 ? void 0 : _d.attr('defaultCollapsed');
                }
                _a = controlData !== null && controlData !== void 0 ? controlData : {}, _b = _a.edges, fullEdges = _b === void 0 ? [] : _b, fullNodes = _a.nodes;
                updateItems_1 = [];
                updateIds_1 = [];
                // 处理节点的 展开 / 收起，获取所有待更新节点的数据
                handleNodeCollapse(e, nodeId_1, collapsed_1);
                updateNodeTarget_1 = fullEdges.find(function (edge) { return edge.source === nodeId_1; }).target;
                fullEdges.forEach(function (edge) {
                    var source = edge.source, target = edge.target;
                    if (target === updateNodeTarget_1) {
                        updateIds_1.push(source);
                    }
                });
                // 更新节点 Update Nodes
                Array.from(new Set(updateIds_1)).forEach(function (id) {
                    updateItems_1.push(graph.find('node', function (node) { return node.get('id') === id; }));
                });
                updateItems_1.forEach(function (nodeItem) {
                    graph.updateItem(nodeItem, {
                        collapsed: !collapsed_1,
                    });
                    graph.refreshItem(nodeItem);
                });
                // 更新折叠图标 Update Markers
                graph.emit(MARKER_CLICK, e, {
                    type: 'collapse',
                    collapsed: !!collapsed_1,
                });
            }
            return [2 /*return*/];
        });
    }); };
    graph.on('node:click', function (e) {
        onClick(e);
    });
};
