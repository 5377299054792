var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { findIndex } from '@antv/util';
import { radialSectorLayout } from '../../layout';
import { createFetchLoading, closeFetchLoading } from '../../utils';
/** sector layout */
export var bindRadialExplore = function (params) {
    var graph = params.graph, asyncData = params.asyncData, layoutCfg = params.layout, fetchLoading = params.fetchLoading;
    var onDblClick = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var item, itemModel, newData, nodes, edges, x, y, centerNodeId, centerNode, _a, centerX, centerY, pureNodes, pureEdges, allNodeModels, allEdgeModels;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    item = e.item;
                    itemModel = item.getModel();
                    createFetchLoading(itemModel, fetchLoading);
                    return [4 /*yield*/, asyncData(item.getModel())];
                case 1:
                    newData = _b.sent();
                    closeFetchLoading();
                    nodes = graph.getNodes();
                    edges = graph.getEdges();
                    x = itemModel.x, y = itemModel.y;
                    centerNodeId = graph.get('centerNode');
                    centerNode = centerNodeId ? graph.findById(centerNodeId) : nodes[0];
                    _a = centerNode.getModel(), centerX = _a.x, centerY = _a.y;
                    pureNodes = newData.nodes.filter(function (item) { return findIndex(nodes, function (t) { return t.getModel().id === item.id; }) === -1; });
                    pureEdges = newData.edges.filter(function (item) {
                        return findIndex(edges, function (t) {
                            var _a = t.getModel(), source = _a.source, target = _a.target;
                            return source === item.source && target === item.target;
                        }) === -1;
                    });
                    allNodeModels = [];
                    allEdgeModels = [];
                    pureNodes.forEach(function (nodeModel) {
                        // set the initial positions of the new nodes to the focus(clicked) node
                        nodeModel.x = itemModel.x;
                        nodeModel.y = itemModel.y;
                        graph.addItem('node', nodeModel);
                    });
                    // add new edges to graph
                    pureEdges.forEach(function (em, i) {
                        graph.addItem('edge', em);
                    });
                    edges.forEach(function (e) {
                        allEdgeModels.push(e.getModel());
                    });
                    nodes.forEach(function (n) {
                        allNodeModels.push(n.getModel());
                    });
                    // 这里使用了引用类型
                    radialSectorLayout({
                        center: [centerX, centerY],
                        eventNodePosition: [x, y],
                        nodes: nodes.map(function (n) { return n.getModel(); }),
                        layoutNodes: pureNodes,
                        options: layoutCfg,
                    });
                    graph.positionsAnimate();
                    graph.data({
                        nodes: allNodeModels,
                        edges: allEdgeModels,
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    graph.on('node:dblclick', function (e) {
        onDblClick(e);
    });
};
