import { defaultFlowGraphAnchorPoints } from '../constants';
/** 支持自定义 anchor */
export var getAnchorPoints = function (anchorPoints, node) {
    if (typeof anchorPoints === 'function' && node) {
        return anchorPoints(node) || {};
    }
    if (Array.isArray(anchorPoints)) {
        return anchorPoints;
    }
    return defaultFlowGraphAnchorPoints;
};
