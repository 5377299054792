import { getSize } from './get-size';
import { defaultStatusBarWidth } from '../constants';
/**
 * card status 对布局的影响，直接加到 padding 中
 */
export var getStatusBBox = function (cfg) {
    if (!cfg) {
        return [0, 0, 0, 0];
    }
    var _a = cfg.size, size = _a === void 0 ? [] : _a, _b = cfg.position, position = _b === void 0 ? 'left' : _b;
    var _c = getSize(size), width = _c[0], height = _c[1];
    var appendPadding = [];
    switch (position) {
        case 'top':
            appendPadding.push(height !== null && height !== void 0 ? height : defaultStatusBarWidth, 0, 0, 0);
            break;
        case 'right':
            appendPadding.push(0, width !== null && width !== void 0 ? width : defaultStatusBarWidth, 0, 0);
            break;
        case 'bottom':
            appendPadding.push(0, 0, height !== null && height !== void 0 ? height : defaultStatusBarWidth, 0);
            break;
        case 'left':
            appendPadding.push(0, 0, 0, width !== null && width !== void 0 ? width : defaultStatusBarWidth);
            break;
    }
    return appendPadding;
};
