var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ITEM_STATE } from '../types';
import { getArrowConfig } from './edge';
import { BASE_STATES, SIZE_IMPACT_STATES } from '../constants';
// 获取节点状态样式
export var getNodeStateStyles = function (state) {
    var _a;
    var stateStyles = (_a = {},
        _a[ITEM_STATE.Selected] = {
            'node-key-shape': {
                lineWidth: 14,
            },
            'node-inner-border-shape': {
                lineWidth: 2,
            },
        },
        _a[ITEM_STATE.Active] = {
            'node-key-shape': {
                lineWidth: 6,
            },
            'node-inner-border-shape': {
                lineWidth: 1,
            },
        },
        _a[ITEM_STATE.Default] = {
            'node-key-shape': {
                lineWidth: 0,
            },
            'node-inner-border-shape': {
                lineWidth: 1,
            },
        },
        _a);
    return __assign({}, stateStyles[state]);
};
// 获取边line的状态样式
export var getEdgeStateStyles = function (state, edge) {
    var _a;
    var _b = edge.style, highlightColor = _b.highlightColor, stroke = _b.stroke, labelFill = _b.labelFill;
    var stateStyles = (_a = {},
        _a[ITEM_STATE.Selected] = {
            'path-shape': {
                stroke: highlightColor,
                endArrow: getArrowConfig(highlightColor),
            },
            'label-shape': {
                fill: highlightColor,
            },
        },
        _a[ITEM_STATE.Active] = {
            'path-shape': {
                stroke: highlightColor,
                endArrow: getArrowConfig(highlightColor),
            },
            'label-shape': {
                fill: highlightColor,
            },
        },
        _a[ITEM_STATE.Default] = {
            'path-shape': {
                stroke: stroke,
                endArrow: getArrowConfig(stroke),
            },
            'label-shape': {
                fill: labelFill,
            },
        },
        _a);
    return __assign({}, stateStyles[state]);
};
// 设置元素状态样式
export var setItemStateStyle = function (item, type) {
    var _a;
    // 获取元素的数据模型
    var model = item.getModel();
    var states = item.getStates();
    // 获取元素状态
    var state = states[states.length - 1] || ITEM_STATE.Default;
    // 获取元素在该state下的各shape的style
    var style = type === 'node' ? getNodeStateStyles(state) : getEdgeStateStyles(state, model);
    // 获取元素的所有shape
    var shapes = (_a = item.getContainer()) === null || _a === void 0 ? void 0 : _a.get('children');
    // 更新各shape的style
    shapes === null || shapes === void 0 ? void 0 : shapes.forEach(function (shape) {
        var shapeName = shape.get('name');
        if (style[shapeName]) {
            shape.attr(style[shapeName]);
        }
    });
};
// 一次绘制
export var paintOnce = function (graph, fn) {
    var autoPaint = graph.get('autoPaint');
    graph.setAutoPaint(false);
    fn();
    graph.paint();
    graph.setAutoPaint(autoPaint);
};
// 清空元素状态
export var clearItemStates = function (graph, graphItem, states, enablePaint) {
    if (enablePaint === void 0) { enablePaint = false; }
    function fn() {
        states.forEach(function (state) {
            if (graphItem === null || graphItem === void 0 ? void 0 : graphItem.hasState(state)) {
                graph.setItemState(graphItem, state, false);
                // item.setState(state, false);
                // 对尺寸 style 有影响的 state 才需要 refresh ，重新计算位置和边界
                // if (Size_IMPACT_STATES.includes(state)) {
                graphItem.refresh();
                // }
            }
        });
    }
    if (enablePaint) {
        paintOnce(graph, function () {
            fn();
        });
    }
    else {
        fn();
    }
};
// 清空指定元素集合的状态
export var clearItemsStates = function (graph, items, clearStates, enablePaint) {
    if (enablePaint === void 0) { enablePaint = false; }
    function fn() {
        items.forEach(function (graphItem) {
            try {
                clearItemStates(graph, graphItem, clearStates, false);
            }
            catch (error) {
                console.log('error :>> ', graphItem, error);
            }
        });
    }
    if (enablePaint) {
        paintOnce(graph, function () {
            fn();
        });
    }
    else {
        fn();
    }
};
// 设置元素状态
export var setItemState = function (graph, graphItem, state, value, enablePaint) {
    if (enablePaint === void 0) { enablePaint = false; }
    function fn() {
        var _a;
        if (!graphItem || graphItem.destroyed) {
            return;
        }
        graph.setItemState(graphItem, state, value);
        // 对style有影响，重新计算位置和边界
        if (!((_a = graphItem.getKeyShape()) === null || _a === void 0 ? void 0 : _a.get('destroyed')) && SIZE_IMPACT_STATES.includes(state)) {
            graphItem.refresh();
        }
    }
    if (enablePaint) {
        paintOnce(graph, function () {
            fn();
        });
    }
    else {
        fn();
    }
};
// 重绘节点相关的边
export var refreshRelatedEdges = function (node) {
    node.getEdges().forEach(function (edge) {
        var _a;
        if (!((_a = edge.getKeyShape()) === null || _a === void 0 ? void 0 : _a.get('destroyed')) && edge.get('keyShape')) {
            edge.refresh();
        }
    });
};
// 重置所有节点的状态到默认认态
export var resetNodeStates = function (graph, enablePaint) {
    if (enablePaint === void 0) { enablePaint = false; }
    // 由于状态的变化，可能会造成 style 的变化，特别是大小的变化，因此在重置了 node 之后，还需要 refresh 关联的边，否则会造成边的终点连接不到节点边界
    var relatedEdges = new Set();
    var resetNodes = function () {
        BASE_STATES.forEach(function (state) {
            var stateNodes = graph.findAllByState('node', state);
            stateNodes.forEach(function (node) {
                clearItemStates(graph, node, [state], false);
                if (SIZE_IMPACT_STATES.includes(state)) {
                    node.getEdges().forEach(function (edge) { return relatedEdges.add(edge); });
                }
            });
        });
        Array.from(relatedEdges).forEach(function (edge) {
            edge.refresh();
        });
    };
    if (enablePaint) {
        paintOnce(graph, resetNodes);
    }
    else {
        resetNodes();
    }
};
// 重置所有边的状态到默认状态
export var resetEdgeStates = function (graph, enablePaint) {
    if (enablePaint === void 0) { enablePaint = false; }
    clearItemsStates(graph, graph.getEdges(), BASE_STATES, enablePaint);
};
// 重置为默认状态
export var resetBaseStates = function (graph, enablePaint) {
    if (enablePaint === void 0) { enablePaint = false; }
    resetNodeStates(graph, enablePaint);
    resetEdgeStates(graph, enablePaint);
};
// 清空激活状态
export var clearActiveStates = function (graph, enablePaint) {
    if (enablePaint === void 0) { enablePaint = false; }
    clearItemsStates(graph, graph.getNodes(), [ITEM_STATE.Active], enablePaint);
    clearItemsStates(graph, graph.getEdges(), [ITEM_STATE.Active], enablePaint);
};
