var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import { deepClone, Log } from '../../utils';
var defaultMinimapCfg = {
    show: false,
    size: [150, 100],
    type: 'keyShape',
};
export var getGraphSize = function (width, height, container) {
    var CANVAS_WIDTH;
    var CANVAS_HEIGHT;
    if (container && container.current) {
        CANVAS_WIDTH = container.current.offsetWidth;
        CANVAS_HEIGHT = container.current.offsetHeight || 500;
    }
    if ((!width && !CANVAS_WIDTH) || (!height && !CANVAS_HEIGHT)) {
        Log.warn('请为 Graph 指定 width 与 height！否则将使用默认值 500 * 500');
        return [500, 500];
    }
    return [width || CANVAS_WIDTH || 500, height || CANVAS_HEIGHT || 500];
};
export var renderMinimap = function (cfg, graph) {
    if (!graph || graph.destroyed)
        return;
    if (cfg && cfg.show) {
        var curMminimapCfg = Object.assign(defaultMinimapCfg, cfg);
        var minimap = new G6.Minimap(__assign({}, curMminimapCfg));
        graph.addPlugin(minimap);
        // return minimap;
    }
    return null;
};
var uuid = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0;
        var v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
// 同一页面存在多 graph 时需要指定 graphId
export var getGraphId = function (graph) {
    if (graph.current) {
        return graph.current;
    }
    graph.current = "IndentedTreeGraph-".concat(uuid());
    return graph.current;
};
export var renderGraph = function (graph, data) {
    var originData = deepClone(data);
    graph.data(originData);
    graph.render();
};
// 事件绑定，兼容历史数据
export var bindEvents = function (graph, props) {
    var handleEdgeClick = props.handleEdgeClick, handleEdgeHover = props.handleEdgeHover, handleEdgeUnHover = props.handleEdgeUnHover, handleNodeClick = props.handleNodeClick, handleNodeHover = props.handleNodeHover, handleNodeUnHover = props.handleNodeUnHover, handleCanvasClick = props.handleCanvasClick;
    graph.on('edge:mouseenter', function (evt) {
        var item = evt.item;
        graph.setItemState(item, 'hover', true);
        handleEdgeHover === null || handleEdgeHover === void 0 ? void 0 : handleEdgeHover(item, graph);
    });
    graph.on('edge:mouseleave', function (evt) {
        var item = evt.item;
        graph.setItemState(item, 'hover', false);
        handleEdgeUnHover === null || handleEdgeUnHover === void 0 ? void 0 : handleEdgeUnHover(item, graph);
    });
    graph.on('edge:click', function (evt) {
        var item = evt.item;
        handleEdgeClick === null || handleEdgeClick === void 0 ? void 0 : handleEdgeClick(item, graph);
    });
    graph.on('edge:touchstart', function (evt) {
        var item = evt.item;
        handleEdgeClick === null || handleEdgeClick === void 0 ? void 0 : handleEdgeClick(item, graph);
    });
    graph.on('node:mouseenter', function (evt) {
        var item = evt.item;
        graph.setItemState(item, 'hover', true);
        handleNodeHover === null || handleNodeHover === void 0 ? void 0 : handleNodeHover(item, graph);
    });
    graph.on('node:mouseleave', function (evt) {
        var item = evt.item;
        graph.setItemState(item, 'hover', false);
        handleNodeUnHover === null || handleNodeUnHover === void 0 ? void 0 : handleNodeUnHover(item, graph);
    });
    graph.on('node:click', function (evt) {
        var item = evt.item;
        handleNodeClick === null || handleNodeClick === void 0 ? void 0 : handleNodeClick(item, graph);
    });
    graph.on('canvas:click', function () {
        handleCanvasClick === null || handleCanvasClick === void 0 ? void 0 : handleCanvasClick(graph);
    });
    graph.on('canvas:touchstart', function () {
        handleCanvasClick === null || handleCanvasClick === void 0 ? void 0 : handleCanvasClick(graph);
    });
};
/**
 * 设置 props 默认值
 * props 会在对应图表和 hooks 里面使用，不想加个很长的赋值表达式。
 * layout 使用 merge
 */
export var useProps = function (props, defaultProps) {
    return __assign(__assign(__assign({}, defaultProps), props), { layout: __assign(__assign({}, defaultProps === null || defaultProps === void 0 ? void 0 : defaultProps.layout), props === null || props === void 0 ? void 0 : props.layout) });
};
