var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import { defaultMargin, defaultLabelStyle } from '../../constants';
import { getStyle, getCssPadding, getSize, getArrowCfg, createMarker } from '../../utils';
var getPathInfo = function (cfg) {
    var edgeCfg = cfg.edgeCfg;
    var startPoint = cfg.startPoint;
    var endPoint = cfg.endPoint;
    var startX = startPoint.x, startY = startPoint.y;
    var endX = endPoint.x, endY = endPoint.y;
    var Ydiff = endY - startY;
    var slope = Ydiff !== 0 ? Math.min(500 / Math.abs(Ydiff), 20) : 0;
    var cpOffset = slope > 15 ? 0 : 16;
    var offset = Ydiff < 0 ? cpOffset : -cpOffset;
    var line1EndPoint = {
        x: startX + slope,
        y: endY + offset,
    };
    var line2StartPoint = {
        x: line1EndPoint.x + cpOffset,
        y: endY,
    };
    // 控制点坐标
    var controlPoint = {
        x: ((line1EndPoint.x - startX) * (endY - startY)) / (line1EndPoint.y - startY) + startX,
        y: endY,
    };
    var path = [
        ['M', startX, startY],
        ['L', line1EndPoint.x, line1EndPoint.y],
        ['Q', controlPoint.x, controlPoint.y, line2StartPoint.x, line2StartPoint.y],
        ['L', endX, endY],
    ];
    if (Math.abs(Ydiff) <= 5) {
        path = [
            ['M', startX, startY],
            ['L', endX, endY],
        ];
    }
    var _a = edgeCfg, startArrowCfg = _a.startArrow, endArrowCfg = _a.endArrow;
    var startArrow = getArrowCfg(startArrowCfg, cfg);
    var endArrow = getArrowCfg(endArrowCfg, cfg);
    return {
        startArrow: startArrow,
        endArrow: endArrow,
        path: path,
        line2StartPoint: line2StartPoint,
        endY: endY,
    };
};
var getPathText = function (value) {
    var text;
    var subText;
    if (value instanceof Object) {
        text = value.text;
        subText = value.subText;
    }
    else {
        text = value;
    }
    return { text: text, subText: subText };
};
// 资金流向图
export var registerFundFlowItems = function () {
    // 注册节点
    G6.registerNode('fund-card', {
        // @ts-ignore
        draw: function (cfg, group) {
            var _a, _b;
            if (cfg === void 0) { cfg = {}; }
            var _c = cfg.value, value = _c === void 0 ? {} : _c, nodeCfg = cfg.nodeCfg, markerCfg = cfg.markerCfg;
            var _d = nodeCfg, _e = _d.label, label = _e === void 0 ? {} : _e, style = _d.style, _f = _d.padding, padding = _f === void 0 ? 0 : _f, customContent = _d.customContent;
            var labelStyle = label.style;
            var paddingArray = getCssPadding(padding);
            var size = getSize(cfg.size);
            var height = 0; // 统计容器总高度，动态设置
            var shapeWidth = size[0];
            var contentWidth = shapeWidth - paddingArray[1] - paddingArray[3];
            var contentHeight = size[1] - paddingArray[0] - paddingArray[2];
            // card box
            var cardStyle = getStyle(style, cfg, group);
            var shape = group.addShape('rect', {
                attrs: __assign({ x: 0, y: 0, width: size[0], height: size[1], radius: size[1] / 2, fill: '#fff', stroke: '#40a9ff' }, cardStyle),
                name: 'main-box',
                draggable: true,
            });
            if (value) {
                height += paddingArray[0];
                var createRowItems_1 = function (item, contentWidth, startX) {
                    var _a, _b;
                    var text = item.text, icon = item.icon;
                    var textShape;
                    var iconShape;
                    if (icon) {
                        iconShape = group.addShape('image', {
                            attrs: __assign({ x: startX, y: height, img: icon, width: contentHeight, height: contentHeight }, getStyle(labelStyle, cfg, group, 'icon')),
                            name: 'fund-icon',
                        });
                    }
                    textShape = group === null || group === void 0 ? void 0 : group.addShape('text', {
                        attrs: __assign(__assign({ textBaseline: 'middle', textAlign: iconShape ? 'start' : 'center', x: startX + (iconShape ? (iconShape === null || iconShape === void 0 ? void 0 : iconShape.getBBox().width) + defaultMargin : contentWidth / 2), y: paddingArray[0] + contentHeight / 2, text: text }, defaultLabelStyle), getStyle(labelStyle, cfg, group, 'text')),
                        name: "fund-text",
                    });
                    return [(_a = textShape === null || textShape === void 0 ? void 0 : textShape.getBBox().height) !== null && _a !== void 0 ? _a : 0, (_b = iconShape === null || iconShape === void 0 ? void 0 : iconShape.getBBox().height) !== null && _b !== void 0 ? _b : 0];
                };
                var createItems = function (item) {
                    var _a;
                    var itemsHeight = [];
                    if (customContent) {
                        itemsHeight.push((_a = customContent(item, group, {
                            startX: paddingArray[3],
                            startY: height,
                            width: contentWidth,
                        })) !== null && _a !== void 0 ? _a : 0);
                    }
                    else {
                        itemsHeight.push.apply(itemsHeight, createRowItems_1(item, contentWidth, paddingArray[3]));
                    }
                    height += Math.max.apply(Math, itemsHeight);
                };
                createItems(value);
            }
            shape === null || shape === void 0 ? void 0 : shape.attr('height', Math.max(size[1], height + paddingArray[2]));
            // collapse marker
            if (markerCfg) {
                var stateCollapsed = ((_b = (_a = group === null || group === void 0 ? void 0 : group.get('item')) === null || _a === void 0 ? void 0 : _a.getModel()) !== null && _b !== void 0 ? _b : {}).collapsed;
                var _g = shape.getBBox(), shapeWidth_1 = _g.width, shapeHeight = _g.height;
                var _h = typeof markerCfg === 'function' ? markerCfg(cfg, group) : markerCfg, show = _h.show, _j = _h.position, position = _j === void 0 ? 'right' : _j, collapsed = _h.collapsed, markerStyle = _h.style;
                createMarker({
                    show: show,
                    position: position,
                    collapsed: stateCollapsed !== null && stateCollapsed !== void 0 ? stateCollapsed : collapsed,
                    style: markerStyle,
                }, group, [shapeWidth_1, shapeHeight]);
                shape.attr('defaultCollapsed', collapsed);
            }
            return shape;
        },
        /**
         * 更新节点，包含文本
         * @override
         * @param  {Object} cfg 节点的配置项
         * @param  {Node} node 节点
         */
        update: undefined,
    }, 'single-node');
    // 注册边
    G6.registerEdge('fund-line', {
        // @ts-ignore
        draw: function draw(cfg, group) {
            if (cfg === void 0) { cfg = {}; }
            var edgeCfg = cfg.edgeCfg, value = cfg.value;
            var _a = getPathText(value), text = _a.text, subText = _a.subText;
            var _b = edgeCfg, edgeStyle = _b.style, labelCfg = _b.label;
            var _c = getPathInfo(cfg), startArrow = _c.startArrow, endArrow = _c.endArrow, path = _c.path, line2StartPoint = _c.line2StartPoint, endY = _c.endY;
            var labelStyle = (labelCfg !== null && labelCfg !== void 0 ? labelCfg : {}).style;
            var line = group.addShape('path', {
                attrs: __assign({ path: path, stroke: '#ccc', startArrow: startArrow, endArrow: endArrow }, (typeof edgeStyle === 'function' ? edgeStyle(cfg, group) : edgeStyle)),
                name: 'path-shape',
            });
            var createItem = function (itemText, key) {
                group.addShape('text', {
                    attrs: __assign(__assign({ text: itemText, x: line2StartPoint.x, y: key === 'text' ? endY - 4 : endY + 16 }, defaultLabelStyle), getStyle(labelStyle, cfg, group, key)),
                    name: "line-text-".concat(key),
                });
            };
            text && createItem(text, 'text');
            subText && createItem(subText, 'subText');
            return line;
        },
        // @ts-ignore
        update: function (cfg, edge) {
            var edgeCfg = cfg.edgeCfg, value = cfg.value;
            var _a = getPathText(value), text = _a.text, subText = _a.subText;
            var group = edge.getContainer();
            var getShape = function (shapeName) {
                return group.get('children').find(function (item) { return item.get('name') === shapeName; });
            };
            // const { startArrow, endArrow } = getPathInfo(cfg);
            var _b = getPathInfo(cfg), startArrow = _b.startArrow, endArrow = _b.endArrow, path = _b.path, line2StartPoint = _b.line2StartPoint, endY = _b.endY;
            var _c = edgeCfg, edgeStyle = _c.style, labelCfg = _c.label;
            var labelStyle = (labelCfg !== null && labelCfg !== void 0 ? labelCfg : {}).style;
            // path
            var pathShape = getShape('path-shape');
            pathShape === null || pathShape === void 0 ? void 0 : pathShape.attr(__assign({ path: path, stroke: '#ccc', startArrow: startArrow, endArrow: endArrow }, (typeof edgeStyle === 'function' ? edgeStyle(cfg, group) : edgeStyle)));
            // path text
            var texts = ['text', 'subText'];
            texts.forEach(function (key) {
                var textShape = getShape("line-text-".concat(key));
                textShape === null || textShape === void 0 ? void 0 : textShape.attr(__assign(__assign({ x: line2StartPoint.x, y: key === 'text' ? endY - 4 : endY + 16, text: key === 'text' ? text : subText }, defaultLabelStyle), getStyle(labelStyle, cfg, group, key)));
            });
        },
    }, 'single-edge');
};
