import { prefix } from '../constants';
/**
 * 根据 level 获取相关数据
 */
export var getFlowLevelData = function (data, level) {
    var nodes = data.nodes, edges = data.edges;
    if (level <= 0) {
        return data;
    }
    var levelNodes = nodes.filter(function (item) { return !item.hasOwnProperty("".concat(prefix, "_level")) || item["".concat(prefix, "_level")] < level; });
    return {
        nodes: levelNodes,
        edges: edges.filter(function (item) {
            var source = item.source, target = item.target;
            return levelNodes.filter(function (n) { return [source, target].includes(n.id); }).length >= 2;
        }),
    };
};
