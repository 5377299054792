var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { prefix, MARKER_CLICK } from '../../constants';
import { createFetchLoading, closeFetchLoading, getChildrenData, setLevelData } from '../../utils';
// 展开&折叠事件
export var bindEvents = function (params) {
    var graph = params.graph, level = params.level, getChildren = params.getChildren, fetchLoading = params.fetchLoading;
    var onClick = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var item, model, _a, collapsed, g_currentPath_1, _b, children, g_parentId_1, g_level_1, id_1, appendChildren, appendChildrenData;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    item = e.item;
                    model = item.getModel();
                    if (!((_c = e.target.get('name')) === null || _c === void 0 ? void 0 : _c.startsWith('collapse-icon'))) return [3 /*break*/, 3];
                    _a = item.getModel(), collapsed = _a.collapsed, g_currentPath_1 = _a.g_currentPath, _b = _a.children, children = _b === void 0 ? [] : _b, g_parentId_1 = _a.g_parentId, g_level_1 = _a.g_level, id_1 = _a.id;
                    appendChildren = level &&
                        !children.length &&
                        getChildrenData(graph.get('eventData').getData(), g_currentPath_1);
                    if (!(getChildren && !(children === null || children === void 0 ? void 0 : children.length) && !(appendChildren === null || appendChildren === void 0 ? void 0 : appendChildren.length))) return [3 /*break*/, 2];
                    createFetchLoading(model, fetchLoading);
                    return [4 /*yield*/, getChildren(item.getModel())];
                case 1:
                    appendChildrenData = _d.sent();
                    if (appendChildrenData) {
                        appendChildrenData = appendChildrenData.map(function (t, index) {
                            var _a;
                            return __assign((_a = {}, _a["".concat(prefix, "_level")] = g_level_1 + 1, _a["".concat(prefix, "_parentId")] = "".concat(g_parentId_1, "-").concat(id_1), _a["".concat(prefix, "_currentPath")] = "".concat(g_currentPath_1, "-").concat(index), _a), t);
                        });
                        setLevelData(graph, appendChildrenData, g_currentPath_1);
                    }
                    appendChildren = appendChildrenData;
                    closeFetchLoading();
                    _d.label = 2;
                case 2:
                    if ((appendChildren === null || appendChildren === void 0 ? void 0 : appendChildren.length) > 0) {
                        model.children = appendChildren;
                        graph.updateChild(model, model.id);
                        graph.updateItem(item, {
                            collapsed: false,
                        });
                        graph.refreshItem(item);
                        graph.emit(MARKER_CLICK, e, {
                            type: 'fetch',
                            collapsed: true,
                        });
                    }
                    else {
                        graph.updateItem(item, {
                            collapsed: !collapsed,
                        });
                        graph.layout();
                        graph.emit(MARKER_CLICK, e, {
                            type: 'collapse',
                            collapsed: !!collapsed,
                        });
                    }
                    _d.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    graph.on('node:click', function (e) {
        onClick(e);
    });
    graph.on('node:touchstart', function (e) {
        onClick(e);
    });
};
