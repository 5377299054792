var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultToolbarStyle } from '../constants';
import { Toolbar } from './components/toolbar';
import { createNode } from '../utils';
export var processToolbar = function (cfg, graph, container) {
    var _a;
    if (cfg === void 0) { cfg = {}; }
    var show = cfg.show, className = cfg.className, style = cfg.style, rest = __rest(cfg, ["show", "className", "style"]);
    var graphId = graph === null || graph === void 0 ? void 0 : graph.get('id');
    if (!graph || graph.destroyed) {
        return;
    }
    var toolbarId = "".concat(graphId, "-toolbar");
    var exist = document.querySelector("#".concat(toolbarId));
    if (exist) {
        (_a = exist.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(exist);
    }
    if (show) {
        var mountPoint = createNode(React.createElement(Toolbar, { graph: graph, container: container, toolbarCfg: rest }), {
            className: className !== null && className !== void 0 ? className : 'charts-toolbar',
            id: toolbarId,
        }, 'toolbar', __assign(__assign({}, defaultToolbarStyle), style));
        // @ts-ignore
        container.appendChild(mountPoint);
    }
};
