var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import baseEdge from './baseEdge';
// 自定义转化边
export var registerConvCubicVertical = function () {
    G6.registerEdge('conv-cubic-vertical', __assign(__assign({}, baseEdge), { draw: function (cfg, group) {
            var startPoint = cfg.startPoint, endPoint = cfg.endPoint;
            var hgap = Math.abs(endPoint.y - startPoint.y);
            // 垂直三阶贝塞尔曲线，两端留4px的汇总直线
            var path = [
                ['M', startPoint.x, startPoint.y],
                ['L', startPoint.x, startPoint.y + 4],
                [
                    'C',
                    startPoint.x,
                    startPoint.y < endPoint.y ? startPoint.y + hgap / 2 : startPoint.y - hgap / 2,
                    endPoint.x,
                    startPoint.y < endPoint.y ? endPoint.y - hgap / 2 : endPoint.y + hgap / 2,
                    endPoint.x,
                    endPoint.y - 15,
                ],
                ['L', endPoint.x, endPoint.y],
            ];
            return baseEdge.draw(cfg, group, path);
        } }));
};
