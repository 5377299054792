var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import G6 from '@antv/g6';
import { getCssPadding, getStyle, createFetchLoading, closeFetchLoading, getMarkerPosition, getGlobalInstance, getChildrenData, pushAsyncEvent, } from '../../utils';
var Util = G6.Util;
// file tree
export var registerFileTreeGeometries = function () {
    var defaultLineWidth = 2;
    var defaultTextStyle = {
        fill: 'rgba(0,0,0,.65)',
        textAlign: 'middle',
        fontSize: 14,
        fontFamily: 'PingFangSC-Regular',
        cursor: 'pointer',
        textBaseline: 'middle',
    };
    var defaultStroke = '#40a9ff';
    var markerSize = 12;
    var defaultMarkerCfg = {
        width: markerSize,
        height: markerSize,
        radius: markerSize / 2,
        stroke: '#999',
        fill: '#fff',
        cursor: 'pointer',
        r: markerSize / 2,
        lineWidth: 1,
    };
    G6.registerNode('file-tree-node', {
        options: {
            style: {
                fill: '#e8f7ff',
            },
            stateStyles: {
                hover: {
                    fillOpacity: 0.6,
                },
                selected: {},
            },
        },
        addLabel: function (group, label, x, y) {
            return group.addShape('text', {
                attrs: {
                    text: label,
                    x: x * 2,
                    y: y,
                    textAlign: 'left',
                    textBaseline: 'top',
                    fontFamily: 'PingFangSC-Regular',
                },
                cursor: 'pointer',
                name: 'name-text-shape',
            });
        },
        addCollapse: function (group, props) {
            var collapsed = props.collapsed, markerCfg = props.markerCfg, model = props.model, size = props.size;
            var style = markerCfg.style;
            var _a = [0, size[1]], x = _a[0], y = _a[1];
            // 子类数量 icon，绘制圆点在节点正下方
            var markerStyle = getStyle(style, model, group, 'collapse');
            var config = __assign(__assign({}, defaultMarkerCfg), markerStyle);
            var childCountGroup = group.addGroup({
                name: 'collapse-group',
            });
            var rectX = x - config.width / 2;
            var rectY = y - config.height / 2;
            childCountGroup.addShape('rect', {
                attrs: __assign({ lineWidth: defaultLineWidth, x: rectX, y: rectY }, config),
                name: 'collapse-icon-circle',
            });
            childCountGroup.addShape('path', {
                attrs: __assign({ path: collapsed
                        ? [
                            ['M', rectX + config.width / 4, y - config.height / 4],
                            ['L', rectX + (config.width * 3) / 4, y],
                            ['L', rectX + config.width / 4, y + config.height / 4],
                        ]
                        : [
                            ['M', rectX + config.width / 4, y - config.height / 4],
                            ['L', rectX + config.width / 2, y + config.height / 4],
                            ['L', rectX + (config.width * 3) / 4, y - config.height / 4],
                        ] }, config),
                name: 'collapse-icon-arrow',
                capture: false,
            });
            if (collapsed) {
                childCountGroup.show();
            }
            else {
                childCountGroup.hide();
            }
        },
        addMarker: function (group, props) {
            var markerCfg = props.markerCfg, model = props.model, size = props.size, _a = props.startX, startX = _a === void 0 ? 0 : _a, _b = props.startY, startY = _b === void 0 ? 0 : _b;
            var style = markerCfg.style, _c = markerCfg.position, position = _c === void 0 ? 'right' : _c, show = markerCfg.show;
            if (!show)
                return;
            // 子类数量 icon，绘制圆点在节点正下方
            var markerStyle = getStyle(style, model, group);
            var config = __assign(__assign({}, defaultMarkerCfg), markerStyle);
            var _d = getMarkerPosition(position, size), x = _d.x, y = _d.y;
            // 增加子节点 icon
            var markerIcon = group.addShape('marker', {
                attrs: __assign({ x: x + startX, y: y + startY, symbol: G6.Marker.expand }, config),
                name: 'icon-add-child',
            });
            markerIcon.hide();
            return markerIcon;
        },
        addHoverBack: function (group, props) {
            var x = props.x, y = props.y, width = props.width, height = props.height, style = props.style;
            group.addShape('rect', {
                attrs: __assign({ x: x, y: y, width: width, height: height, radius: 4, cursor: 'pointer' }, style),
                // capture: false,
                name: 'main-shape',
                draggable: true,
            });
        },
        addName: function (group, props) {
            var label = props.label, _a = props.x, x = _a === void 0 ? 0 : _a, y = props.y, style = props.style;
            return group.addShape('text', {
                attrs: __assign(__assign({ text: label, x: x, y: y }, defaultTextStyle), style),
                name: 'text-shape',
                draggable: true,
            });
        },
        addBottomLine: function (group, props) {
            var x = props.x, y = props.y, width = props.width, stroke = props.stroke, lineWidth = props.lineWidth;
            return group.addShape('path', {
                attrs: {
                    path: [
                        ['M', x - 1, y],
                        ['L', width, y],
                    ],
                    stroke: stroke,
                    lineWidth: lineWidth,
                },
                name: 'node-path-shape',
            });
        },
        draw: function (model, group) {
            var collapsed = model.collapsed, depth = model.depth, value = model.value, markerCfg = model.markerCfg, _a = model.children, children = _a === void 0 ? [] : _a;
            var nodeCfg = model.nodeCfg;
            var style = nodeCfg.style, label = nodeCfg.label, _b = nodeCfg.padding, padding = _b === void 0 ? 0 : _b, customContent = nodeCfg.customContent, lineStyle = nodeCfg.lineStyle;
            var bottomLineStyle = getStyle(lineStyle, model, group);
            var fileName = value.text;
            var size = (nodeCfg === null || nodeCfg === void 0 ? void 0 : nodeCfg.size) || [];
            if (typeof size === 'number')
                size = [size, size];
            var cardPadding = getCssPadding(padding);
            // 是否为根节点
            var rootNode = depth === 0;
            // 子节点数量
            var childCount = children.length || 0;
            var height = size[1] || 28;
            var x = 0;
            var y = 0;
            var text; // 名称文本
            if (customContent) {
                text = customContent(value, group, {
                    startX: x + cardPadding[3],
                    startY: height / 2,
                    width: size[0],
                });
            }
            else {
                text = this.addName(group, {
                    label: fileName,
                    x: x + cardPadding[3],
                    y: height / 2,
                    style: getStyle(label.style, model, group),
                });
            }
            var textWidth = group.getBBox().width;
            var width = Math.max(size[0], textWidth + cardPadding[1] + cardPadding[3]);
            if (!size.length)
                size = [width, height];
            var cardStyle = getStyle(style, model, group);
            var keyShapeAttrs = __assign({ x: x, y: y, width: width, height: height, radius: 12 }, cardStyle);
            var keyShape = group.addShape('rect', {
                attrs: keyShapeAttrs,
                name: 'root-rect-shape',
            });
            // 底部横线
            !rootNode &&
                this.addBottomLine(group, __assign({ stroke: defaultStroke, lineWidth: defaultLineWidth, x: x, width: width, y: y + height }, bottomLineStyle));
            var callbackMarkerCfg = markerCfg;
            var graph = getGlobalInstance(model._graphId);
            if (typeof markerCfg === 'function') {
                callbackMarkerCfg = markerCfg(__assign(__assign({}, model), { children: getChildrenData(graph === null || graph === void 0 ? void 0 : graph.get('eventData').getData(), model.g_currentPath) }), group);
            }
            if (childCount && !rootNode)
                this.addCollapse(group, {
                    collapsed: collapsed,
                    markerCfg: callbackMarkerCfg,
                    model: model,
                    size: [width, height],
                });
            var markerIcon = this.addMarker(group, {
                collapsed: collapsed,
                markerCfg: callbackMarkerCfg,
                model: model,
                size: [width, height],
                startX: text.getBBox().x - cardPadding[3],
                startY: keyShape.getBBox().y,
            });
            var bbox = group.getBBox();
            var minX = bbox.minX, minY = bbox.minY, maxX = bbox.maxX, maxY = bbox.maxY;
            var backContainer = group.addShape('path', {
                attrs: {
                    path: childCount
                        ? [
                            ['M', minX, minY],
                            ['L', maxX, minY],
                            ['L', maxX, maxY],
                            ['L', minX + 20, maxY],
                            ['L', minX + 20, maxY + 20],
                            ['L', minX, maxY + 20],
                            ['Z'],
                        ]
                        : [['M', minX, minY], ['L', maxX, minY], ['L', maxX, maxY], ['L', minX, maxY], ['Z']],
                    fill: '#fff',
                    opacity: 0,
                },
                draggable: true,
            });
            text === null || text === void 0 ? void 0 : text.toFront();
            backContainer.toBack();
            if (rootNode) {
                pushAsyncEvent(model._graphId, function () {
                    var _a = keyShape.getBBox(), keyShapeWith = _a.width, maxX = _a.maxX;
                    markerIcon === null || markerIcon === void 0 ? void 0 : markerIcon.attr({
                        x: x + keyShapeWith / 2,
                    });
                    text === null || text === void 0 ? void 0 : text.attr({
                        x: x + cardPadding[3] - keyShapeWith / 2,
                    });
                    keyShape.attr({
                        x: x - keyShapeWith / 2,
                    });
                });
            }
            return keyShape;
        },
        setState: function (name, value, node) {
            var _a, _b, _c, _d;
            if (['closest', 'selected', 'hover'].includes(name)) {
                var model = node.getModel();
                var edgeCfg = model.edgeCfg, nodeCfg = model.nodeCfg, depth = model.depth, markerCfg = model.markerCfg;
                if (depth === 0)
                    return;
                var _e = edgeCfg, edgeStateStyles = _e.edgeStateStyles, edgeStyle = _e.style;
                var _f = nodeCfg, nodeStateStyles_1 = _f.nodeStateStyles, lableStyle = _f.label.style, style = _f.style, lineStyle = _f.lineStyle;
                // closest 使用 hover 样式
                var _name_1 = name === 'closest' ? 'hover' : name;
                var group = node.getContainer();
                var rootShape = group.find(function (child) { return child.get('name') === 'root-rect-shape'; });
                var markerShapes_1 = [];
                var getMarkers_1 = function (shapes) {
                    shapes.forEach(function (shape) {
                        var _a;
                        var name = shape.get('name');
                        if ((name === null || name === void 0 ? void 0 : name.startsWith('collapse-icon')) || name === 'icon-add-child') {
                            markerShapes_1.push(shape);
                        }
                        if ((_a = shape.getChildren) === null || _a === void 0 ? void 0 : _a.call(shape).length)
                            getMarkers_1(shape.getChildren());
                    });
                };
                getMarkers_1(group.getChildren());
                var textShape = group.find(function (child) { return child.get('name') === 'text-shape'; });
                var pathShape = group.find(function (child) { return child.get('name') === 'node-path-shape'; });
                var selected = node.hasState('selected');
                if (value) {
                    var baseStyle_1 = nodeStateStyles_1[_name_1];
                    rootShape === null || rootShape === void 0 ? void 0 : rootShape.attr(__assign({}, (((_a = nodeStateStyles_1[_name_1]) === null || _a === void 0 ? void 0 : _a.rect) || baseStyle_1)));
                    markerShapes_1.forEach(function (markerShape) {
                        var _a;
                        markerShape === null || markerShape === void 0 ? void 0 : markerShape.attr(__assign({}, (((_a = nodeStateStyles_1[_name_1]) === null || _a === void 0 ? void 0 : _a.marker) || baseStyle_1)));
                    });
                    textShape === null || textShape === void 0 ? void 0 : textShape.attr(__assign({}, (((_b = nodeStateStyles_1[_name_1]) === null || _b === void 0 ? void 0 : _b.text) || baseStyle_1)));
                    pathShape === null || pathShape === void 0 ? void 0 : pathShape.attr(__assign({}, edgeStateStyles[_name_1]));
                }
                else {
                    if (selected) {
                        var baseStyle_2 = nodeStateStyles_1['selected'];
                        rootShape === null || rootShape === void 0 ? void 0 : rootShape.attr(__assign({}, (((_c = nodeStateStyles_1['selected']) === null || _c === void 0 ? void 0 : _c.rect) || baseStyle_2)));
                        markerShapes_1.forEach(function (markerShape) {
                            var _a;
                            markerShape === null || markerShape === void 0 ? void 0 : markerShape.attr(__assign({}, (((_a = nodeStateStyles_1['selected']) === null || _a === void 0 ? void 0 : _a.marker) || baseStyle_2)));
                        });
                        textShape === null || textShape === void 0 ? void 0 : textShape.attr(__assign({}, (((_d = nodeStateStyles_1['selected']) === null || _d === void 0 ? void 0 : _d.text) || baseStyle_2)));
                        pathShape === null || pathShape === void 0 ? void 0 : pathShape.attr(__assign({}, edgeStateStyles['selected']));
                    }
                    else {
                        var cardStyle = getStyle(style, model, node);
                        var textStyle = getStyle(lableStyle, model, node);
                        var pathStyle = getStyle(lineStyle, model, node);
                        var markStyle_1 = getStyle(markerCfg, model, node);
                        rootShape === null || rootShape === void 0 ? void 0 : rootShape.attr(cardStyle);
                        textShape === null || textShape === void 0 ? void 0 : textShape.attr(textStyle);
                        pathShape === null || pathShape === void 0 ? void 0 : pathShape.attr(pathStyle);
                        markerShapes_1.forEach(function (markerShape) {
                            markerShape === null || markerShape === void 0 ? void 0 : markerShape.attr(markStyle_1 === null || markStyle_1 === void 0 ? void 0 : markStyle_1.style);
                        });
                    }
                }
            }
        },
    });
    G6.registerEdge('file-tree-edge', {
        getControlPoints: function (cfg) {
            var startPoint = cfg.startPoint;
            var endPoint = cfg.endPoint;
            return [
                startPoint,
                {
                    x: startPoint.x,
                    y: endPoint.y,
                },
                endPoint,
            ];
        },
        update: undefined,
    }, 'polyline');
};
export var registerFileTreeBehaviors = function () {
    G6.registerBehavior('wheel-scroll', {
        getDefaultCfg: function () {
            return {
                direction: 'y',
                zoomKey: 'ctrl',
                sensitivity: 3,
                // wheel-scroll 可滚动的扩展范围，默认为 0，即最多可以滚动一屏的位置
                // 当设置的值大于 0 时，即滚动可以超过一屏
                // 当设置的值小于 0 时，相当于缩小了可滚动范围
                // 具体实例可参考：https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*IFfoS67_HssAAAAAAAAAAAAAARQnAQ
                scalableRange: -64,
            };
        },
        getEvents: function () {
            if (!this.zoomKey || ['shift', 'ctrl', 'alt', 'control'].indexOf(this.zoomKey) === -1)
                this.zoomKey = 'ctrl';
            return {
                wheel: 'onWheel',
            };
        },
        onWheel: function (ev) {
            var graph = this.graph;
            var keyDown = ev["".concat(this.zoomKey, "Key")];
            if (this.zoomKey === 'control')
                keyDown = ev.ctrlKey;
            if (keyDown) {
                var sensitivity = this.get('sensitivity');
                var canvas = graph.get('canvas');
                var point = canvas.getPointByClient(ev.clientX, ev.clientY);
                var ratio = graph.getZoom();
                if (ev.wheelDelta > 0) {
                    ratio *= 1 + 0.01 * sensitivity;
                }
                else {
                    ratio *= 1 - 0.01 * sensitivity;
                }
                graph.zoomTo(ratio, {
                    x: point.x,
                    y: point.y,
                });
                graph.emit('wheelzoom', ev);
            }
            else {
                var dx = ev.deltaX || ev.movementX;
                var dy = ev.deltaY || ev.movementY;
                if (!dy && navigator.userAgent.indexOf('Firefox') > -1)
                    dy = (-ev.wheelDelta * 125) / 3;
                var width = this.graph.get('width');
                var height = this.graph.get('height');
                var graphCanvasBBox = this.graph.get('group').getCanvasBBox();
                var expandWidth = this.scalableRange;
                var expandHeight = this.scalableRange;
                // 若 scalableRange 是 0~1 的小数，则作为比例考虑
                if (expandWidth < 1 && expandWidth > -1) {
                    expandWidth = width * expandWidth;
                    expandHeight = height * expandHeight;
                }
                var minX = graphCanvasBBox.minX, maxX = graphCanvasBBox.maxX, minY = graphCanvasBBox.minY, maxY = graphCanvasBBox.maxY;
                if (dx > 0) {
                    if (maxX < -expandWidth) {
                        dx = 0;
                    }
                    else if (maxX - dx < -expandWidth) {
                        dx = maxX + expandWidth;
                    }
                }
                else if (dx < 0) {
                    if (minX > width + expandWidth) {
                        dx = 0;
                    }
                    else if (minX - dx > width + expandWidth) {
                        dx = minX - (width + expandWidth);
                    }
                }
                if (dy > 0) {
                    if (maxY < -expandHeight) {
                        dy = 0;
                    }
                    else if (maxY - dy < -expandHeight) {
                        dy = maxY + expandHeight;
                    }
                }
                else if (dy < 0) {
                    if (minY > height + expandHeight) {
                        dy = 0;
                    }
                    else if (minY - dy > height + expandHeight) {
                        dy = minY - (height + expandHeight);
                    }
                }
                if (this.get('direction') === 'x') {
                    dy = 0;
                }
                else if (this.get('direction') === 'y') {
                    dx = 0;
                }
                graph.translate(-dx, -dy);
            }
            ev.preventDefault();
        },
    });
    G6.registerBehavior('drag-branch', {
        getEvents: function getEvents() {
            return {
                'node:dragstart': 'dragstart',
                'node:drag': 'drag',
                'node:dragend': 'dragend',
                'node:dragenter': 'dragenter',
                'node:dragleave': 'dragleave',
            };
        },
        dragstart: function dragstart(e) {
            var _a;
            this.set('foundNode', undefined);
            this.origin = {
                x: e.x,
                y: e.y,
            };
            this.target = e.item;
            var graph = this.get('graph');
            // 未配置 shouldBegin 时 默认为 true
            if (this.shouldBegin && !this.shouldBegin(graph.findDataById((_a = this.target) === null || _a === void 0 ? void 0 : _a.getID()))) {
                this.began = false;
                return;
            }
            this.began = true;
        },
        dragenter: function dragenter(e) {
            if (!this.began) {
                return;
            }
            var graph = this.get('graph');
            var foundNode = e.item;
            if (foundNode)
                graph.setItemState(foundNode, 'closest', true);
            this.set('foundNode', foundNode);
        },
        dragleave: function dragleave(e) {
            if (!this.began) {
                return;
            }
            var graph = this.get('graph');
            var foundNode = this.get('foundNode');
            if (foundNode)
                graph.setItemState(foundNode, 'closest', false);
            this.set('foundNode', foundNode);
        },
        drag: function drag(e) {
            if (!this.began) {
                return;
            }
            // move the delegate
            this.updateDelegate(e);
        },
        dragend: function dragend(e) {
            var graph = this.get('graph');
            var foundNode = this.get('foundNode');
            if (foundNode) {
                graph.setItemState(foundNode, 'closest', false);
            }
            if (!this.began) {
                return;
            }
            this.began = false;
            var item = e.item;
            var id = item.getID();
            var data = graph.findDataById(id);
            // remove delegate
            if (this.delegateRect) {
                this.delegateRect.remove();
                this.delegateRect = null;
            }
            if (!foundNode) {
                graph.emit('afterdragbranch', {
                    success: false,
                    message: 'Failed. No node close to the dragged node.',
                    branch: data,
                });
                return;
            }
            var foundNodeId = foundNode.getID();
            var oriParentData;
            Util.traverseTree(graph.get('data'), function (d) {
                var _a, _b;
                if (oriParentData)
                    return false;
                if ((_b = (_a = d.children) === null || _a === void 0 ? void 0 : _a.filter(function (child) { return child.id === id; })) === null || _b === void 0 ? void 0 : _b.length) {
                    oriParentData = d;
                }
                return true;
            });
            // 未配置 shouldEnd，则默认为 true
            if (this.shouldEnd && !this.shouldEnd(data, graph.findDataById(foundNodeId), oriParentData)) {
                return;
            }
            // if the foundNode is a descent of the dragged node, return
            var isDescent = false;
            Util.traverseTree(data, function (d) {
                if (d.id === foundNodeId)
                    isDescent = true;
            });
            if (isDescent) {
                var newParentData_1 = graph.findDataById(foundNodeId);
                graph.emit('afterdragbranch', {
                    success: false,
                    message: 'Failed. The target node is a descendant of the dragged node.',
                    newParentData: newParentData_1,
                    branch: data,
                });
                return;
            }
            var newParentData = graph.findDataById(foundNodeId);
            // 触发外部对数据的改变
            graph.emit('afterdragbranch', { success: true, message: 'Success.', newParentData: newParentData, oriParentData: oriParentData, branch: data });
            graph.removeChild(data.id);
            setTimeout(function () {
                var newChildren = newParentData.children;
                if (newChildren)
                    newChildren.push(data);
                else
                    newChildren = [data];
                // 更新正在被操作的子树颜色
                Util.traverseTree(data, function (d) {
                    d.branchColor = newParentData.branchColor;
                });
                graph.updateChildren(newChildren, newParentData.id);
            }, 600);
        },
        updateDelegate: function (e) {
            var graph = this.graph;
            if (!this.delegateRect) {
                // 拖动多个
                var parent_1 = graph.get('group');
                var attrs = {
                    fill: '#F3F9FF',
                    fillOpacity: 0.5,
                    stroke: '#1890FF',
                    strokeOpacity: 0.9,
                    lineDash: [5, 5],
                };
                var _a = this.calculationGroupPosition(e), cx = _a.x, cy = _a.y, width = _a.width, height = _a.height, minX = _a.minX, minY = _a.minY;
                this.originPoint = { x: cx, y: cy, width: width, height: height, minX: minX, minY: minY };
                // model上的x, y是相对于图形中心的，delegateShape是g实例，x,y是绝对坐标
                this.delegateRect = parent_1.addShape('rect', {
                    attrs: __assign({ width: width, height: height, x: cx, y: cy }, attrs),
                    name: 'rect-delegate-shape',
                });
                this.delegateRect.set('capture', false);
            }
            else {
                var clientX = e.x - this.origin.x + this.originPoint.minX;
                var clientY = e.y - this.origin.y + this.originPoint.minY;
                this.delegateRect.attr({
                    x: clientX,
                    y: clientY,
                });
            }
        },
        calculationGroupPosition: function (evt) {
            var node = this.target;
            if (!node) {
                node = evt.item;
            }
            var bbox = node.getBBox();
            var minX = bbox.minX, minY = bbox.minY, maxX = bbox.maxX, maxY = bbox.maxY;
            return {
                x: Math.floor(minX),
                y: Math.floor(minY),
                width: Math.ceil(maxX) - Math.floor(minX),
                height: Math.ceil(maxY) - Math.floor(minY),
                minX: minX,
                minY: minY,
            };
        },
    });
    G6.registerBehavior('click-node', {
        getEvents: function () {
            return {
                'node:click': 'onNodeClick',
                'canvas:click': 'onCanvasClick',
            };
        },
        onNodeClick: function (e) {
            return __awaiter(this, void 0, void 0, function () {
                var item, shapeName, model, graph, data, _a, id, value, _b, getChildren, fetchLoading, appendChildren;
                var _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            item = e.item;
                            shapeName = e.target.get('name');
                            model = item.getModel();
                            graph = this.graph;
                            if (!(shapeName === 'icon-add-child')) return [3 /*break*/, 3];
                            data = graph.findDataById(item.getID());
                            if (!data.children)
                                data.children = [];
                            this.graph.emit('add:children', e);
                            _a = e.item.getModel(), id = _a.id, value = _a.value;
                            _b = graph.get('extraPlugin'), getChildren = _b.getChildren, fetchLoading = _b.fetchLoading;
                            if (!getChildren) return [3 /*break*/, 2];
                            createFetchLoading(model, fetchLoading);
                            return [4 /*yield*/, getChildren({
                                    id: id,
                                    value: value,
                                })];
                        case 1:
                            appendChildren = _d.sent();
                            (_c = data.children).push.apply(_c, appendChildren);
                            // @ts-ignore
                            graph.updateChildren(data.children, data.id);
                            closeFetchLoading();
                            _d.label = 2;
                        case 2: return [2 /*return*/];
                        case 3:
                            // 选中节点
                            graph.getNodes().forEach(function (node) {
                                graph.setItemState(node, 'selected', false);
                            });
                            graph.setItemState(item, 'selected', true);
                            return [2 /*return*/];
                    }
                });
            });
        },
        onCanvasClick: function (e) {
            var _this = this;
            this.graph.getNodes().forEach(function (node) {
                _this.graph.setItemState(node, 'selected', false);
            });
        },
    });
    G6.registerBehavior('hover-node', {
        getEvents: function () {
            return {
                'node:mouseover': 'onNodeMouseOver',
                'node:mouseleave': 'onNodeMouseLeave',
                'node:mouseenter': 'onNodeMouseEnter',
            };
        },
        onNodeMouseEnter: function (e) {
            var _a;
            var item = e.item;
            if (!item || item.get('destroyed'))
                return;
            item.toFront();
            var model = item.getModel();
            var collapsed = model.collapsed, depth = model.depth;
            var rootNode = depth === 0 || model.isRoot;
            var group = item.getContainer();
            if (rootNode)
                return;
            // 控制子节点个数标记
            if (!collapsed) {
                (_a = group.find(function (e) { return e.get('name') === 'collapse-group'; })) === null || _a === void 0 ? void 0 : _a.show();
            }
            this.graph.setItemState(item, 'hover', true);
        },
        onNodeMouseOver: function (e) {
            var _a, _b;
            this.graph.emit('tooltip: show', e);
            // expand 状态下，若 hover 到子节点个数标记，填充背景+显示收起 icon
            var item = e.item;
            var group = item.getContainer();
            var model = item.getModel();
            if (!model.collapsed) {
                var childCountGroup = group.find(function (e) { return e.get('name') === 'collapse-group'; });
                if (childCountGroup) {
                    childCountGroup.show();
                    (_a = childCountGroup.find(function (e) { return e.get('name') === 'collapse-icon-arrow'; })) === null || _a === void 0 ? void 0 : _a.show();
                }
            }
            (_b = group.find(function (e) { return e.get('name') === 'icon-add-child'; })) === null || _b === void 0 ? void 0 : _b.show();
        },
        onNodeMouseLeave: function (e) {
            var _a, _b;
            var item = e.item;
            var model = item.getModel();
            var group = item.getContainer();
            var collapsed = model.collapsed;
            if (!collapsed) {
                var childCountGroup = group.find(function (e) { return e.get('name') === 'collapse-group'; });
                if (childCountGroup) {
                    childCountGroup.hide();
                    (_a = childCountGroup.find(function (e) { return e.get('name') === 'collapse-icon-arrow'; })) === null || _a === void 0 ? void 0 : _a.hide();
                }
            }
            (_b = group.find(function (e) { return e.get('name') === 'icon-add-child'; })) === null || _b === void 0 ? void 0 : _b.hide();
            this.graph.emit('tooltip: hide', e);
        },
    });
};
