var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useRef, useEffect } from 'react';
import { isEqual, pick } from '@antv/util';
import { deepCloneMapConfig } from '../util';
export default function useL7Plot(Ctor, config) {
    var plotRef = useRef();
    var plotConfig = useRef();
    var containerRef = useRef(null);
    var onReady = config.onReady;
    // updateOption/changeData/updateMap
    useEffect(function () {
        if (!plotRef.current || !plotConfig.current || isEqual(plotConfig.current, config))
            return;
        var changeData = false;
        var updateMap = false;
        var updateOption = false;
        var _a = plotConfig.current, currentOnReady = _a.onReady, currentMap = _a.map, _b = _a.source, currentSourceData = _b.data, currentSourceConfig = __rest(_b, ["data"]), currentConfig = __rest(_a, ["onReady", "map", "source"]);
        var onReady = config.onReady, inputMap = config.map, _c = config.source, inputSourceData = _c.data, inputSourceDataConfig = __rest(_c, ["data"]), inputConfig = __rest(config, ["onReady", "map", "source"]);
        updateMap = !isEqual(currentMap, inputMap);
        changeData = !isEqual(currentSourceConfig, inputSourceDataConfig) || currentSourceData !== inputSourceData;
        updateOption = !isEqual(currentConfig, inputConfig);
        plotConfig.current = deepCloneMapConfig(config);
        if (updateMap) {
            var updateMapConfig = pick(inputMap, ['center', 'pitch', 'rotation', 'zoom', 'style']);
            plotRef.current.updateMap(updateMapConfig);
        }
        if (changeData) {
            if (plotRef.current.loaded) {
                plotRef.current.changeData(inputSourceData, inputSourceDataConfig);
            }
            else {
                plotRef.current.once('loaded', function () {
                    var _a;
                    (_a = plotRef.current) === null || _a === void 0 ? void 0 : _a.changeData(inputSourceData, inputSourceDataConfig);
                });
            }
        }
        if (updateOption) {
            if (plotRef.current.loaded) {
                // @ts-ignore
                plotRef.current.update(inputConfig);
            }
            else {
                plotRef.current.once('loaded', function () {
                    var _a;
                    // @ts-ignore
                    (_a = plotRef.current) === null || _a === void 0 ? void 0 : _a.update(inputConfig);
                });
            }
        }
    }, [config]);
    useEffect(function () {
        if (!containerRef.current) {
            return function () { return null; };
        }
        if (!plotConfig.current) {
            plotConfig.current = deepCloneMapConfig(config);
        }
        plotRef.current = new Ctor(containerRef.current, __assign({}, config));
        if (onReady) {
            plotRef.current.once('loaded', function () {
                onReady(plotRef.current);
            });
        }
        // 组件销毁时销毁图表
        return function () {
            if (plotRef.current) {
                plotRef.current.destroy();
                plotRef.current = undefined;
            }
        };
    }, []);
    return {
        plotRef: plotRef,
        containerRef: containerRef,
    };
}
