// 交互
export var bindStateEvents = function (graph, cfg) {
    var _a = cfg !== null && cfg !== void 0 ? cfg : {}, _b = _a.nodeCfg, nodeCfg = _b === void 0 ? {} : _b, _c = _a.edgeCfg, edgeCfg = _c === void 0 ? {} : _c;
    var nodeStateStyles = nodeCfg.nodeStateStyles;
    var edgeStateStyles = edgeCfg.edgeStateStyles;
    /**
     * 存储交互状态
     * id: [[endActive, endDefalut], [startActive, startDefalut]]
     */
    var statusCache = {};
    var updateArrowFill = function (item, endArrowFill, stratArrowFill) {
        graph.updateItem(item, {
            style: {
                endArrow: !!endArrowFill && {
                    fill: endArrowFill,
                },
                startArrow: !!stratArrowFill && {
                    fill: stratArrowFill,
                },
            },
        });
    };
    var setState = function (item, name, status) {
        var _a, _b, _c;
        status ? item.toFront() : item.toBack();
        var _d = (_a = item.getModel().style) !== null && _a !== void 0 ? _a : {}, endArrow = _d.endArrow, startArrow = _d.startArrow;
        if (endArrow || startArrow) {
            if (!statusCache[item.getID()]) {
                // @ts-ignore
                var endArrowFill = (endArrow !== null && endArrow !== void 0 ? endArrow : {}).fill;
                // @ts-ignore
                var startArrowFill = (startArrow !== null && startArrow !== void 0 ? startArrow : {}).fill;
                var hoverStatus = (_c = (_b = item.getModel().style) === null || _b === void 0 ? void 0 : _b[name]) === null || _c === void 0 ? void 0 : _c.stroke;
                statusCache[item.getID()] = [
                    [hoverStatus !== null && hoverStatus !== void 0 ? hoverStatus : endArrowFill, endArrowFill],
                    [hoverStatus !== null && hoverStatus !== void 0 ? hoverStatus : startArrowFill, startArrowFill],
                ];
            }
            var fill = statusCache[item.getID()];
            updateArrowFill(item, endArrow && fill[0][status ? 0 : 1], startArrow && fill[1][status ? 0 : 1]);
        }
        graph.setItemState(item, name, status);
    };
    var getRelationItems = function (currentItem, name, status, type) {
        var relationItems = type === 'node'
            ? graph.findAll('edge', function (edge) { return edge.getSource() === currentItem || edge.getTarget() === currentItem; })
            : graph.findAll('node', function (node) {
                return currentItem.getSource().get('id') === node.get('id') ||
                    currentItem.getTarget().get('id') === node.get('id');
            });
        var highlightItems = [currentItem].concat(relationItems);
        highlightItems.forEach(function (item) {
            setState(item, name, status);
        });
    };
    if (nodeStateStyles) {
        graph.on('node:mouseenter', function (evt) {
            var item = evt.item;
            getRelationItems(item, 'hover', true, 'node');
        });
        graph.on('node:mouseleave', function (evt) {
            var item = evt.item;
            getRelationItems(item, 'hover', false, 'node');
        });
    }
    if (edgeStateStyles) {
        graph.on('edge:mouseenter', function (evt) {
            var item = evt.item;
            getRelationItems(item, 'hover', true, 'edge');
        });
        graph.on('edge:mouseleave', function (evt) {
            var item = evt.item;
            getRelationItems(item, 'hover', false, 'edge');
        });
    }
};
