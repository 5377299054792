var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import { defaultLabelStyle, Margin, defaultTitleLabelCfg, cardTitlePadding } from './constants';
import { getConfig, getContentAndStyle, getMarkerPosition } from './utils';
export var registerCardNode = function () {
    G6.registerNode('card', {
        draw: function (cfg, group) {
            var _a;
            if (cfg === void 0) { cfg = {}; }
            var title = cfg.title, body = cfg.body, footer = cfg.footer, collapseExpand = cfg.collapseExpand, children = cfg.children, markerPosition = cfg.markerPosition;
            var size = cfg.size || [100, 30];
            var height = 0; // 统计容器总高度，动态设置
            if (typeof size === 'number')
                size = [size, size];
            var style = __assign({ radius: 2, fill: '#fff' }, cfg.style);
            var color = style.stroke || cfg.color || '#5B8FF9';
            var radius = style.radius;
            // node box
            var shape = group.addShape('rect', {
                attrs: __assign({ x: 0, y: 0, width: size[0], height: size[1], stroke: color }, style),
                name: 'main-box',
                draggable: true,
            });
            // node title
            var titleTextShape;
            if (title) {
                var _b = getContentAndStyle(title), text = _b.text, _c = _b.style, titleStyle = _c === void 0 ? cfg.titleStyle : _c;
                titleTextShape = group.addShape('text', {
                    attrs: __assign(__assign({ textBaseline: 'top', x: Margin, y: cardTitlePadding, text: text }, defaultTitleLabelCfg), getConfig(titleStyle, group)),
                    name: 'title',
                });
            }
            var titleHeight = (titleTextShape ? titleTextShape.getBBox() : { height: size[1] / 2 }).height;
            // title rect
            var titleRectShape = group.addShape('rect', {
                attrs: {
                    x: 0,
                    y: 0,
                    width: size[0],
                    height: titleHeight + 2 * cardTitlePadding,
                    fill: color,
                    radius: [radius, radius, 0, 0],
                },
                name: 'title-rect',
                draggable: true,
            });
            titleTextShape === null || titleTextShape === void 0 ? void 0 : titleTextShape.toFront();
            // collapse marker
            var markerShape;
            if (collapseExpand && children) {
                markerShape = group.addShape('marker', {
                    attrs: __assign(__assign(__assign({}, getMarkerPosition(markerPosition, size)), { r: 6, cursor: 'pointer', symbol: cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse, stroke: color, lineWidth: 1, fill: '#fff' }), getConfig(cfg.markerStyle, group, __assign(__assign({}, cfg), { name: 'collapse-icon' }))),
                    name: 'collapse-icon',
                });
            }
            var titleRectBoxHeight = titleRectShape.getBBox().height;
            height += titleRectBoxHeight;
            // body
            var bodyShape;
            if (body) {
                var _d = getContentAndStyle(body), text = _d.text, _e = _d.style, bodyStyle = _e === void 0 ? cfg.bodyStyle : _e;
                bodyShape = group.addShape('text', {
                    attrs: __assign(__assign({ textBaseline: 'top', x: Margin, y: height + Margin, text: text }, defaultLabelStyle), getConfig(bodyStyle, group)),
                    name: "body",
                });
                height += bodyShape.getBBox().height;
            }
            // footer
            var footerTextShape;
            if (footer) {
                if (bodyShape) {
                    height += Margin;
                }
                var _f = getContentAndStyle(footer), labelText = _f.text, _g = _f.style, labelStyle = _g === void 0 ? cfg.footerStyle : _g;
                footerTextShape = group.addShape('text', {
                    attrs: __assign(__assign({ textBaseline: 'top', x: Margin, y: height + Margin, text: labelText }, defaultLabelStyle), getConfig(labelStyle, group)),
                    name: "footer-label",
                });
                var _h = footerTextShape.getBBox(), width = _h.width, contentHeight = _h.height;
                var footerValueHeight = 0;
                if (typeof cfg.footer === 'object' && ((_a = cfg.footer) === null || _a === void 0 ? void 0 : _a.value)) {
                    var _j = getContentAndStyle({
                        content: cfg.footer.value,
                        style: cfg.footer.valueStyle,
                    }), valueText = _j.text, _k = _j.style, valueStyle = _k === void 0 ? cfg.footerValueStyle || cfg.footerStyle : _k;
                    var valueTextShape = group.addShape('text', {
                        attrs: __assign(__assign({ textBaseline: 'top', x: width + Margin * 2, y: height + Margin, text: valueText }, defaultLabelStyle), getConfig(valueStyle, group)),
                        name: "footer-value",
                    });
                    var valueHeight = valueTextShape.getBBox().height;
                    footerValueHeight = valueHeight;
                }
                height += Math.max(contentHeight, footerValueHeight);
            }
            // 调整容器宽高
            if (bodyShape) {
                var desTextShapeBBox = bodyShape.getBBox();
                var width = size[0] > desTextShapeBBox.width + 16 ? size[0] : desTextShapeBBox.width + 16;
                shape.attr({ width: width, height: height + 16 });
                titleRectShape === null || titleRectShape === void 0 ? void 0 : titleRectShape.attr('width', width);
                markerShape === null || markerShape === void 0 ? void 0 : markerShape.attr(__assign({}, getMarkerPosition(markerPosition, [width, height + titleHeight + 2 * cardTitlePadding])));
            }
            return shape;
        },
        update: undefined,
    }, 'single-node');
};
export var registerIconNode = function () {
    G6.registerNode('icon-node', {
        options: {
            size: [60, 20],
            stroke: '#91d5ff',
            fill: '#91d5ff',
        },
        draw: function (cfg, group) {
            if (cfg === void 0) { cfg = {}; }
            // @ts-ignore
            var styles = this.getShapeStyle(cfg);
            var _a = cfg.labelCfg, labelCfg = _a === void 0 ? {} : _a, labelStyle = cfg.labelStyle, label = cfg.label, markerStyle = cfg.markerStyle, showMarker = cfg.showMarker, title = cfg.title, titleStyle = cfg.titleStyle;
            var keyShape = group.addShape('rect', {
                attrs: __assign(__assign({}, styles), { x: 0, y: 0 }),
            });
            var keyShapeHeight = keyShape.getBBox().height;
            var headShape;
            if (cfg.leftIcon) {
                var _b = cfg.leftIcon, _c = _b.x, x = _c === void 0 ? 8 : _c, y = _b.y, _d = _b.width, width = _d === void 0 ? 24 : _d, _e = _b.height, height = _e === void 0 ? 24 : _e, style = _b.style;
                if (style) {
                    group.addShape('rect', {
                        attrs: __assign({ x: 1, y: 1, width: 38, height: styles.height - 2 }, style),
                    });
                }
                headShape = group.addShape('image', {
                    attrs: {
                        x: x,
                        y: y || keyShapeHeight / 2 - height / 2,
                        width: width,
                        height: height,
                        img: cfg.leftIcon.img,
                    },
                    name: 'image-shape',
                });
            }
            if (showMarker) {
                group.addShape('marker', {
                    attrs: __assign({ x: styles.width / 3, y: styles.height + 6, r: 6, stroke: '#73d13d', cursor: 'pointer', symbol: G6.Marker.expand }, getConfig(markerStyle, group, __assign(__assign({}, cfg), { name: 'add-item' }))),
                    name: 'add-item',
                });
                group.addShape('marker', {
                    attrs: __assign({ x: (styles.width * 2) / 3, y: styles.height + 6, r: 6, stroke: '#ff4d4f', cursor: 'pointer', symbol: G6.Marker.collapse }, getConfig(markerStyle, group, __assign(__assign({}, cfg), { name: 'remove-item' }))),
                    name: 'remove-item',
                });
            }
            if (label) {
                var textCfg = labelStyle ? getConfig(labelStyle, group, cfg) : labelCfg.style;
                var y = title ? styles.height / 2 - (textCfg.fontSize * 1 || 12) - Margin / 2 : styles.height / 2;
                group.addShape('text', {
                    attrs: __assign({ text: label, x: styles.width / 2, y: y, textAlign: headShape ? 'start' : 'center', textBaseline: title ? 'top' : 'middle' }, textCfg),
                });
            }
            if (title) {
                var titleCfg = titleStyle ? getConfig(titleStyle, group, cfg) : labelCfg.style;
                group.addShape('text', {
                    attrs: __assign({ text: title, x: styles.width / 2, y: styles.height / 2 + Margin / 2, textAlign: headShape ? 'start' : 'center', textBaseline: 'top' }, titleCfg),
                });
            }
            return keyShape;
        },
    }, 'rect');
};
