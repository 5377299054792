var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ErrorBoundary from '../../errorBoundary';
import useGraph from '../../hooks/useGraphs';
import useProps from '../../hooks/useProps';
import { ChartLoading } from '../../utils';
import { defaultFlowGraphAnchorPoints, defaultStateStyles, defaultNodeStyle } from '../../constants';
var defaultLayout = {
    type: 'dendrogram',
    direction: 'LR',
    nodeSep: 20,
    rankSep: 100,
    radial: true,
};
var defaultProps = {
    nodeCfg: {
        type: 'circle',
        size: 30,
        anchorPoints: defaultFlowGraphAnchorPoints,
        linkCenter: true,
        nodeStateStyles: defaultStateStyles,
        style: defaultNodeStyle,
    },
    edgeCfg: {
        type: 'line',
        edgeStateStyles: defaultStateStyles,
    },
    behaviors: ['zoom-canvas', 'drag-canvas'],
    layout: defaultLayout,
    animate: true,
    markerPosition: 'right',
    autoFit: true,
    fitCenter: true,
    style: {
        position: 'relative',
        height: 'inherit',
        backgroundColor: '#fff',
    },
};
var RadialTreeGraph = function (props) {
    var uProps = useProps(props, defaultProps).uProps;
    var className = uProps.className, style = uProps.style, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate, rest = __rest(uProps, ["className", "style", "loading", "loadingTemplate", "errorTemplate"]);
    var container = useGraph('TreeGraph', rest, { name: 'RadialTreeGraph' }).container;
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default RadialTreeGraph;
