import G6 from '@antv/g6';
export var defaultLabelCfg = {
    style: {
        fill: '#000',
        fontSize: 12,
    },
};
export var defaultEdgeStyle = {
    stroke: '#91d5ff',
    endArrow: {
        path: G6.Arrow.vee(10, 10),
    },
};
export var defaultNodeAnchorPoints = [
    [0.5, 0],
    [0.5, 1],
];
export var defaultStateStyles = {
    hover: {
        stroke: '#1890ff',
        lineWidth: 2,
    },
};
export var defaultNodeSize = [120, 40];
