/** 超出省略，不够精准 */
export var setEllipsis = function (text, fontStyle, contentWidth) {
    if (contentWidth === void 0) { contentWidth = 120; }
    var _a = fontStyle.fontSize, fontSize = _a === void 0 ? 12 : _a, _b = fontStyle.fontWeight, fontWeight = _b === void 0 ? 'normal' : _b, _c = fontStyle.fontFamily, fontFamily = _c === void 0 ? 'Arial, sans-serif' : _c;
    var canvas = document.querySelector('canvas');
    var ctx = canvas.getContext('2d');
    ctx.font = "".concat(fontSize, "px ").concat(fontWeight, " ").concat(fontFamily);
    var currentText = text;
    var flag = false;
    ctx.fillText(currentText, 0, 0);
    if (text.length === 1) {
        return text;
    }
    for (var i = text.length - 1; i > 0; i--) {
        var width = ctx.measureText(currentText).width;
        if (width <= contentWidth) {
            if (!flag)
                return currentText;
            // 中文结尾删除最后一位、非中文结尾删除最后2位
            var reg = /[\u4e00-\u9fa5]/;
            var lastStr = currentText.substring(currentText.length - 1, currentText.length);
            return currentText.substring(0, currentText.length - (reg.test(lastStr) ? 1 : 2)) + '...';
        }
        else {
            flag = true;
            currentText = currentText.substring(0, currentText.length - 1);
        }
    }
};
