var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ChartLoading } from '../../utils';
import ErrorBoundary from '../../errorBoundary';
import useGraph from '../../hooks/useGraphs';
import useProps from '../../hooks/useProps';
import { defaultStateStyles } from '../../constants';
import { bindEvents } from '../file-tree-graph/events';
import { registerOrganizationCardNode } from './customItem';
registerOrganizationCardNode();
var defaultNodeStyle = {
    fill: '#91d5ff',
    stroke: '#40a9ff',
    radius: 2,
};
var defaultLayout = {
    type: 'compactBox',
    direction: 'TB',
    getId: function getId(d) {
        return d.id;
    },
    getHeight: function getHeight() {
        return 16;
    },
    getWidth: function getWidth() {
        return 16;
    },
    getVGap: function getVGap() {
        return 40;
    },
    getHGap: function getHGap() {
        return 70;
    },
};
var defaultProps = {
    nodeCfg: {
        type: 'organization-card',
        size: [100, 44],
        style: defaultNodeStyle,
        padding: 6,
        anchorPoints: [
            [0.5, 0],
            [0.5, 1],
        ],
        nodeStateStyles: defaultStateStyles,
        label: {
            style: function (cfg, group, type) {
                var styles = {
                    icon: {
                        width: 32,
                        height: 32,
                    },
                    title: {
                        fill: '#fff',
                    },
                    name: {
                        fill: '#000',
                    },
                };
                return type ? styles[type] : {};
            },
        },
    },
    edgeCfg: {
        type: 'polyline',
        endArrow: {
            type: 'triangle',
            fill: '#91d5ff',
        },
        edgeStateStyles: defaultStateStyles,
        style: {
            stroke: '#91d5ff',
        },
    },
    behaviors: ['zoom-canvas', 'drag-canvas'],
    layout: defaultLayout,
    animate: true,
    markerPosition: 'right',
    autoFit: true,
    fitCenter: true,
    style: {
        position: 'relative',
        height: 'inherit',
        backgroundColor: '#fff',
    },
};
var OrganizationGraph = function (props) {
    var uProps = useProps(props, defaultProps).uProps;
    var className = uProps.className, style = uProps.style, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate, rest = __rest(uProps, ["className", "style", "loading", "loadingTemplate", "errorTemplate"]);
    var container = useGraph('TreeGraph', rest, { name: 'OrganizationGraph', bindEvents: bindEvents }).container;
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default OrganizationGraph;
