import React, { useEffect } from 'react';
import G6 from '@antv/g6';
import { ChartLoading } from '../../utils';
import ErrorBoundary from '../../errorBoundary';
import useGraph from '../hooks/useGraph';
import { customIconNode } from './customItems';
import { defaultLabelCfg, defaultStateStyles } from './constants';
import { getGraphSize, renderMinimap, getGraphId, renderGraph, bindEvents, useProps } from './utils';
var defaultNodeStyle = {
    fill: '#91d5ff',
    stroke: '#40a9ff',
    radius: 2,
};
var defaultEdgeStyle = {
    stroke: '#91d5ff',
    endArrow: {
        path: 'M 0,0 L 12, 6 L 9,0 L 12, -6 Z',
        fill: '#91d5ff',
        d: -20,
    },
};
var defaultLayout = {
    type: 'compactBox',
    direction: 'TB',
    getId: function getId(d) {
        return d.id;
    },
    getHeight: function getHeight() {
        return 16;
    },
    getWidth: function getWidth() {
        return 16;
    },
    getVGap: function getVGap() {
        return 40;
    },
    getHGap: function getHGap() {
        return 70;
    },
};
var defaultProps = {
    nodeType: 'rect',
    edgeType: 'flow-line',
    collapseExpand: false,
    nodeSize: [120, 40],
    nodeLabelCfg: defaultLabelCfg,
    edgeLabelCfg: defaultLabelCfg,
    layout: defaultLayout,
    enableEdit: false,
    nodeStyle: defaultNodeStyle,
    edgeStyle: defaultEdgeStyle,
    nodeStateStyles: defaultStateStyles,
    edgeStateStyles: defaultStateStyles,
    autoFit: true,
};
var graphs = {};
var OrganizationTreeGraphComponent = function (props) {
    var uProps = useProps(props, defaultProps);
    var data = uProps.data, className = uProps.className, style = uProps.style, width = uProps.width, height = uProps.height, _a = uProps.nodeType, nodeType = _a === void 0 ? 'rect' : _a, _b = uProps.edgeType, edgeType = _b === void 0 ? 'flow-line' : _b, _c = uProps.collapseExpand, collapseExpand = _c === void 0 ? false : _c, _d = uProps.nodeSize, nodeSize = _d === void 0 ? [120, 40] : _d, _e = uProps.nodeLabelCfg, nodeLabelCfg = _e === void 0 ? defaultLabelCfg : _e, _f = uProps.edgeLabelCfg, edgeLabelCfg = _f === void 0 ? defaultLabelCfg : _f, _g = uProps.layout, layout = _g === void 0 ? defaultLayout : _g, _h = uProps.enableEdit, enableEdit = _h === void 0 ? false : _h, minimapCfg = uProps.minimapCfg, _j = uProps.nodeStyle, nodeStyle = _j === void 0 ? defaultNodeStyle : _j, _k = uProps.edgeStyle, edgeStyle = _k === void 0 ? defaultEdgeStyle : _k, _l = uProps.nodeStateStyles, nodeStateStyles = _l === void 0 ? defaultStateStyles : _l, _m = uProps.edgeStateStyles, edgeStateStyles = _m === void 0 ? defaultStateStyles : _m, _o = uProps.autoFit, autoFit = _o === void 0 ? true : _o, graphRef = uProps.graphRef, onReady = uProps.onReady, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate;
    var container = React.useRef(null);
    var graph = React.useRef(null);
    var graphId = getGraphId(graph);
    useGraph(graphs[graphId], uProps, container);
    useEffect(function () {
        var graphSize = getGraphSize(width, height, container);
        if (nodeType === 'icon-node') {
            customIconNode({ enableEdit: enableEdit });
        }
        var graph = graphs[graphId];
        if (!graph) {
            graph = new G6.TreeGraph({
                container: container.current,
                width: graphSize[0],
                height: graphSize[1],
                linkCenter: true,
                modes: {
                    default: ['drag-canvas', 'zoom-canvas'],
                },
                defaultNode: {
                    type: nodeType,
                    size: nodeSize,
                    style: nodeStyle,
                    labelCfg: nodeLabelCfg,
                },
                defaultEdge: {
                    type: edgeType,
                    style: edgeStyle,
                    labelCfg: edgeLabelCfg,
                },
                nodeStateStyles: nodeStateStyles,
                edgeStateStyles: edgeStateStyles,
                layout: layout,
                fitView: autoFit,
            });
            graphs[graphId] = graph;
        }
        if (graphRef) {
            graphRef.current = graph;
        }
        renderMinimap(minimapCfg, graph);
        renderGraph(graph, data);
        if (onReady) {
            onReady(graph);
        }
        if (collapseExpand) {
            graph.addBehaviors({
                type: 'collapse-expand',
            }, 'default');
        }
        bindEvents(graph, props);
        return function () {
            if (graphs[graphId]) {
                graphs[graphId].destroy();
                delete graphs[graphId];
            }
        };
    }, []);
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default OrganizationTreeGraphComponent;
