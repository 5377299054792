var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import { defaultMinimapCfg } from '../constants';
export var processMinimap = function (cfg, graph) {
    if (cfg === void 0) { cfg = {}; }
    if (!graph || graph.destroyed) {
        return;
    }
    var pluginMinimap = graph.get('plugins').filter(function (plugin) { return plugin.get('name') === 'minimap'; })[0];
    if (pluginMinimap) {
        graph.removePlugin(pluginMinimap);
    }
    if (cfg.show) {
        var curMminimapCfg = Object.assign(defaultMinimapCfg, cfg);
        var minimap = new G6.Minimap(__assign(__assign({}, curMminimapCfg), { name: 'minimap' }));
        graph.addPlugin(minimap);
    }
};
