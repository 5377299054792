var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// 合并 defaultProps
import { useCallback } from 'react';
import { getType, deepClone } from '../utils';
export default function useProps(props, defaultProps) {
    var cloneProps = deepClone(props);
    var mergeProps = useCallback(function (p, defaultProps) {
        var config = __assign({}, defaultProps);
        var propsKeys = Object.keys(p);
        propsKeys.forEach(function (key) {
            if (getType(p[key]) === 'Object') {
                config[key] = __assign(__assign({}, defaultProps[key]), p[key]);
            }
            else {
                config[key] = p[key];
            }
        });
        return config;
    }, [props, defaultProps]);
    var uProps = mergeProps(cloneProps, defaultProps);
    return {
        uProps: uProps,
    };
}
