import { getSize } from './get-size';
import { defaultStatusBarWidth } from '../constants';
import { isNumber } from '@antv/util';
export var getStatusCfg = function (cfg, cardSize) {
    var _a = cfg !== null && cfg !== void 0 ? cfg : {}, _b = _a.size, size = _b === void 0 ? [] : _b, _c = _a.position, position = _c === void 0 ? 'left' : _c;
    var _d = getSize(size), width = _d[0], height = _d[1];
    var cardWidth = cardSize[0], cardHeight = cardSize[1];
    var x = 0;
    var y = 0;
    var w = 0;
    var h = 0;
    switch (position) {
        case 'top':
            x = 0;
            y = 0;
            w = width !== null && width !== void 0 ? width : cardWidth;
            h = height !== null && height !== void 0 ? height : defaultStatusBarWidth;
            break;
        case 'left':
            x = 0;
            y = 0;
            w = width !== null && width !== void 0 ? width : defaultStatusBarWidth;
            h = height !== null && height !== void 0 ? height : cardHeight;
            break;
        case 'right':
            x = cardWidth - (isNumber(width) ? width : defaultStatusBarWidth);
            y = 0;
            w = width !== null && width !== void 0 ? width : defaultStatusBarWidth;
            h = height !== null && height !== void 0 ? height : cardHeight;
            break;
        case 'bottom':
            x = 0;
            y = cardHeight - (isNumber(height) ? height : defaultStatusBarWidth);
            w = width !== null && width !== void 0 ? width : cardWidth;
            h = height !== null && height !== void 0 ? height : defaultStatusBarWidth;
            break;
    }
    return {
        x: x,
        y: y,
        width: w,
        height: h,
    };
};
