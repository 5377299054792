var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
// 默认箭头样式
export var getArrowCfg = function (arrowCfg, edge) {
    if (!arrowCfg) {
        return;
    }
    if (typeof arrowCfg === 'object' && (arrowCfg === null || arrowCfg === void 0 ? void 0 : arrowCfg.show) === false) {
        return;
    }
    var cfg = typeof arrowCfg === 'function' ? arrowCfg(edge) : arrowCfg;
    var _a = cfg.type, type = _a === void 0 ? 'vee' : _a, _b = cfg.d, d = _b === void 0 ? 0 : _b, _c = cfg.size, size = _c === void 0 ? 10 : _c;
    return __assign({ path: G6.Arrow[type](size, size, d), fill: '#ccc', d: d }, cfg);
};
