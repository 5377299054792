// 默认交互状态
export var defaultStateStyles = {
    hover: {
        stroke: '#1890ff',
        lineWidth: 2,
    },
};
// card 默认节点大小
export var defaultNodeSize = [120, 40];
// 默认节点样式
export var defaultNodeStyle = {
    stroke: '#40a9ff',
};
// 默认 anchor 连接点
export var defaultNodeAnchorPoints = [
    [0, 0.5],
    [1, 0.5],
];
// card 内部 padding | margin
export var Margin = 6;
// card title 默认样式
export var defaultTitleLabelCfg = {
    fill: '#fff',
    fontSize: 12,
};
export var cardTitlePadding = 2;
// card body|footer  默认样式
export var defaultLabelStyle = {
    fill: '#000',
    fontSize: 12,
};
export var defaultMinimapCfg = {
    show: false,
    size: [150, 100],
    type: 'keyShape',
};
export var defaultNodeCfg = {
    style: {
        fill: '#000',
        fontSize: 12,
    },
};
export var defaultEdgeCfg = {
    style: {
        stroke: '#ccc',
    },
};
