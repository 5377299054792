var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import { deepClone, Log } from '../../utils';
import { defaultMinimapCfg } from './constants';
export var getGraphSize = function (width, height, container) {
    var CANVAS_WIDTH;
    var CANVAS_HEIGHT;
    if (container && container.current) {
        CANVAS_WIDTH = container.current.offsetWidth;
        CANVAS_HEIGHT = container.current.offsetHeight || 500;
    }
    if ((!width && !CANVAS_WIDTH) || (!height && !CANVAS_HEIGHT)) {
        Log.warn('请为 Graph 指定 width 与 height！否则将使用默认值 500 * 500');
        return [500, 500];
    }
    return [width || CANVAS_WIDTH || 500, height || CANVAS_HEIGHT || 500];
};
// 展开&折叠事件
export var bindDefaultEvents = function (graph, collapseExpand) {
    if (collapseExpand) {
        var onClick_1 = function (e) {
            var item = e.item;
            if (e.target.get('name') === 'collapse-icon') {
                graph.updateItem(item, {
                    collapsed: !item.getModel().collapsed,
                });
                graph.layout();
            }
        };
        graph.on('node:click', function (e) {
            onClick_1(e);
        });
        graph.on('node:touchstart', function (e) {
            onClick_1(e);
        });
    }
};
// 默认箭头样式
export var getDefaultEdgeArrowCfg = function (d, arrowType, fill) {
    if (d === void 0) { d = 0; }
    if (arrowType === void 0) { arrowType = 'vee'; }
    if (fill === void 0) { fill = '#ccc'; }
    return {
        endArrow: {
            path: G6.Arrow[arrowType](10, 10, d),
            fill: fill,
            d: d,
        },
    };
};
// 统一处理 text&style
export var getContentAndStyle = function (cfg) {
    if (typeof cfg === 'string' || typeof cfg === 'number') {
        return {
            text: cfg,
        };
    }
    var content = cfg.content, style = cfg.style;
    return {
        text: content,
        style: style,
    };
};
// 统一处理 config，支持回调
export var getConfig = function (source, item, cfg) {
    if (typeof source === 'function') {
        return source(item, cfg);
    }
    return source || {};
};
var uuid = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0;
        var v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
// 同一页面存在多 graph 时需要指定 graphId
export var getGraphId = function (graph) {
    if (graph.current) {
        return graph.current;
    }
    graph.current = "IndentedTreeGraph-".concat(uuid());
    return graph.current;
};
export var renderGraph = function (graph, data) {
    var originData = deepClone(data);
    graph.data(originData);
    graph.render();
};
export var processMinimap = function (cfg, graph) {
    if (cfg === void 0) { cfg = {}; }
    if (!graph || graph.destroyed)
        return;
    if (cfg.show) {
        var curMminimapCfg = Object.assign(defaultMinimapCfg, cfg);
        var minimap = new G6.Minimap(__assign({}, curMminimapCfg));
        graph.addPlugin(minimap);
        // return minimap;
    }
    return null;
};
/**
 * min ma
 */
export var getMarkerPosition = function (direction, size) {
    if (direction === void 0) { direction = 'right'; }
    var width = size[0], height = size[1];
    var x = 0;
    var y = 0;
    switch (direction) {
        case 'top':
            x = width / 2;
            y = 0;
            break;
        case 'right':
            x = width;
            y = height / 2;
            break;
        case 'bottom':
            x = width / 2;
            y = height;
            break;
        case 'left':
            x = 0;
            y = height / 2;
            break;
    }
    return { x: x, y: y };
};
/**
 * 设置 props 默认值
 * props 会在对应图表和 hooks 里面使用，不想加个很长的赋值表达式。
 * layout 使用 merge
 */
export var useProps = function (props, defaultProps) {
    return __assign(__assign(__assign({}, defaultProps), props), { layout: __assign(__assign({}, defaultProps === null || defaultProps === void 0 ? void 0 : defaultProps.layout), props === null || props === void 0 ? void 0 : props.layout) });
};
