import React, { useEffect, useRef, Fragment } from 'react';
import useFullscreen from '../../../hooks/useFullscreen';
export var Toolbar = function (_a) {
    var toolbarCfg = _a.toolbarCfg, container = _a.container, graph = _a.graph;
    var useGraph = useRef();
    var width = useRef();
    var height = useRef();
    var zoom = useRef(1);
    var _b = toolbarCfg.zoomFactor, zoomFactor = _b === void 0 ? 0.25 : _b, renderIcon = toolbarCfg.renderIcon, customContent = toolbarCfg.customContent;
    var _c = useFullscreen(container), fullscreen = _c[0], toggleFullscreen = _c[1];
    // 获取当全屏时的窗口大小
    var getWindow = function () {
        return [window.outerWidth, window.outerHeight];
    };
    // 切换全屏时保存 graph 尺寸
    var toggleWidth = function (f) {
        var _a;
        var size = f ? getWindow() : [width.current, height.current];
        (_a = useGraph.current) === null || _a === void 0 ? void 0 : _a.changeSize(size[0], size[1]);
    };
    // 获取缩放中心
    var getCenter = function () {
        if (!container) {
            return {
                x: 0,
                y: 0,
            };
        }
        return {
            x: container.clientWidth / 2,
            y: container.clientHeight / 2,
        };
    };
    // in 放大
    var zoomIn = function () {
        var _a;
        (_a = useGraph.current) === null || _a === void 0 ? void 0 : _a.zoom(Math.min(zoom.current + zoomFactor, 5), getCenter());
    };
    // out 缩小
    var zoomOut = function () {
        var _a;
        (_a = useGraph.current) === null || _a === void 0 ? void 0 : _a.zoom(Math.max(zoom.current - zoomFactor, 0.25), getCenter());
    };
    useEffect(function () {
        if (graph) {
            useGraph.current = graph;
            width.current = container === null || container === void 0 ? void 0 : container.clientWidth;
            height.current = container === null || container === void 0 ? void 0 : container.clientHeight;
        }
    }, [graph]);
    var setToggleFullscreen = function () {
        toggleFullscreen();
        toggleWidth(!document.fullscreenElement);
    };
    var customRender = customContent || renderIcon;
    if (customRender) {
        return customRender({
            zoomIn: zoomIn,
            zoomOut: zoomOut,
            toggleFullscreen: setToggleFullscreen,
            fullscreen: fullscreen,
            graph: graph,
        });
    }
    return (React.createElement(Fragment, null,
        !fullscreen ? (React.createElement("span", { style: {
                cursor: 'pointer',
            }, onClick: setToggleFullscreen }, "\u2610")) : (React.createElement("span", { style: {
                cursor: 'pointer',
            }, onClick: setToggleFullscreen }, "\u2684")),
        React.createElement("span", { style: {
                cursor: 'pointer',
            }, onClick: zoomIn }, "+"),
        React.createElement("span", { style: {
                cursor: 'pointer',
            }, onClick: zoomOut }, "-")));
};
