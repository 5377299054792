var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * get children
 * 获取相关路径下的一级节点
 */
export var getChildrenData = function (data, currentPath) {
    if (currentPath === void 0) { currentPath = ''; }
    // 打标时已经做了编码，这直接取值即可
    var path = currentPath.split('-');
    path.shift(); // 根节点没有 path
    var current = data;
    path.forEach(function (childrenIndex) {
        if (!current)
            return;
        current = current.children[Number(childrenIndex)];
    });
    if (!(current === null || current === void 0 ? void 0 : current.children)) {
        return [];
    }
    return current.children.map(function (item) { return (__assign(__assign({}, item), { children: [] })); });
};
