var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import G6 from '@antv/g6';
import { isEqual } from 'lodash';
import { DEFAULT_LAYOUT_OPTIONS, DEFAULT_NODE, DEFAULT_EDGE, DEFAULT_MODE } from './constants';
import { ITEM_STATE } from './types';
import { transformOriginData, drawLayerName, updateEdgeAnchorAndType } from './utils';
import registerBehavior from './behaviors';
import { resigterNodes } from './nodes';
import { resigterEdges } from './edges';
import { resigterLayout } from './layout';
var ConversionDagreGraph = /** @class */ (function (_super) {
    __extends(ConversionDagreGraph, _super);
    function ConversionDagreGraph(props) {
        var _this = _super.call(this, props) || this;
        // 图相关的注册
        _this.registerGraphRelative = function () {
            // 注册节点
            resigterNodes();
            // 注册边
            resigterEdges();
            // 注册布局
            resigterLayout();
        };
        // 浅比较图数据: 只比较图结构有没有变化
        _this.getGraphShallowDiff = function (data, prevData) {
            if (data === void 0) { data = { nodes: [], edges: [] }; }
            if (prevData === void 0) { prevData = { nodes: [], edges: [] }; }
            var nodes = data.nodes, edges = data.edges;
            var prevNodes = prevData.nodes, prevEdges = prevData.edges;
            if (nodes.length !== prevNodes.length || edges.length !== prevEdges.length) {
                return true;
            }
            var noNodeChange = nodes.every(function (node) { return prevNodes.find(function (prevNode) { return prevNode.id === node.id; }); });
            var noEdgeChange = edges.every(function (edge) { return prevEdges.find(function (prevEdge) { return prevEdge.id === edge.id; }); });
            return !(noNodeChange && noEdgeChange);
        };
        // 创建主图
        _this.newGraph = function () {
            var _a, _b;
            if (_this.graph) {
                _this.removeEventListener();
                _this.graph.destroy();
            }
            var width = (_a = _this.container) === null || _a === void 0 ? void 0 : _a.scrollWidth;
            var height = (_b = _this.container) === null || _b === void 0 ? void 0 : _b.scrollHeight;
            var graph = new G6.Graph({
                container: _this.container,
                width: width,
                height: height,
                modes: {
                    default: DEFAULT_MODE,
                },
                defaultNode: DEFAULT_NODE,
                defaultEdge: DEFAULT_EDGE,
                layout: _this.getLayoutParams(),
                minZoom: 0.001,
            });
            _this.graph = graph;
            var onReady = _this.props.onReady;
            if (onReady) {
                onReady(graph);
            }
            // 注册自定义behavior
            registerBehavior(_this.graph);
            _this.addEventListener();
        };
        // 绘制节点和边
        _this.renderGraph = function () {
            var graphData = _this.state.graphData;
            _this.graph.data(graphData);
            _this.graph.render();
        };
        // 添加事件监听
        _this.addEventListener = function () {
            // 布局完成
            _this.graph.on('afterlayout', _this.handleAfterLayout);
        };
        // 移除事件监听
        _this.removeEventListener = function () {
            var _a;
            if (_this.graph && !((_a = _this.graph) === null || _a === void 0 ? void 0 : _a.destroyed)) {
                // 移除布局完成监听
                _this.graph.off('afterlayout', _this.handleAfterLayout);
                // 移除container resize监听
                _this.resizeObserver.disconnect();
            }
        };
        // 获取布局参数
        _this.getLayoutParams = function (forceLayout) {
            var _a;
            var _b = _this.props, layout = _b.layout, data = _b.data;
            // 是否每个节点都有位置信息
            var hasPosition = (_a = data === null || data === void 0 ? void 0 : data.nodes) === null || _a === void 0 ? void 0 : _a.every(function (node) { return node.x !== undefined && node.y !== undefined; });
            // 如果每个节点都有位置信息，则走自定义preset布局
            if (!forceLayout && hasPosition) {
                return {
                    type: 'conv-preset',
                };
            }
            return __assign(__assign(__assign(__assign({}, DEFAULT_LAYOUT_OPTIONS), { ranksep: layout.rankdir === 'TB' ? 75 : 150 }), layout), { type: 'dagre' });
        };
        // 布局完成回调
        _this.handleAfterLayout = function () {
            var _a, _b;
            var _c = _this.props, layerOrder = _c.layerOrder, rankdir = _c.layout.rankdir;
            if (!(layerOrder === null || layerOrder === void 0 ? void 0 : layerOrder.length)) {
                // 画布内容自适应视口大小
                _this.graph.fitView(20);
                return;
            }
            // 更新边对应的类型、起点和终点的连接点
            updateEdgeAnchorAndType(_this.graph, layerOrder, rankdir);
            // 绘制层级名称
            drawLayerName(_this.graph, layerOrder, rankdir);
            if (_this.shouldCacheZoomAndTranslate) {
                // 设置画布缩放比、位移、选中元素
                _this.setCacheGraph();
            }
            else {
                // 画布内容自适应视口大小
                _this.graph.fitView(20);
                // 首次非空图数据，缓存当前视口状态
                if ((_b = (_a = _this.state.graphData) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.length) {
                    _this.shouldCacheZoomAndTranslate = true;
                    _this.handleCacheGraph();
                }
            }
        };
        // 缓存画布缩放比、画布位移、选中元素
        _this.handleCacheGraph = function () {
            if (!_this.graph || _this.graph.destroyed) {
                return;
            }
            var width = _this.graph.get('width');
            var height = _this.graph.get('height');
            // 记录保存时的视口中心点对应的canvas坐标
            var centerPoint = _this.graph.getCanvasByPoint(width / 2, height / 2);
            // 选中的节点
            var selectedNodes = _this.graph
                .getNodes()
                .filter(function (node) { return node.hasState(ITEM_STATE.Selected); })
                .map(function (node) { return node.get('id'); });
            // 选中的边
            var selectedEdges = _this.graph
                .getEdges()
                .filter(function (edge) { return edge.hasState(ITEM_STATE.Selected); })
                .map(function (edge) { return edge.get('id'); });
            // 缓存视口数据
            _this.cacheData = {
                zoom: _this.graph.getZoom(),
                centerPoint: centerPoint,
                selectedNodes: selectedNodes,
                selectedEdges: selectedEdges,
            };
        };
        // 设置画布缩放比、位移、选中的节点
        _this.setCacheGraph = function () {
            if (!_this.cacheData) {
                return;
            }
            var _a = _this.cacheData, selectedNodes = _a.selectedNodes, selectedEdges = _a.selectedEdges, zoom = _a.zoom, _b = _a.centerPoint, x = _b.x, y = _b.y;
            var width = _this.graph.get('width');
            var height = _this.graph.get('height');
            _this.graph.zoomTo(zoom);
            // 获取视口中心点对应的canvas坐标
            var newCenterPoint = _this.graph.getCanvasByPoint(width / 2, height / 2);
            // 做视口中心点的平移
            _this.graph.translate(x - newCenterPoint.x, y - newCenterPoint.y);
            // 还原选中的节点状态
            selectedNodes.forEach(function (selectedNode) {
                _this.graph.setItemState(selectedNode, ITEM_STATE.Selected, true);
            });
            // 还原选中的边状态
            selectedEdges.forEach(function (selectedEdge) {
                _this.graph.setItemState(selectedEdge, ITEM_STATE.Selected, true);
            });
        };
        _this.handleMouseDown = function (event) {
            //阻止外层拖动影响
            event.preventDefault();
        };
        _this.container = null;
        _this.graph = null;
        _this.shouldCacheZoomAndTranslate = false;
        var data = props.data, layerOrder = props.layerOrder, segmLayer = props.segmLayer, ratioMethod = props.ratioMethod;
        _this.state = {
            graphData: transformOriginData(data, layerOrder, segmLayer, ratioMethod),
        };
        return _this;
    }
    ConversionDagreGraph.prototype.componentDidMount = function () {
        // 先做图相关的注册：如节点、边、布局
        this.registerGraphRelative();
        // 创建主图
        this.newGraph();
        // 绘制节点和边
        this.renderGraph();
    };
    ConversionDagreGraph.getDerivedStateFromProps = function (props, state) {
        var data = props.data, layerOrder = props.layerOrder, segmLayer = props.segmLayer, ratioMethod = props.ratioMethod;
        // props数据 -> G6渲染图数据
        var graphData = transformOriginData(data, layerOrder, segmLayer, ratioMethod);
        return {
            graphData: graphData,
        };
    };
    ConversionDagreGraph.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, layerOrder = _a.layerOrder, layout = _a.layout, data = _a.data;
        // 缓存当前视口状态
        this.handleCacheGraph();
        var graphData = this.state.graphData;
        var prevGraphData = transformOriginData(prevProps.data, prevProps.layerOrder, prevProps.segmLayer, prevProps.ratioMethod);
        this.shouldCacheZoomAndTranslate = !this.getGraphShallowDiff(data, prevProps.data);
        if (isEqual(prevGraphData, graphData) && !isEqual(layout, prevProps.layout)) {
            // 仅更新布局
            this.graph.updateLayout(this.getLayoutParams(!isEqual(layout, prevProps.layout)));
            return;
        }
        // 数据变化或者层级顺序发生变化
        if (!isEqual(prevGraphData, graphData) || !isEqual(layerOrder, prevProps.layerOrder)) {
            // 更新布局
            this.graph.updateLayout(this.getLayoutParams(!isEqual(layerOrder, prevProps.layerOrder)));
            // 重新绘制节点和边
            this.renderGraph();
        }
    };
    ConversionDagreGraph.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var graphData = this.state.graphData;
        var nextGraphData = transformOriginData(nextProps.data, nextProps.layerOrder, nextProps.segmLayer, nextProps.ratioMethod);
        if (!isEqual(nextGraphData, graphData) || !isEqual(nextProps.layout, this.props.layout)) {
            return true;
        }
        return false;
    };
    ConversionDagreGraph.prototype.componentWillUnmount = function () {
        // 移除事件监听
        this.removeEventListener();
    };
    ConversionDagreGraph.prototype.render = function () {
        var _this = this;
        var _a = this.props, className = _a.className, _b = _a.style, propsStyle = _b === void 0 ? {} : _b;
        var style = __assign({ width: '100%', height: '100%' }, propsStyle);
        return (React.createElement("div", { className: className, style: style, onMouseDown: this.handleMouseDown, ref: function (container) {
                _this.container = container;
            } }));
    };
    ConversionDagreGraph.defaultProps = {
        data: null,
        layerOrder: [],
        segmLayer: '',
        ratioMethod: 'both',
        layout: {
            rankdir: 'TB', // 默认从上到下
        },
    };
    return ConversionDagreGraph;
}(Component));
export default ConversionDagreGraph;
