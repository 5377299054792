import './custom-hover';
import './custom-click';
var modes = {
    default: ['conv-hover', 'conv-click'],
};
export default (function (graph) {
    Object.keys(modes).forEach(function (key) {
        graph.addBehaviors(modes[key], key);
    });
});
