var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ErrorBoundary from '../../errorBoundary';
import useGraph from '../../hooks/useGraphs';
import useProps from '../../hooks/useProps';
import { ChartLoading } from '../../utils';
import { defaultFlowGraphAnchorPoints, defaultNodeSize, defaultStateStyles, defaultNodeStyle } from '../../constants';
import { registerFundFlowItems } from './customItem';
import { bindEvents } from '../flow-analysis-graph/events';
registerFundFlowItems();
var defaultLayout = {
    type: 'dagre',
    rankdir: 'LR',
    nodesep: 30,
    ranksep: 50,
};
var defaultProps = {
    nodeCfg: {
        type: 'fund-card',
        size: defaultNodeSize,
        style: defaultNodeStyle,
        anchorPoints: defaultFlowGraphAnchorPoints,
        nodeStateStyles: defaultStateStyles,
        padding: 6,
    },
    edgeCfg: {
        type: 'fund-line',
        edgeStateStyles: defaultStateStyles,
        style: {
            stroke: '#40a9ff',
        },
        endArrow: {
            fill: '#40a9ff',
        },
    },
    behaviors: ['zoom-canvas', 'drag-canvas'],
    layout: defaultLayout,
    animate: true,
    autoFit: true,
    fitCenter: true,
    style: {
        position: 'relative',
        height: 'inherit',
        backgroundColor: '#fff',
    },
};
var FundFlowGraph = function (props) {
    var uProps = useProps(props, defaultProps).uProps;
    var className = uProps.className, style = uProps.style, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate, rest = __rest(uProps, ["className", "style", "loading", "loadingTemplate", "errorTemplate"]);
    var container = useGraph('Graph', rest, { name: 'FundFlowGraph', bindEvents: bindEvents }).container;
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default FundFlowGraph;
