var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cloneDeep, uniqBy } from 'lodash';
import { getNodeMaxSize } from './text';
// 数据转换：原始节点数据 -> G6渲染节点数据
var transformOriginNodes = function (originNodes, layerOrder, size) {
    // 过滤得到节点中实际存在的有效层级
    var effectiveLayer = (Array.isArray(layerOrder) &&
        layerOrder.filter(function (layerName) { return originNodes.find(function (node) { return node.layerName === layerName; }); })) ||
        [];
    return originNodes.map(function (originNode) {
        var layer = (effectiveLayer === null || effectiveLayer === void 0 ? void 0 : effectiveLayer.findIndex(function (item) { return item === originNode.layerName; })) + 1; // dagre布局指定层级有bug，layer暂时从1开始
        return {
            id: originNode.id,
            label: originNode.name || '',
            layer: layer !== -1 ? layer : undefined,
            style: __assign({ stroke: '#B8C3D9', textColor: '#000' }, (originNode.style || {})),
            size: size,
            custom: {
                layerName: originNode.layerName,
                measure: originNode.measure,
                relatedMeasures: originNode.relatedMeasures,
                compareMeasures: originNode.compareMeasures, // 同环比指标
            },
            data: cloneDeep(originNode),
            x: originNode.x,
            y: originNode.y,
        };
    });
};
// 数据转换：原始边数据 -> G6渲染边数据
var transformOriginEdges = function (data, layerOrder, segmLayer, ratioMethod) {
    var _a = data.nodes, originNodes = _a === void 0 ? [] : _a, _b = data.edges, originEdges = _b === void 0 ? [] : _b;
    return originEdges
        .map(function (originEdge) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var sourceNode = originNodes.find(function (node) { return node.id === originEdge.source; });
        var targetNode = originNodes.find(function (node) { return node.id === originEdge.target; });
        if (!sourceNode || !targetNode) {
            return undefined;
        }
        // 自动计算边的比率和名称
        var autoRatio = 0;
        var name = '';
        switch (ratioMethod) {
            case 'both':
                // 包含占比和分流
                // 找出分段层级的层级值
                var segmLayerValue = layerOrder.findIndex(function (item) { return item === segmLayer; });
                // 找出终点的层级值
                var targetLayerValue = layerOrder.findIndex(function (item) { return item === (targetNode === null || targetNode === void 0 ? void 0 : targetNode.layerName); });
                // 同层级的边、分段层级的入边都是占比边, 比率 = 边上指标 / 终点的主指标
                if (!segmLayer || targetLayerValue <= segmLayerValue) {
                    autoRatio = ((_a = originEdge === null || originEdge === void 0 ? void 0 : originEdge.measure) === null || _a === void 0 ? void 0 : _a.value) / ((_b = targetNode === null || targetNode === void 0 ? void 0 : targetNode.measure) === null || _b === void 0 ? void 0 : _b.value);
                    name = '占比';
                }
                else {
                    // 分段层级的出边都是分流边, 比率 = 边上指标 / 起点的主指标
                    autoRatio = ((_c = originEdge === null || originEdge === void 0 ? void 0 : originEdge.measure) === null || _c === void 0 ? void 0 : _c.value) / ((_d = sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.measure) === null || _d === void 0 ? void 0 : _d.value);
                    name = '分流';
                }
                break;
            case 'splitFlow':
                // 均是分流：比率 = 边上指标 / 起点的主指标
                autoRatio = ((_e = originEdge === null || originEdge === void 0 ? void 0 : originEdge.measure) === null || _e === void 0 ? void 0 : _e.value) / ((_f = sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.measure) === null || _f === void 0 ? void 0 : _f.value);
                name = '分流';
                break;
            case 'proportion':
                // 均是占比：比率 = 边上指标 / 终点的主指标
                autoRatio = ((_g = originEdge === null || originEdge === void 0 ? void 0 : originEdge.measure) === null || _g === void 0 ? void 0 : _g.value) / ((_h = targetNode === null || targetNode === void 0 ? void 0 : targetNode.measure) === null || _h === void 0 ? void 0 : _h.value);
                name = '占比';
                break;
            default:
                // 不展示转化比率
                autoRatio = undefined;
                name = '';
                break;
        }
        var ratio = originEdge.ratio || autoRatio; // 优先使用edge数据里的ratio
        var formatRatio = !isNaN(ratio) && ratio !== Infinity
            ? Number((ratio * 100).toString().match(/^\d+(?:\.\d{0,2})?/))
            : '-'; // 比率最多保留2位小数
        var showRatio = "".concat(formatRatio, "%");
        var label = originEdge.name || name; // 优先使用edge数据里的name
        return {
            id: originEdge.id,
            source: originEdge.source,
            target: originEdge.target,
            label: autoRatio !== undefined ? "".concat(label, " ").concat(showRatio) : '',
            style: __assign({ stroke: '#B8C3D9', labelFill: '#000', highlightColor: '#3572F9' }, (originEdge.style || {})),
            custom: {
                ratio: ratio,
                formatRatio: formatRatio,
                showRatio: showRatio,
                sourceNode: sourceNode,
                targetNode: targetNode,
                label: label,
            },
            data: cloneDeep(originEdge),
        };
    })
        .filter(function (edge) { return !!edge; });
};
// 数据转换：原始数据 -> G6渲染数据
export var transformOriginData = function (originData, layerOrder, segmLayer, ratioMethod) {
    if (layerOrder === void 0) { layerOrder = []; }
    var _a = originData.nodes, originNodes = _a === void 0 ? [] : _a;
    // 节点去重
    var uniqNodes = uniqBy(originNodes, 'id');
    // 获取所有节点中最大的宽作为节点size
    var size = getNodeMaxSize(uniqNodes);
    var nodes = transformOriginNodes(uniqNodes, layerOrder, size);
    var edges = transformOriginEdges(originData, layerOrder, segmLayer, ratioMethod);
    return {
        nodes: nodes,
        edges: edges,
    };
};
