var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { prefix } from '../constants';
/**
 * 对数据进行打标，加上 level 和  parentId
 */
export var setTreeTag = function (data, level, parentId, path) {
    var _a;
    if (level === void 0) { level = 0; }
    if (parentId === void 0) { parentId = ''; }
    if (path === void 0) { path = ''; }
    var id = data.id, _b = data.children, children = _b === void 0 ? [] : _b;
    return __assign(__assign((_a = {}, _a["".concat(prefix, "_level")] = level, _a["".concat(prefix, "_parentId")] = parentId, _a["".concat(prefix, "_currentPath")] = path, _a), data), { children: children === null || children === void 0 ? void 0 : children.map(function (item, index) {
            return setTreeTag(item, level + 1, parentId ? "".concat(parentId, "-").concat(id) : id, "".concat(path, "-").concat(index));
        }) });
};
