import { countBy } from './count-by';
export var getCenterNode = function (data) {
    var nodes = data.nodes, edges = data.edges;
    if (nodes.length === 1) {
        return nodes[0].id;
    }
    var linkCount = [];
    edges.forEach(function (item) {
        linkCount.push(item.source);
    });
    var timesObj = countBy(linkCount);
    var maxTimes = 0;
    var maxTimeKey = '';
    for (var k in timesObj) {
        if (timesObj.hasOwnProperty(k) && timesObj[k] > maxTimes) {
            maxTimes = timesObj[k];
            maxTimeKey = k;
        }
    }
    return maxTimeKey;
};
