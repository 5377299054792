export var radialSectorLayout = function (params) {
    var center = params.center, eventNodePosition = params.eventNodePosition, allNodes = params.nodes, layoutNodes = params.layoutNodes, _a = params.options, options = _a === void 0 ? {} : _a;
    var _b = options, _c = _b.unitRadius, unitRadius = _c === void 0 ? 80 : _c, _d = _b.nodeSize, nodeSize = _d === void 0 ? 20 : _d, _e = _b.nodeSpacing, nodeSpacing = _e === void 0 ? 10 : _e;
    if (!layoutNodes.length)
        layoutNodes;
    // 过滤已经在画布上的节点
    var pureLayoutNodes = layoutNodes.filter(function (node) {
        return (allNodes.findIndex(function (n) {
            var id = n.id;
            return id === node.id;
        }) !== -1);
    });
    if (!pureLayoutNodes.length)
        return layoutNodes;
    var getDistance = function (point1, point2) {
        var dx = point1.x - point2.x;
        var dy = point1.y - point2.y;
        return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
    };
    // 节点裁剪
    var centerX = center[0], centerY = center[1];
    var ex = eventNodePosition[0], ey = eventNodePosition[1];
    var diffX = ex - centerX;
    var diffY = ey - centerY;
    var allNodePositions = [];
    allNodes.forEach(function (n) {
        var id = n.id, x = n.x, y = n.y;
        allNodePositions.push({
            id: id,
            x: x,
            y: y,
            layer: Math.round(getDistance({ x: x, y: y }, { x: centerX, y: centerY })) / unitRadius,
        });
    });
    var currentRadius = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
    var degree = Math.atan2(diffY, diffX);
    var minRadius = currentRadius + unitRadius;
    var pureNodePositions = [];
    var getNodesPosition = function (nodes, r) {
        var degreeStep = 2 * Math.asin((nodeSize + nodeSpacing) / 2 / r);
        pureNodePositions = [];
        var l = nodes.length - 1;
        nodes.forEach(function (n, i) {
            n.x = centerX + r * Math.cos(degree + (-l / 2 + i) * degreeStep);
            n.y = centerY + r * Math.sin(degree + (-l / 2 + i) * degreeStep);
            pureNodePositions.push({ x: n.x, y: n.y });
        });
    };
    var checkOverlap = function (nodesPosition, pureNodesPosition) {
        var hasOverlap = false;
        var checkLayer = Math.round(minRadius / unitRadius);
        var loopNodes = nodesPosition.filter(function (n) { return n.layer === checkLayer; });
        for (var i = 0; i < loopNodes.length; i++) {
            var n = loopNodes[i];
            // 因为是同心圆布局，最先相交的应该是收尾节点
            if (getDistance(pureNodesPosition[0], n) < nodeSize ||
                getDistance(pureNodesPosition[pureNodesPosition.length - 1], n) < nodeSize) {
                hasOverlap = true;
                break;
            }
        }
        return hasOverlap;
    };
    getNodesPosition(pureLayoutNodes, minRadius);
    while (checkOverlap(allNodePositions, pureNodePositions)) {
        minRadius += unitRadius;
        getNodesPosition(pureLayoutNodes, minRadius);
    }
    return layoutNodes;
};
