var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRef, useEffect } from 'react';
import { isObject, isString, isEqual } from '@antv/util';
import { getGraphSize, getCommonCfg, getArrowCfg, getMarkerPosition, setTreeTag, getTreeLevelData, deepClone, } from '../../utils';
import { processMinimap } from '../../plugins';
export default function useGraph(graphInstance, config, container) {
    var graphHook = useRef();
    var data = config.data, width = config.width, height = config.height, layout = config.layout, minimapCfg = config.minimapCfg, behaviors = config.behaviors, fitCenter = config.fitCenter, nodeCfg = config.nodeCfg, edgeCfg = config.edgeCfg, markerCfg = config.markerCfg, level = config.level;
    var graphOptions = useRef();
    // data 单独处理，会被 G6 修改
    var graphData = useRef();
    /** 隐藏孤立边 */
    var setEdgesState = function (edges) {
        edges.forEach(function (edge) {
            var _a, _b;
            var _c = edge.getModel(), source = _c.source, target = _c.target;
            var sourceVisible = (_a = graphInstance === null || graphInstance === void 0 ? void 0 : graphInstance.findById(source)) === null || _a === void 0 ? void 0 : _a.get('visible');
            var targetVisible = (_b = graphInstance === null || graphInstance === void 0 ? void 0 : graphInstance.findById(target)) === null || _b === void 0 ? void 0 : _b.get('visible');
            if (sourceVisible === false || targetVisible === false) {
                edge.changeVisibility(false);
            }
        });
    };
    var changeData = function () {
        var _a;
        if (!graphInstance) {
            return;
        }
        var currentData = data;
        if (level) {
            currentData = setTreeTag(data);
        }
        graphInstance.changeData(level ? getTreeLevelData(currentData, level) : data);
        (_a = graphInstance.get('eventData')) === null || _a === void 0 ? void 0 : _a.setData(currentData);
        setEdgesState(graphInstance.getEdges());
        if (fitCenter) {
            graphInstance.fitCenter();
        }
    };
    var updateLayout = function () {
        graphInstance === null || graphInstance === void 0 ? void 0 : graphInstance.updateLayout(layout);
        if (fitCenter) {
            graphInstance === null || graphInstance === void 0 ? void 0 : graphInstance.fitCenter();
        }
    };
    var updateNodes = function () {
        if (!graphInstance) {
            return;
        }
        var _a = nodeCfg !== null && nodeCfg !== void 0 ? nodeCfg : {}, nodeType = _a.type, nodeAnchorPoints = _a.anchorPoints, nodeStyle = _a.style, nodeLabelCfg = _a.title;
        graphInstance.getNodes().forEach(function (node) {
            graphInstance.updateItem(node, {
                nodeCfg: nodeCfg,
                markerCfg: markerCfg,
                type: nodeType,
                style: nodeStyle,
                anchorPoints: nodeAnchorPoints,
                labelCfg: nodeLabelCfg,
            });
        });
    };
    var updateEdges = function () {
        if (!graphInstance) {
            return;
        }
        var _a = edgeCfg !== null && edgeCfg !== void 0 ? edgeCfg : {}, edgeType = _a.type, edgeStyle = _a.style, startArrowCfg = _a.startArrow, endArrowCfg = _a.endArrow, labelCfg = _a.label;
        graphInstance.getEdges().forEach(function (edge) {
            // 资金流向图
            if (edgeType === 'fund-line') {
                graphInstance.updateItem(edge, {
                    edgeCfg: edgeCfg,
                });
            }
            else {
                var edgeCfgModel = edge.getModel();
                var startArrow = getArrowCfg(startArrowCfg, edgeCfgModel);
                var endArrow = getArrowCfg(endArrowCfg, edgeCfgModel);
                var _a = labelCfg !== null && labelCfg !== void 0 ? labelCfg : {}, style = _a.style, content = _a.content;
                graphInstance.updateItem(edge, {
                    type: edgeType,
                    label: getCommonCfg(content, edgeCfgModel, graphInstance),
                    labelCfg: {
                        style: getCommonCfg(style, edgeCfgModel, graphInstance),
                    },
                    style: __assign({ stroke: '#ccc', startArrow: startArrow, endArrow: endArrow }, (typeof edgeStyle === 'function' ? edgeStyle(edgeCfgModel, graphInstance) : edgeStyle)),
                });
            }
        });
    };
    // 目前仅支持更新位置
    var updateMarker = function () {
        if (!graphInstance) {
            return;
        }
        graphInstance.getNodes().forEach(function (node) {
            var _a = (typeof markerCfg === 'function' ? markerCfg(node.getModel(), node.get('group')) : markerCfg).position, position = _a === void 0 ? 'right' : _a;
            var _b = node.getBBox(), width = _b.width, height = _b.height;
            var markerShape = node
                .get('group')
                .get('children')
                .find(function (item) { return item.get('name') === 'collapse-icon'; });
            if (markerShape) {
                markerShape === null || markerShape === void 0 ? void 0 : markerShape.attr(__assign({}, getMarkerPosition(position, [width, height])));
            }
        });
    };
    useEffect(function () {
        if (graphInstance && !graphInstance.destroyed) {
            if (isEqual(data, graphData.current)) {
                return;
            }
            graphData.current = deepClone(data);
            changeData();
        }
    }, [data]);
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (graphInstance && !graphInstance.destroyed) {
            if (isEqual(config, graphOptions.current)) {
                return;
            }
            if (!isEqual(layout, (_a = graphOptions.current) === null || _a === void 0 ? void 0 : _a.layout)) {
                updateLayout();
            }
            if (!isEqual(minimapCfg, (_b = graphOptions.current) === null || _b === void 0 ? void 0 : _b.minimapCfg)) {
                processMinimap(minimapCfg, graphInstance);
            }
            if (!isEqual(nodeCfg, (_c = graphOptions.current) === null || _c === void 0 ? void 0 : _c.nodeCfg)) {
                updateNodes();
            }
            if (!isEqual(edgeCfg, (_d = graphOptions.current) === null || _d === void 0 ? void 0 : _d.edgeCfg)) {
                updateEdges();
            }
            if (!isEqual(markerCfg, (_e = graphOptions.current) === null || _e === void 0 ? void 0 : _e.markerCfg)) {
                updateMarker();
            }
            graphOptions.current = config;
        }
    }, [config]);
    useEffect(function () {
        if (graphInstance && !graphInstance.destroyed) {
            var graphSize = getGraphSize(width, height, container);
            graphInstance.changeSize(graphSize[0], graphSize[1]);
        }
    }, [container, width, height]);
    useEffect(function () {
        if (graphInstance && !graphInstance.destroyed) {
            var defaultMode = graphInstance.get('modes').default;
            var removingBehaviors_1 = [];
            defaultMode.forEach(function (be) {
                if (isObject(be)) {
                    removingBehaviors_1.push(be.type);
                }
                else if (isString(be)) {
                    removingBehaviors_1.push(be);
                }
            });
            graphInstance.removeBehaviors(removingBehaviors_1, 'default');
            graphInstance.addBehaviors(behaviors, 'default');
        }
    }, [behaviors]);
    useEffect(function () {
        graphHook.current = graphInstance;
        return function () {
            if (graphInstance && !graphInstance.destroyed) {
                graphInstance.destroy();
                graphInstance = undefined;
            }
        };
    }, []);
    return {
        graphHook: graphHook,
    };
}
