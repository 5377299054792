// 在画布上绘制层级名称
export var drawLayerName = function (graph, layerOrder, rankdir) {
    var nodes = graph.getNodes().map(function (node) { return node.getModel(); });
    if (!graph || !Array.isArray(layerOrder) || !(layerOrder === null || layerOrder === void 0 ? void 0 : layerOrder.length)) {
        return;
    }
    var group = graph.getGroup();
    // 删除已有的层级名称容器分组
    removeLayerNameGroup(graph);
    // 添加层级名称容器的分组
    var layerNameContainerGroup = group.addGroup({ id: 'layer-name-container-group' });
    // 过滤得到节点中实际存在的有效层级
    var effectiveLayer = layerOrder.filter(function (layerName) {
        return nodes.find(function (node) { var _a; return ((_a = node.custom) === null || _a === void 0 ? void 0 : _a.layerName) === layerName; });
    });
    // 存储所有层级名称的x: 需遍历所有节点找出最左侧的节点的x, 实现层级名称纵向对齐
    var minX = Infinity;
    var minY = Infinity;
    // 存储所有层级名称的x: 同层级节点x值的分布最多的值
    var xInfo = [];
    // 存储所有层级名称的y: 同层级节点y值的分布最多的值
    var yInfo = [];
    effectiveLayer.forEach(function (layerName) {
        var countYInfo = {};
        var countXInfo = {};
        nodes.forEach(function (node) {
            if (node.custom.layerName === layerName) {
                // 找到所有节点中最左侧的节点的x坐标
                if (node.x < minX) {
                    minX = node.x;
                }
                // 找到所有节点中最顶部的节点的y坐标
                if (node.y < minY) {
                    minY = node.y;
                }
                var xKey = String(node.x);
                var yKey = String(node.y);
                // 记录所属同层级节点的x、y值分布（考虑节点拖动保存情况）
                if (Object.keys(countXInfo).indexOf(String(xKey)) === -1) {
                    countXInfo[xKey] = 1;
                }
                else {
                    countXInfo[xKey] += 1;
                }
                if (Object.keys(countYInfo).indexOf(String(yKey)) === -1) {
                    countYInfo[yKey] = 1;
                }
                else {
                    countYInfo[yKey] += 1;
                }
            }
        });
        // 找出同层级节点中最多分布的x值（考虑节点拖动保存情况）
        var xInfoKeys = Object.keys(countXInfo);
        var mostX = xInfoKeys[0];
        xInfoKeys.forEach(function (key) {
            if (countXInfo[key] > countXInfo[mostX]) {
                mostX = key;
            }
        });
        xInfo.push(Number(mostX));
        // 找出同层级节点中最多分布的y值（考虑节点拖动保存情况）
        var yInfoKeys = Object.keys(countYInfo);
        var mostY = yInfoKeys[0];
        yInfoKeys.forEach(function (key) {
            if (countYInfo[key] > countYInfo[mostY]) {
                mostY = key;
            }
        });
        yInfo.push(Number(mostY));
    });
    // 在画布中绘制有效层级
    effectiveLayer.forEach(function (layerName, index) {
        if (isNaN(xInfo[index]) || isNaN(yInfo[index])) {
            return;
        }
        // 层级名称换行处理：先暂时处理每隔4个字符换行一次，待G6升级g-canvas 1.x以后使用wordWrap代替
        var text = '';
        var textArray = layerName.split('');
        textArray.forEach(function (item, index) {
            text += index > 0 && index < textArray.length - 1 && index % 4 === 3 ? "".concat(item, "\n") : item;
        });
        // 添加层级名称的分组
        var layerNameGroup = layerNameContainerGroup.addGroup({ id: "layer-name-group-".concat(index) });
        if (rankdir === 'TB') {
            layerNameGroup.setMatrix([1, 0, 0, 0, 1, 0, minX - 102, yInfo[index], 1]);
        }
        else {
            layerNameGroup.setMatrix([1, 0, 0, 0, 1, 0, xInfo[index] + 32, minY - 102, 1]);
        }
        // 监听层级名称分组的拖动: 一期暂不支持，后续和节点拖动保存位置一起做
        // layerNameGroup.on('drag', e => {
        //   const { pointX, pointY } = e;
        //   layerNameGroup.setMatrix([1, 0, 0, 0, 1, 0, pointX, pointY, 1]);
        // });
        // 向层级名称的分组中添加 层级名称图形
        var layerNameShape = layerNameGroup.addShape('text', {
            attrs: {
                text: text,
                x: 0,
                y: 0,
                fontFamily: 'PingFangSC',
                fontSize: 16,
                fontWeight: 400,
                fill: 'rgb(3,34,98)',
                opacity: 0.65,
                lineHeight: 18,
                textBaseline: 'top',
                textAlign: 'center',
                // wordWrap: true, // g-canvas 1.x以上版本才支持
                // wordWrapWidth: 48,
            },
            name: "layer-name-".concat(layerName),
            // draggable: true, // 一期暂不支持，后续和节点拖动保存位置一起做
        });
        var layerNameBbox = layerNameShape.getBBox();
        // 向层级名称的分组中添加 层级名称背景图形
        layerNameGroup.addShape('rect', {
            attrs: {
                x: -34,
                y: -2,
                width: 68,
                height: (layerNameBbox === null || layerNameBbox === void 0 ? void 0 : layerNameBbox.height) + 4,
                fill: '#B8C7E6',
                opacity: 0.12,
            },
            name: "layer-name-".concat(layerName),
            // draggable: true, // 一期暂不支持，后续和节点拖动保存位置一起做
        });
        layerNameShape.toFront();
    });
};
// 删除已有的层级名称分组
export var removeLayerNameGroup = function (graph) {
    var group = graph.getGroup();
    var layerNameGroup = group.findById('layer-name-container-group');
    if (layerNameGroup) {
        group.removeChild(layerNameGroup);
    }
};
