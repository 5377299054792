// 获取文本的字节长度
export var getNodeStrSize = function (node) {
    var _a = (node || {}).measure, _b = _a === void 0 ? {} : _a, _c = _b.formattedValue, formattedValue = _c === void 0 ? '' : _c, _d = _b.value, value = _d === void 0 ? '' : _d, _e = _b.formattedUnit, formattedUnit = _e === void 0 ? '' : _e;
    var nodeStr = (formattedValue !== undefined && formattedValue !== null
        ? "".concat(formattedValue).concat(formattedUnit)
        : value) || '';
    return nodeStr.replace(/[^\x00-\xff]/g, '00').length;
};
// 获取文本最长的节点
export var getMaxSizeNode = function (nodes) {
    if (nodes === void 0) { nodes = []; }
    return nodes.reduce(function (prevNode, node) {
        var prevSize = getNodeStrSize(prevNode);
        var nodeSize = getNodeStrSize(node);
        return prevSize > nodeSize ? prevNode : node;
    });
};
// 获取字符串实际渲染的宽度
export var getWordsWidth = function (text, font) {
    if (text === void 0) { text = ''; }
    if (text === '') {
        return 0;
    }
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.font = font;
    var width = context.measureText(text).width;
    canvas = null;
    return width;
};
// 获取最大节点宽度
export var getNodeMaxSize = function (nodes) {
    if (nodes === void 0) { nodes = []; }
    if (!(nodes === null || nodes === void 0 ? void 0 : nodes.length)) {
        return 150;
    }
    var maxSizeNode = getMaxSizeNode(nodes);
    var _a = maxSizeNode.measure, _b = _a === void 0 ? {} : _a, _c = _b.formattedValue, formattedValue = _c === void 0 ? '' : _c, _d = _b.value, value = _d === void 0 ? '' : _d, _e = _b.formattedUnit, formattedUnit = _e === void 0 ? '' : _e;
    // 是否展示formatValue
    var isShowFormatValue = formattedValue !== undefined && formattedValue !== null;
    var displayValue = isShowFormatValue ? formattedValue : value;
    var displayValueWidth = getWordsWidth("".concat(displayValue), '600 20px Roboto-Medium');
    // 单位不为空时才计算单位宽度 unit的paddingLeft 4
    var unitWidth = isShowFormatValue && formattedUnit
        ? getWordsWidth("".concat(formattedUnit), '600 20px PingFangSC') + 4
        : 0;
    // 节点大小 value + unit + 左右padding
    var nodeSize = displayValueWidth + unitWidth + 24;
    // 节点最小宽度 150
    return Math.max(nodeSize, 150);
};
