import { wrapBehavior, each, deepMix } from '@antv/util';
var PluginBase = /** @class */ (function () {
    /**
     * 插件基类的构造函数
     * @param cfgs 插件的配置项
     */
    function PluginBase(cfgs) {
        this._cfgs = deepMix(this.getDefaultCfgs(), cfgs);
        this._events = {};
        this.destroyed = false;
    }
    /**
     * 获取默认的插件配置
     */
    PluginBase.prototype.getDefaultCfgs = function () {
        return {};
    };
    /**
     * 初始化插件
     * @param graph IGraph 实例
     */
    PluginBase.prototype.initPlugin = function (graph) {
        var self = this;
        self.set('graph', graph);
        var events = self.getEvents();
        var bindEvents = {};
        each(events, function (v, k) {
            var event = wrapBehavior(self, v);
            bindEvents[k] = event;
            graph.on(k, event);
        });
        this._events = bindEvents;
        this.init();
    };
    /**
     * 获取插件中的事件和事件处理方法，供子类实现
     */
    PluginBase.prototype.getEvents = function () {
        return {};
    };
    /**
     * 获取配置项中的某个值
     * @param key 键值
     */
    PluginBase.prototype.get = function (key) {
        var _a;
        return (_a = this._cfgs) === null || _a === void 0 ? void 0 : _a[key];
    };
    /**
     * 将指定的值存储到 cfgs 中
     * @param key 键值
     * @param val 设置的值
     */
    PluginBase.prototype.set = function (key, val) {
        this._cfgs[key] = val;
    };
    /**
     * 销毁方法，供子类复写
     */
    PluginBase.prototype.destroy = function () { };
    /**
     * 销毁插件
     */
    PluginBase.prototype.destroyPlugin = function () {
        this.destroy();
        var graph = this.get('graph');
        var events = this._events;
        each(events, function (v, k) {
            graph.off(k, v);
        });
        this._events = null;
        this._cfgs = null;
        this.destroyed = true;
    };
    return PluginBase;
}());
export default PluginBase;
