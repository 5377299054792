var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import G6 from '@antv/g6';
import { defaultMargin, defaultLabelStyle, defaultCardStyle } from '../../constants';
import { getStyle, getCssPadding, createMarker } from '../../utils';
// 组织架构图
export var registerOrganizationCardNode = function () {
    var defaultIconStyle = {
        width: 12,
        height: 12,
    };
    G6.registerNode('organization-card', {
        draw: function (cfg, group) {
            var _a, _b, _c;
            if (cfg === void 0) { cfg = {}; }
            var _d = cfg.value, originValue = _d === void 0 ? {} : _d, nodeCfg = cfg.nodeCfg, markerCfg = cfg.markerCfg, draggable = cfg._draggable;
            var value = __assign({}, originValue);
            var isOld = false;
            /** 兼容历史数据 */
            if (value.text) {
                isOld = true;
                value.name = value.text;
            }
            if (value.value) {
                isOld = true;
                value.title = value.value;
            }
            var _e = nodeCfg, style = _e.style, _f = _e.padding, padding = _f === void 0 ? 0 : _f, _g = _e.label, label = _g === void 0 ? {} : _g, autoWidth = _e.autoWidth, customContent = _e.customContent;
            var labelStyle = label.style;
            var paddingArray = getCssPadding(padding);
            var size = ((cfg === null || cfg === void 0 ? void 0 : cfg.size) || [100, 30]);
            if (typeof size === 'number')
                size = [size, size];
            var height = 0; // 统计容器总高度，动态设置，宽度不做调整
            var contentWidth = size[0] - paddingArray[1] - paddingArray[3];
            // card box
            var cardStyle = getStyle(style, cfg, group);
            var shape = group.addShape('rect', {
                attrs: __assign(__assign({ x: 0, y: 0, width: size[0], height: size[1] }, defaultCardStyle), cardStyle),
                name: 'main-box',
                draggable: draggable,
            });
            if (value) {
                // 兼容历史数据
                var getKey_1 = function (key) {
                    if (isOld) {
                        var keys = {
                            name: 'text',
                            title: 'value',
                        };
                        return keys[key];
                    }
                    return key;
                };
                height += paddingArray[0];
                var createRowItems_1 = function (item, contentWidth, startX, index) {
                    if (index === void 0) { index = 0; }
                    var iconWidth = 0;
                    var rowHeight = [];
                    var keys = ['icon', 'name', 'title'];
                    var getXY = function (type, layoutCfg) {
                        var _a = layoutCfg.fontSize, fontSize = _a === void 0 ? 12 : _a;
                        var x = 0;
                        var y = 0;
                        var offsetX = autoWidth
                            ? iconWidth
                                ? iconWidth + paddingArray[3]
                                : iconWidth
                            : (contentWidth + iconWidth) / 2;
                        switch (type) {
                            case 'icon':
                                x = startX;
                                y = height;
                                break;
                            case 'name':
                                x = startX + offsetX;
                                y = item.title ? paddingArray[0] : (size[1] - fontSize) / 2;
                                break;
                            case 'title':
                                x = startX + offsetX;
                                y = item.name ? paddingArray[0] + rowHeight[1] + defaultMargin : (size[1] - fontSize) / 2;
                                break;
                            default:
                                break;
                        }
                        return { x: x, y: y };
                    };
                    keys.forEach(function (key, keyIndex) {
                        var isIcon = key.startsWith('icon');
                        var shapeStyle = getStyle(labelStyle, cfg, group, getKey_1(key));
                        if (key === 'icon' && item[key]) {
                            iconWidth = shapeStyle.width || 32;
                        }
                        var keyShape = group.addShape(isIcon ? 'image' : 'text', {
                            attrs: __assign(__assign(__assign(__assign({ textBaseline: 'top', textAlign: autoWidth ? 'start' : 'center' }, getXY(key, shapeStyle)), { text: item[key], img: item[key] }), (isIcon ? defaultIconStyle : defaultLabelStyle)), shapeStyle),
                            name: "".concat(key, "-").concat(index, "-").concat(keyIndex),
                            draggable: draggable,
                        });
                        rowHeight.push(keyShape.getBBox().height);
                    });
                    return rowHeight;
                };
                var createItems = function (item, index) {
                    var _a;
                    if (index === void 0) { index = 0; }
                    var itemsHeight = [];
                    if (customContent) {
                        itemsHeight.push((_a = customContent(item, group, {
                            startX: paddingArray[3],
                            startY: height,
                            width: contentWidth,
                        })) !== null && _a !== void 0 ? _a : 0);
                    }
                    else {
                        itemsHeight.push.apply(itemsHeight, createRowItems_1(item, contentWidth, paddingArray[3], index));
                    }
                    height += Math.max.apply(Math, itemsHeight);
                };
                createItems(value);
            }
            shape === null || shape === void 0 ? void 0 : shape.attr('height', Math.max(height + paddingArray[2], size[1]));
            if (autoWidth) {
                var maxX = Math.max.apply(Math, __spreadArray([size[0]], (_a = group === null || group === void 0 ? void 0 : group.getChildren()) === null || _a === void 0 ? void 0 : _a.map(function (childrenShape) {
                    return (childrenShape.getBBox().maxX || 0) + paddingArray[1];
                }), false));
                shape === null || shape === void 0 ? void 0 : shape.attr('width', maxX);
            }
            // collapse marker
            if (markerCfg) {
                var stateCollapsed = ((_c = (_b = group === null || group === void 0 ? void 0 : group.get('item')) === null || _b === void 0 ? void 0 : _b.getModel()) !== null && _c !== void 0 ? _c : {}).collapsed;
                var _h = shape.getBBox(), shapeWidth = _h.width, shapeHeight = _h.height;
                var _j = typeof markerCfg === 'function' ? markerCfg(cfg, group) : markerCfg, show = _j.show, _k = _j.position, position = _k === void 0 ? 'right' : _k, collapsed = _j.collapsed, markerStyle = _j.style;
                createMarker({
                    show: show,
                    position: position,
                    collapsed: stateCollapsed !== null && stateCollapsed !== void 0 ? stateCollapsed : collapsed,
                    style: markerStyle,
                }, group, [shapeWidth, shapeHeight]);
                shape.attr('defaultCollapsed', collapsed);
            }
            return shape;
        },
        update: undefined,
    }, 'single-node');
};
