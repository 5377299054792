/**
 * 深克隆
 * @param source 要深克隆的目标对象
 */
export var deepClone = function (source) {
    if (!source || typeof source !== 'object') {
        return source;
    }
    var target;
    if (Array.isArray(source)) {
        target = source.map(function (item) { return deepClone(item); });
    }
    else if (source instanceof HTMLElement) {
        target = source;
    }
    else {
        target = {};
        Object.keys(source).forEach(function (key) {
            return (target[key] = deepClone(source[key]));
        });
    }
    return target;
};
