import { EdgeType } from '../types';
// 获取边的类型，起点和终点的anchorPoint索引值
export var getEdgeAnchorAndType = function (graph, edge, layerOrder, rankdir) {
    var nodes = graph.getNodes().map(function (node) { return node.getModel(); });
    var sourceNode = nodes.find(function (node) { return node.id === edge.source; });
    var targetNode = nodes.find(function (node) { return node.id === edge.target; });
    var sourceLayer = layerOrder.findIndex(function (item) { var _a; return item === ((_a = sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.custom) === null || _a === void 0 ? void 0 : _a.layerName); });
    var targetLayer = layerOrder.findIndex(function (item) { var _a; return item === ((_a = targetNode === null || targetNode === void 0 ? void 0 : targetNode.custom) === null || _a === void 0 ? void 0 : _a.layerName); });
    var sourceAnchor;
    var targetAnchor;
    var type;
    // 同层级节点之间的边，
    if (sourceLayer !== -1 && sourceLayer === targetLayer) {
        type = EdgeType.CONV_LINE;
        // 起始点的连接点根据起始点的位置顺序来
        if (rankdir === 'LR') {
            sourceAnchor = sourceNode.y < targetNode.y ? 1 : 0;
            targetAnchor = sourceNode.y < targetNode.y ? 0 : 1;
        }
        else {
            sourceAnchor = sourceNode.x < targetNode.x ? 3 : 2;
            targetAnchor = sourceNode.x < targetNode.x ? 2 : 3;
        }
    }
    else {
        if (rankdir === 'LR') {
            type = EdgeType.CONV_CUBIC_HORIZONTAL;
            sourceAnchor = sourceNode.x < targetNode.x ? 3 : 2;
            targetAnchor = sourceNode.x < targetNode.x ? 2 : 3;
        }
        else {
            type = EdgeType.CONV_CUBIC_VERTICAL;
            sourceAnchor = sourceNode.y < targetNode.y ? 1 : 0;
            targetAnchor = sourceNode.y < targetNode.y ? 0 : 1;
        }
    }
    return {
        type: type,
        sourceAnchor: sourceAnchor,
        targetAnchor: targetAnchor,
    };
};
// 更新边对应的类型、起点和终点的连接点
export var updateEdgeAnchorAndType = function (graph, layerOrder, rankdir) {
    graph.getEdges().forEach(function (edge) {
        var edgeModel = edge.getModel();
        var _a = getEdgeAnchorAndType(graph, edgeModel, layerOrder, rankdir), type = _a.type, sourceAnchor = _a.sourceAnchor, targetAnchor = _a.targetAnchor;
        graph.updateItem(edgeModel.id, {
            type: type,
            sourceAnchor: sourceAnchor,
            targetAnchor: targetAnchor,
        });
    });
};
// 获取自定义箭头
export var getArrowConfig = function (color) {
    if (color === void 0) { color = '#B8C3D9'; }
    return {
        path: 'M 14,-4 C 14,-4,11,0,14,4 L 4,0 L 14,-4 Z',
        d: 2,
        fill: color,
        stroke: color,
        lineWidth: 1,
        lineDash: [],
    };
};
