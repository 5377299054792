import { Log } from './log';
export var getGraphSize = function (width, height, container) {
    var CANVAS_WIDTH;
    var CANVAS_HEIGHT;
    if (container && container.current) {
        CANVAS_WIDTH = container.current.offsetWidth;
        CANVAS_HEIGHT = container.current.offsetHeight || 500;
    }
    if ((!width && !CANVAS_WIDTH) || (!height && !CANVAS_HEIGHT)) {
        Log.warn('请为 Graph 指定 width 与 height！否则将使用默认值 500 * 500');
        return [500, 500];
    }
    return [width || CANVAS_WIDTH || 500, height || CANVAS_HEIGHT || 500];
};
