var EventData = /** @class */ (function () {
    function EventData(data) {
        data && this.setData(data);
    }
    EventData.prototype.getData = function () {
        return this.data;
    };
    EventData.prototype.setData = function (data) {
        this.data = data;
    };
    return EventData;
}());
export default EventData;
