import G6 from '@antv/g6';
import { setItemStateStyle } from '../utils';
export var registerConvNode = function () {
    // 自定义转化节点
    G6.registerNode('conv-node', {
        options: {},
        draw: function (cfg, group) {
            var _a = cfg.custom, custom = _a === void 0 ? {} : _a, _b = cfg.style, stroke = _b.stroke, textColor = _b.textColor, _c = cfg.size, size = _c === void 0 ? 150 : _c;
            var label = cfg.label;
            var _d = custom.measure, measure = _d === void 0 ? {} : _d, _e = custom.relatedMeasures, relatedMeasures = _e === void 0 ? [] : _e;
            var showFormattedValue = measure.formattedValue !== undefined && measure.formattedValue !== null;
            // 最外层描边（光晕）
            var keyShape = group.addShape('rect', {
                attrs: {
                    x: 0,
                    y: 0,
                    width: size,
                    height: 122,
                    lineWidth: 0,
                    stroke: stroke,
                    opacity: 0.3,
                    radius: 4,
                },
                name: 'node-key-shape',
                draggable: true,
            });
            var keyShapeBbox = keyShape.getBBox();
            group.addShape('rect', {
                attrs: {
                    x: 0,
                    y: 0,
                    width: keyShapeBbox.width,
                    height: keyShapeBbox.height,
                    stroke: stroke,
                    fill: '#fff',
                    radius: 4,
                },
                name: 'node-inner-border-shape',
                draggable: true,
            });
            group.addShape('rect', {
                attrs: {
                    x: 0,
                    y: 0,
                    width: 4,
                    height: keyShapeBbox.height,
                    fill: stroke,
                    radius: [4, 0, 0, 4],
                },
                name: 'node-left-border-shape',
                draggable: true,
            });
            var showNameMore = label.length > 10;
            // 节点名称
            group === null || group === void 0 ? void 0 : group.addShape('text', {
                attrs: {
                    text: showNameMore ? "".concat(label.slice(0, 11), "...") : label,
                    x: 12,
                    y: 12,
                    fontFamily: 'PingFangSC',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 17,
                    textBaseline: 'top',
                    fill: textColor,
                    opacity: 0.85,
                },
                name: 'node-name',
            });
            // 主指标名称
            group === null || group === void 0 ? void 0 : group.addShape('text', {
                attrs: {
                    text: measure.name || '',
                    x: 12,
                    y: 37,
                    fontFamily: 'PingFangSC',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 17,
                    textBaseline: 'top',
                    fill: textColor,
                    opacity: 0.85,
                },
                name: 'node-measure-name',
                draggable: true,
            });
            // 主指标值
            var measureShape = group === null || group === void 0 ? void 0 : group.addShape('text', {
                attrs: {
                    text: (showFormattedValue ? measure.formattedValue : measure.value) || '',
                    x: 12,
                    y: 58,
                    fontFamily: 'Roboto-Medium',
                    fontSize: 20,
                    fontWeight: 600,
                    lineHeight: 28,
                    textBaseline: 'top',
                    fill: textColor,
                    opacity: 0.85,
                },
                name: 'node-measure-value',
                draggable: true,
            });
            if (showFormattedValue) {
                var measureShapeBbox = measureShape.getBBox();
                // 主指标单位
                group === null || group === void 0 ? void 0 : group.addShape('text', {
                    attrs: {
                        text: measure.formattedUnit || '',
                        x: 12 + measureShapeBbox.width + 4,
                        y: 56,
                        fontFamily: 'PingFangSC',
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: 28,
                        textBaseline: 'top',
                        fill: textColor,
                        opacity: 0.85,
                    },
                    name: 'node-measure-unit',
                    draggable: true,
                });
            }
            // 相关指标信息
            relatedMeasures.forEach(function (relatedMeasure, index) {
                var showFormattedValue = relatedMeasure.formattedValue !== undefined;
                var relatedMeasureText = "".concat(relatedMeasure.name, " ").concat(showFormattedValue ? relatedMeasure.formattedValue : relatedMeasure.value).concat(showFormattedValue && relatedMeasure.formattedUnit);
                group === null || group === void 0 ? void 0 : group.addShape('text', {
                    attrs: {
                        text: relatedMeasureText,
                        x: 12,
                        y: 95,
                        fontFamily: 'PingFangSC',
                        fontSize: 12,
                        fontWeight: 400,
                        lineHeight: 17,
                        textBaseline: 'top',
                        fill: textColor,
                        opacity: 0.45,
                    },
                    name: "node-releted-measure-".concat(index),
                    draggable: true,
                });
            });
            return keyShape;
        },
        setState: function (name, value, node) {
            // 设置状态样式
            setItemStateStyle(node, 'node');
        },
    });
};
