var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import { isObject } from '@antv/util';
export var registerCustomItems = function () {
    G6.registerNode('card-node', {
        draw: function (cfg, group) {
            var _a;
            if (cfg === void 0) { cfg = {}; }
            var size = cfg.size || [100, 30];
            if (typeof size === 'number')
                size = [size, size];
            var style = __assign({ radius: 2, fill: '#fff' }, cfg.style);
            var color = style.stroke || cfg.color || '#5B8FF9';
            var r = style.radius || 0;
            var shape = group.addShape('rect', {
                attrs: __assign({ x: 0, y: 0, width: size[0], height: size[1], stroke: color }, style),
                name: 'main-box',
                draggable: true,
            });
            // title text
            var title = cfg.title || cfg.label;
            var titleTextShape;
            var labelStyle = ((_a = cfg.labelCfg) === null || _a === void 0 ? void 0 : _a.style) || {};
            if (title) {
                var titleStyle = __assign({ fill: '#fff' }, labelStyle);
                titleTextShape = group.addShape('text', {
                    attrs: __assign(__assign({ textBaseline: 'top', x: 8, y: 2, 
                        // lineHeight: 20,
                        text: title }, titleStyle), { fill: '#fff' }),
                    name: 'title',
                });
            }
            var titleBox = titleTextShape ? titleTextShape.getBBox() : { height: size[1] / 2 };
            // title rect
            var titleRectShape = group.addShape('rect', {
                attrs: {
                    x: 0,
                    y: 0,
                    width: size[0],
                    height: titleBox.height + 4,
                    fill: color,
                    radius: [r, r, 0, 0],
                },
                name: 'title-rect',
                draggable: true,
            });
            titleTextShape === null || titleTextShape === void 0 ? void 0 : titleTextShape.toFront();
            // marker
            var markerShape;
            if (cfg === null || cfg === void 0 ? void 0 : cfg.children) {
                markerShape = group.addShape('marker', {
                    attrs: {
                        x: size[0] / 2,
                        y: 0,
                        r: 6,
                        cursor: 'pointer',
                        symbol: cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse,
                        stroke: color,
                        lineWidth: 1,
                        fill: '#fff',
                    },
                    name: 'collapse-icon',
                });
            }
            // description
            var description = cfg && cfg.description ? cfg.description : undefined;
            var titleRectBox = titleRectShape.getBBox();
            var descriptionTextShape;
            if (description) {
                descriptionTextShape = group.addShape('text', {
                    attrs: __assign({ textBaseline: 'top', x: 8, y: titleRectBox.height + 8, text: description }, labelStyle),
                    name: "description",
                });
            }
            if (descriptionTextShape) {
                var desTextShapeBBox = descriptionTextShape.getBBox();
                var height = titleRectBox.height + 16 + desTextShapeBBox.height;
                var width = size[0] > desTextShapeBBox.width + 16 ? size[0] : desTextShapeBBox.width + 16;
                shape.attr({ width: width, height: height });
                titleRectShape === null || titleRectShape === void 0 ? void 0 : titleRectShape.attr('width', width);
                markerShape === null || markerShape === void 0 ? void 0 : markerShape.attr({
                    x: width,
                    y: height / 2,
                });
            }
            return shape;
        },
        update: undefined,
    }, 'single-node');
    G6.registerNode('round-rect', {
        drawShape: function (cfg, group) {
            if (cfg === void 0) { cfg = {}; }
            var size = cfg.size || [100, 30];
            if (typeof size === 'number')
                size = [size, size];
            var style = cfg.style || {};
            var color = style.stroke || cfg.color || '#5B8FF9';
            var fill = style.fill || '#fff';
            style = __assign({ width: size[0], height: size[1], radius: size[1] / 2, fill: fill, lineWidth: 1.2, stroke: color }, style);
            var rect = group.addShape('rect', {
                attrs: __assign({ x: -size[0] / 2, y: -size[1] / 2 }, style),
                name: 'rect-shape',
            });
            // circles for anchor points
            group.addShape('circle', {
                attrs: {
                    x: -size[0] / 2,
                    y: 0,
                    r: 3,
                    fill: style.stroke,
                },
                name: 'circle-shape',
            });
            group.addShape('circle', {
                attrs: {
                    x: size[0] / 2,
                    y: 0,
                    r: 3,
                    fill: style.stroke,
                },
                name: 'circle-shape2',
            });
            return rect;
        },
        getAnchorPoints: function getAnchorPoints() {
            return [
                [0, 0.5],
                [1, 0.5],
            ];
        },
        update: function update(cfg, item) {
            var _a;
            if (cfg === void 0) { cfg = {}; }
            var group = item.getContainer();
            var children = group.get('children');
            var node = children[0];
            var circleLeft = children[1];
            var circleRight = children[2];
            var stroke = ((_a = cfg.style) === null || _a === void 0 ? void 0 : _a.stroke) || '#5B8FF9';
            if (stroke) {
                node.attr('stroke', stroke);
                circleLeft.attr('fill', stroke);
                circleRight.attr('fill', stroke);
            }
        },
    }, 'single-node');
    G6.registerEdge('fund-polyline', {
        draw: function draw(cfg, group) {
            var _a;
            if (cfg === void 0) { cfg = {}; }
            var startPoint = cfg.startPoint;
            var endPoint = cfg.endPoint;
            var Ydiff = endPoint.y - startPoint.y;
            var slope = Ydiff !== 0 ? Math.min(500 / Math.abs(Ydiff), 20) : 0;
            var cpOffset = slope > 15 ? 0 : 16;
            var offset = Ydiff < 0 ? cpOffset : -cpOffset;
            var line1EndPoint = {
                x: startPoint.x + slope,
                y: endPoint.y + offset,
            };
            var line2StartPoint = {
                x: line1EndPoint.x + cpOffset,
                y: endPoint.y,
            };
            // 控制点坐标
            var controlPoint = {
                x: ((line1EndPoint.x - startPoint.x) * (endPoint.y - startPoint.y)) / (line1EndPoint.y - startPoint.y) +
                    startPoint.x,
                y: endPoint.y,
            };
            var path = [
                ['M', startPoint.x, startPoint.y],
                ['L', line1EndPoint.x, line1EndPoint.y],
                ['Q', controlPoint.x, controlPoint.y, line2StartPoint.x, line2StartPoint.y],
                ['L', endPoint.x, endPoint.y],
            ];
            if (Math.abs(Ydiff) <= 5) {
                path = [
                    ['M', startPoint.x, startPoint.y],
                    ['L', endPoint.x, endPoint.y],
                ];
            }
            var style = cfg.style;
            var stroke = style.stroke || ((cfg === null || cfg === void 0 ? void 0 : cfg.colorMap) && cfg.colorMap[cfg.dataType])
                ? (cfg === null || cfg === void 0 ? void 0 : cfg.colorMap)[cfg === null || cfg === void 0 ? void 0 : cfg.dataType]
                : '#5B8FF9';
            var endArrow = ((_a = cfg.style) === null || _a === void 0 ? void 0 : _a.endArrow) || false;
            if (isObject(endArrow))
                endArrow.fill = stroke;
            var line = group.addShape('path', {
                attrs: {
                    path: path,
                    stroke: stroke,
                    lineWidth: style.lineWidth || 1.2,
                    endArrow: endArrow,
                },
                name: 'path-shape',
            });
            var labelLeftOffset = 0;
            var labelTopOffset = 8;
            // label
            var labelTextShape;
            var textBeginX = line2StartPoint.x + labelLeftOffset;
            if (cfg === null || cfg === void 0 ? void 0 : cfg.label) {
                labelTextShape = group.addShape('text', {
                    attrs: {
                        text: cfg.label,
                        x: textBeginX,
                        y: endPoint.y - labelTopOffset - 2,
                        fontSize: 14,
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#000',
                    },
                    name: 'text-shape-label',
                });
            }
            // dataType
            if (cfg === null || cfg === void 0 ? void 0 : cfg.dataType) {
                var labelTextShapeBBox = labelTextShape ? labelTextShape.getBBox() : { height: 0 };
                group.addShape('text', {
                    attrs: {
                        text: cfg.dataType,
                        x: textBeginX,
                        y: endPoint.y - labelTopOffset - labelTextShapeBBox.height - 2,
                        fontSize: 10,
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#000',
                    },
                    name: 'text-shape-type',
                });
            }
            // subLabel
            if (cfg === null || cfg === void 0 ? void 0 : cfg.subLabel) {
                group.addShape('text', {
                    attrs: {
                        text: cfg.subLabel,
                        x: textBeginX,
                        y: endPoint.y + labelTopOffset + 4,
                        fontSize: 12,
                        fontWeight: 300,
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#000',
                    },
                    name: 'text-shape-sub-label',
                });
            }
            return line;
        },
        update: undefined,
    }, 'single-edge');
    G6.registerEdge('flow-line', {
        draw: function (cfg, group) {
            if (cfg === void 0) { cfg = {}; }
            var startPoint = cfg.startPoint, endPoint = cfg.endPoint;
            var _a = cfg.style, style = _a === void 0 ? {} : _a;
            var shape = group.addShape('path', {
                attrs: {
                    stroke: style.stroke,
                    endArrow: style.endArrow,
                    path: [
                        ['M', startPoint.x, startPoint.y],
                        ['L', startPoint.x, (startPoint.y + endPoint.y) / 2],
                        ['L', endPoint.x, (startPoint.y + endPoint.y) / 2],
                        ['L', endPoint.x, endPoint.y],
                    ],
                },
            });
            return shape;
        },
    });
};
export var customIconNode = function (params) {
    G6.registerNode('icon-node', {
        options: {
            size: [60, 20],
            stroke: '#91d5ff',
            fill: '#91d5ff',
        },
        draw: function (cfg, group) {
            if (cfg === void 0) { cfg = {}; }
            // @ts-ignore
            var styles = this.getShapeStyle(cfg);
            var _a = cfg.labelCfg, labelCfg = _a === void 0 ? {} : _a;
            var keyShape = group.addShape('rect', {
                attrs: __assign(__assign({}, styles), { x: 0, y: 0 }),
            });
            /**
             * leftIcon 格式如下：
             *  {
             *    style: ShapeStyle;
             *    img: ''
             *  }
             */
            var style = {
                fill: '#e6fffb',
            };
            var img = 'https://g.alicdn.com/cm-design/arms-trace/1.0.155/styles/armsTrace/images/TAIR.png';
            if (cfg.leftIcon) {
                style = __assign(__assign({}, style), cfg.leftIcon.style);
                img = cfg.leftIcon.img;
            }
            group.addShape('rect', {
                attrs: __assign({ x: 1, y: 1, width: 38, height: styles.height - 2 }, style),
            });
            group.addShape('image', {
                attrs: {
                    x: 8,
                    y: 8,
                    width: 24,
                    height: 24,
                    img: img,
                },
                name: 'image-shape',
            });
            if (params.enableEdit) {
                group.addShape('marker', {
                    attrs: {
                        x: styles.width / 3,
                        y: styles.height + 6,
                        r: 6,
                        stroke: '#73d13d',
                        cursor: 'pointer',
                        symbol: G6.Marker.expand,
                    },
                    name: 'add-item',
                });
                group.addShape('marker', {
                    attrs: {
                        x: (styles.width * 2) / 3,
                        y: styles.height + 6,
                        r: 6,
                        stroke: '#ff4d4f',
                        cursor: 'pointer',
                        symbol: G6.Marker.collapse,
                    },
                    name: 'remove-item',
                });
            }
            if (cfg.label) {
                group.addShape('text', {
                    attrs: __assign(__assign({}, labelCfg.style), { text: cfg.label, x: styles.width / 2, y: styles.height / 1.5 }),
                });
            }
            return keyShape;
        },
    }, 'rect');
};
