import { isObject, isString, clone } from '@antv/util';
export var cloneBesidesImg = function (obj) {
    var clonedObj = {};
    Object.keys(obj).forEach(function (key1) {
        var obj2 = obj[key1];
        if (isObject(obj2)) {
            var clonedObj2_1 = {};
            Object.keys(obj2).forEach(function (key2) {
                var v = obj2[key2];
                if (key2 === 'img' && !isString(v))
                    return;
                clonedObj2_1[key2] = clone(v);
            });
            clonedObj[key1] = clonedObj2_1;
        }
        else {
            clonedObj[key1] = clone(obj2);
        }
    });
    return clonedObj;
};
