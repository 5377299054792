import { setItemStateStyle, getArrowConfig } from '../utils';
export default {
    draw: function (cfg, group, path) {
        var ratio = cfg.custom.ratio, _a = cfg.style, dashed = _a.dashed, stroke = _a.stroke, _b = _a.endArrow, endArrow = _b === void 0 ? true : _b, _c = _a.sizeMapping, sizeMapping = _c === void 0 ? true : _c;
        var borderWidth = 0;
        // 如果开启了大小映射，则根据比率映射边框宽度
        if (sizeMapping) {
            borderWidth = !isNaN(ratio) && ratio !== Infinity ? Math.max(2, Math.min(72, 72 * ratio)) : 2;
        }
        // 添加边框
        var keyShape = group === null || group === void 0 ? void 0 : group.addShape('path', {
            attrs: {
                stroke: stroke,
                opacity: 0.2,
                path: path,
                lineWidth: borderWidth,
            },
            name: 'path-border-shape',
        });
        // 路径主线
        group === null || group === void 0 ? void 0 : group.addShape('path', {
            attrs: {
                stroke: stroke,
                path: path,
                endArrow: endArrow ? getArrowConfig(stroke) : null,
                lineDash: dashed ? [3, 3] : [],
            },
            name: 'path-shape',
        });
        return keyShape;
    },
    afterDraw: function (cfg, group) {
        var label = cfg.label, labelFill = cfg.style.labelFill;
        var shape = group === null || group === void 0 ? void 0 : group.get('children')[0];
        // 获取路径图形的中点坐标
        var midPoint = shape.getPoint(0.5);
        if (label) {
            // 绘制label
            var labelShape = group === null || group === void 0 ? void 0 : group.addShape('text', {
                attrs: {
                    text: label,
                    x: midPoint.x,
                    y: midPoint.y,
                    fill: labelFill,
                    opacity: 0.85,
                    fontFamily: 'Roboto-Regular',
                    fontSize: 12,
                    textAlign: 'center',
                    textBaseline: 'middle',
                },
                name: 'label-shape',
            });
            var labelBbox = labelShape === null || labelShape === void 0 ? void 0 : labelShape.getBBox();
            var padding = 5; // 四周的留白
            // 绘制label背景框
            group === null || group === void 0 ? void 0 : group.addShape('rect', {
                attrs: {
                    width: labelBbox.width + padding * 2,
                    height: labelBbox.height + padding * 2,
                    x: midPoint.x - labelBbox.width / 2 - padding,
                    y: midPoint.y - labelBbox.height / 2 - padding,
                    fill: '#fff',
                },
                name: 'label-bg-shape',
                labelRelated: true,
            });
            // 边名称labelShape上移一个层级
            if (labelShape) {
                labelShape.toFront();
            }
        }
    },
    setState: function (name, value, edge) {
        // 设置状态样式
        setItemStateStyle(edge, 'edge');
    },
};
