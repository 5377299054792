// 边类型
export var EdgeType;
(function (EdgeType) {
    EdgeType["CONV_LINE"] = "conv-line";
    EdgeType["CONV_CUBIC_VERTICAL"] = "conv-cubic-vertical";
    EdgeType["CONV_CUBIC_HORIZONTAL"] = "conv-cubic-horizontal";
})(EdgeType || (EdgeType = {}));
// 元素状态
export var ITEM_STATE;
(function (ITEM_STATE) {
    ITEM_STATE["Active"] = "active";
    ITEM_STATE["Default"] = "default";
    ITEM_STATE["Selected"] = "selected";
})(ITEM_STATE || (ITEM_STATE = {}));
