var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import G6 from '@antv/g6';
import ErrorBoundary from '../../../errorBoundary';
import useGraph from '../../hooks/useGraph';
import { ChartLoading } from '../../../utils';
import { getGraphSize, getGraphId, useProps } from '../utils';
import { bindDefaultEvents, renderGraph, getDefaultEdgeArrowCfg } from '../utils';
import { registerCardNode } from '../customItems';
import { defaultNodeAnchorPoints, defaultNodeSize, defaultStateStyles, defaultNodeStyle } from '../constants';
var graphs = {};
registerCardNode();
var defaultLayout = {
    type: 'compactBox',
    direction: 'LR',
    getId: function (d) {
        return d.id;
    },
    getHeight: function () {
        return 60;
    },
    getWidth: function () {
        return 16;
    },
    getVGap: function () {
        return 16;
    },
    getHGap: function () {
        return 100;
    },
};
var defaultProps = {
    nodeType: 'card',
    edgeType: 'cubic-horizontal',
    behaviors: ['zoom-canvas', 'drag-canvas'],
    nodeAnchorPoints: defaultNodeAnchorPoints,
    nodeSize: defaultNodeSize,
    layout: defaultLayout,
    animate: true,
    markerPosition: 'right',
    nodeStateStyles: defaultStateStyles,
    edgeStateStyles: defaultStateStyles,
    collapseExpand: true,
    showArrow: true,
    arrowType: 'vee',
    autoFit: true,
    style: {
        height: 'inherit',
    },
};
var IndentedTreeGraph = function (props) {
    var uProps = useProps(props, defaultProps);
    var data = uProps.data, className = uProps.className, style = uProps.style, width = uProps.width, height = uProps.height, _a = uProps.nodeType, nodeType = _a === void 0 ? 'card' : _a, _b = uProps.edgeType, edgeType = _b === void 0 ? 'cubic-horizontal' : _b, _c = uProps.behaviors, behaviors = _c === void 0 ? ['zoom-canvas', 'drag-canvas'] : _c, _d = uProps.nodeAnchorPoints, nodeAnchorPoints = _d === void 0 ? defaultNodeAnchorPoints : _d, _e = uProps.nodeSize, nodeSize = _e === void 0 ? defaultNodeSize : _e, layout = uProps.layout, _f = uProps.animate, animate = _f === void 0 ? true : _f, nodeStyle = uProps.nodeStyle, edgeStyle = uProps.edgeStyle, edgeCfg = uProps.edgeCfg, markerStyle = uProps.markerStyle, _g = uProps.markerPosition, markerPosition = _g === void 0 ? 'right' : _g, _h = uProps.nodeStateStyles, nodeStateStyles = _h === void 0 ? defaultStateStyles : _h, _j = uProps.edgeStateStyles, edgeStateStyles = _j === void 0 ? defaultStateStyles : _j, _k = uProps.collapseExpand, collapseExpand = _k === void 0 ? true : _k, titleStyle = uProps.titleStyle, bodyStyle = uProps.bodyStyle, footerStyle = uProps.footerStyle, footerValueStyle = uProps.footerValueStyle, _l = uProps.showArrow, showArrow = _l === void 0 ? true : _l, _m = uProps.arrowType, arrowType = _m === void 0 ? 'vee' : _m, _o = uProps.autoFit, autoFit = _o === void 0 ? true : _o, onReady = uProps.onReady, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate;
    var container = React.useRef(null);
    var graph = React.useRef(null);
    var graphId = getGraphId(graph);
    useGraph(graphs[graphId], uProps, container);
    useEffect(function () {
        var graphSize = getGraphSize(width, height, container);
        var graph = graphs[graphId];
        if (!graph) {
            graph = new G6.TreeGraph({
                container: container.current,
                width: graphSize[0],
                height: graphSize[1],
                animate: animate,
                modes: {
                    default: behaviors,
                },
                defaultNode: {
                    type: nodeType,
                    size: nodeSize,
                    anchorPoints: nodeAnchorPoints,
                    titleStyle: titleStyle,
                    bodyStyle: bodyStyle,
                    footerStyle: footerStyle,
                    footerValueStyle: footerValueStyle,
                    markerStyle: markerStyle,
                    collapseExpand: collapseExpand,
                    markerPosition: markerPosition,
                },
                defaultEdge: {
                    type: edgeType,
                },
                nodeStateStyles: nodeStateStyles,
                edgeStateStyles: edgeStateStyles,
                layout: layout,
                fitView: autoFit,
            });
            graphs[graphId] = graph;
        }
        graph.node(function (node) {
            if (typeof nodeStyle === 'function') {
                return {
                    style: nodeStyle(node, graph),
                };
            }
            return {
                style: __assign(__assign({}, defaultNodeStyle), nodeStyle),
            };
        });
        graph.edge(function (edge) {
            if (edgeCfg) {
                return typeof edgeCfg === 'function' ? edgeCfg(edge, graph) : edgeCfg;
            }
            if (typeof edgeStyle === 'function') {
                return {
                    style: edgeStyle(edge, graph),
                };
            }
            return {
                style: __assign(__assign({ stroke: '#ccc' }, (showArrow && getDefaultEdgeArrowCfg(0, arrowType))), edgeStyle),
            };
        });
        if (collapseExpand) {
            bindDefaultEvents(graph, collapseExpand);
        }
        renderGraph(graph, data);
        if (onReady) {
            onReady(graph);
        }
        return function () {
            if (graphs[graphId]) {
                graphs[graphId].destroy();
                delete graphs[graphId];
            }
        };
    }, []);
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default IndentedTreeGraph;
