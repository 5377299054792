var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { prefix } from '../constants';
import { Log } from './log';
/**
 * 对数据进行打标，加上 level
 */
export var setFlowTag = function (data, limitLevel) {
    if (limitLevel === void 0) { limitLevel = 100; }
    var resNodes = [];
    var _a = data.nodes, nodes = _a === void 0 ? [] : _a, _b = data.edges, edges = _b === void 0 ? [] : _b;
    var levelNodes = [];
    var getTarget = function (source) {
        return edges.filter(function (item) {
            var _a;
            var used = resNodes.findIndex(function (t) { return t.id === item.target; });
            if (used !== -1 && item.source === source) {
                var loop = getSource(item.source);
                Log.warn("The data [".concat(item.source, ",").concat(item.target, ",").concat((_a = loop[0]) === null || _a === void 0 ? void 0 : _a.source, "] is in a loop, please check the rationality."));
            }
            return used === -1 && item.source === source;
        });
    };
    var getSource = function (target) {
        return edges.filter(function (item) { return item.target === target; });
    };
    edges.forEach(function (item) {
        var source = item.source;
        if (!edges.find(function (item) { return item.target === source; }))
            levelNodes.push(source);
    });
    var level = 0;
    var appendInfo = function (item) {
        var _a;
        return (_a = {},
            _a["".concat(prefix, "_level")] = level,
            _a["".concat(prefix, "_parent")] = getSource(item.id).map(function (n) { return n.source; }),
            _a["".concat(prefix, "_children")] = level < limitLevel - 1 ? getTarget(item.id).map(function (n) { return n.target; }) : [],
            _a);
    };
    if (!levelNodes.length) {
        return {
            edges: edges,
            nodes: nodes.map(function (item) { return (__assign(__assign({}, item), appendInfo(item))); }),
        };
    }
    var _loop_1 = function () {
        var currentLevelNodes = nodes.filter(function (item) { return levelNodes.includes(item.id); });
        resNodes = resNodes.concat(currentLevelNodes.map(function (item) { return (__assign(__assign({}, item), appendInfo(item))); }));
        var nextLevelNodes = [];
        levelNodes.forEach(function (source) {
            nextLevelNodes.push.apply(nextLevelNodes, getTarget(source).map(function (item) { return item.target; }));
        });
        levelNodes = nextLevelNodes;
        level += 1;
    };
    while (levelNodes.length) {
        _loop_1();
    }
    return { nodes: resNodes, edges: edges };
};
