import EventData from './event-data';
import { deepClone } from './deep-clone';
import { setTreeTag, setFlowTag, getFlowLevelData, getTreeLevelData } from './';
import { runAsyncEvent } from './async-events';
var isFlowData = function (data) { return (data === null || data === void 0 ? void 0 : data.nodes) instanceof Array && (data === null || data === void 0 ? void 0 : data.edges) instanceof Array; };
export var getRenderData = function (data, level) {
    var originData = deepClone(data);
    var tagData = originData;
    if (level) {
        if (isFlowData(data)) {
            tagData = setFlowTag(data, level);
            originData = getFlowLevelData(tagData, level);
        }
        else {
            // is tree data
            tagData = setTreeTag(data);
            originData = getTreeLevelData(tagData, level);
        }
    }
    return [originData, tagData];
};
export var renderGraph = function (graph, data, level) {
    var _a = getRenderData(data, level), originData = _a[0], tagData = _a[1];
    graph.data(originData);
    graph.set('eventData', new EventData(tagData));
    graph.render();
    // 关闭局部刷新，各种 bug
    graph.get('canvas').set('localRefresh', false);
    runAsyncEvent(graph.get('id'));
};
