var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { stateColor } from '../../constants';
import ErrorBoundary from '../../errorBoundary';
import useGraph from '../../hooks/useGraphs';
import useProps from '../../hooks/useProps';
import { ChartLoading } from '../../utils';
import { bindEvents } from './events';
import { registerFileTreeGeometries, registerFileTreeBehaviors } from './customItem';
registerFileTreeGeometries();
registerFileTreeBehaviors();
var PEM = 18;
var defaultLayout = {
    type: 'indented',
    direction: 'LR',
    isHorizontal: true,
    indent: 40,
    getHeight: function (d) {
        var _a;
        if (d.isRoot) {
            return 30;
        }
        if (d.collapsed && ((_a = d.children) === null || _a === void 0 ? void 0 : _a.length)) {
            return 36;
        }
        return 22;
    },
    getVGap: function () {
        return 10;
    },
    getWidth: function (d) {
        var _a;
        var label = ((_a = d.value) === null || _a === void 0 ? void 0 : _a.text) || ' ';
        return d.width || label.split('').length * PEM; // FIXME DO NOT get width like this
    },
};
var defaultProps = {
    nodeCfg: {
        type: 'file-tree-node',
        size: [120, 28],
        anchorPoints: [
            [0, 1],
            [1, 0.5],
        ],
        padding: [6, 12],
        nodeStateStyles: {
            hover: {
                rect: {
                    fill: '#eee',
                },
                text: {
                    fill: '#38404C',
                },
                marker: {
                    stroke: stateColor,
                },
            },
            selected: {
                rect: {
                    fill: '#EFF2FF',
                },
                text: {
                    fill: '#2F54EB',
                },
            },
        },
        label: {
            style: function (cfg) {
                if (cfg.depth === 0)
                    return { fill: '#fff' };
                return {
                    fill: 'rgba(0,0,0,.65)',
                };
            },
        },
        style: function (model) { return ({
            fill: model.depth === 0 ? '#525964' : 'transparent',
        }); },
        lineStyle: {
            lineWidth: 2,
            stroke: '#ccc',
        },
    },
    edgeCfg: {
        type: 'file-tree-edge',
        style: {
            lineWidth: 2,
            radius: 16,
        },
        edgeStateStyles: {
            hover: {
                stroke: stateColor,
            },
            selected: {
                stroke: stateColor,
            },
        },
    },
    behaviors: ['drag-canvas', 'wheel-scroll', 'hover-node', 'click-node', 'drag-branch'],
    layout: defaultLayout,
    animate: true,
    // autoFit: true,
    fitCenter: true,
    style: {
        position: 'relative',
        height: 'inherit',
        backgroundColor: '#fff',
    },
    markerCfg: {
        show: true,
    },
};
var FileTreeGraph = function (props) {
    var uProps = useProps(props, defaultProps).uProps;
    var className = uProps.className, style = uProps.style, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate, rest = __rest(uProps, ["className", "style", "loading", "loadingTemplate", "errorTemplate"]);
    var container = useGraph('TreeGraph', rest, {
        name: 'FileTreeGraph',
        bindEvents: bindEvents,
    }).container;
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default FileTreeGraph;
