export var getMarkerPosition = function (direction, size) {
    if (direction === void 0) { direction = 'right'; }
    var width = size[0], height = size[1];
    var x = 0;
    var y = 0;
    switch (direction) {
        case 'top':
            x = width / 2;
            y = 0;
            break;
        case 'right':
            x = width;
            y = height / 2;
            break;
        case 'bottom':
            x = width / 2;
            y = height;
            break;
        case 'left':
            x = 0;
            y = height / 2;
            break;
    }
    return { x: x, y: y };
};
