var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import G6 from '@antv/g6';
import baseEdge from './baseEdge';
export var registerConvLine = function () {
    // 自定义转化边
    G6.registerEdge('conv-line', __assign(__assign({}, baseEdge), { draw: function (cfg, group) {
            var startPoint = cfg.startPoint, endPoint = cfg.endPoint;
            // 直线，线条样式、箭头自定义
            var path = [
                ['M', startPoint.x, startPoint.y],
                ['L', endPoint.x, endPoint.y],
            ];
            return baseEdge.draw(cfg, group, path);
        } }));
};
