import React, { useEffect } from 'react';
import G6 from '@antv/g6';
import { ChartLoading } from '../../utils';
import ErrorBoundary from '../../errorBoundary';
import useGraph from '../hooks/useGraph';
import { registerCardNode } from '../graphs/customItems';
import { defaultNodeSize, defaultLabelCfg, defaultNodeAnchorPoints, defaultStateStyles, defaultEdgeStyle, } from './constants';
import { getGraphSize, renderMinimap, getGraphId, renderGraph, bindEvents, useProps } from './utils';
import { registerCustomItems } from './customItems';
registerCustomItems();
registerCardNode();
var defaultNodeStyle = {
    stroke: '#40a9ff',
};
var defaultLayout = {
    type: 'dagre',
    rankdir: 'TB',
    nodesepFunc: function () { return 0; },
    ranksepFunc: function () { return 0; },
    controlPoints: true,
};
var defaultProps = {
    nodeType: 'modelRect',
    edgeType: 'polyline',
    behaviors: ['zoom-canvas', 'drag-canvas'],
    nodeSize: defaultNodeSize,
    nodeLabelCfg: defaultLabelCfg,
    edgeLabelCfg: defaultLabelCfg,
    nodeAnchorPoints: defaultNodeAnchorPoints,
    layout: defaultLayout,
    nodeStyle: defaultNodeStyle,
    edgeStyle: defaultEdgeStyle,
    nodeStateStyles: defaultStateStyles,
    edgeStateStyles: defaultStateStyles,
    autoFit: true,
};
var graphs = {};
var DagreGraph = function (props) {
    var uProps = useProps(props, defaultProps);
    var data = uProps.data, className = uProps.className, style = uProps.style, width = uProps.width, height = uProps.height, _a = uProps.nodeType, nodeType = _a === void 0 ? 'modelRect' : _a, _b = uProps.edgeType, edgeType = _b === void 0 ? 'polyline' : _b, _c = uProps.behaviors, behaviors = _c === void 0 ? ['zoom-canvas', 'drag-canvas'] : _c, _d = uProps.nodeSize, nodeSize = _d === void 0 ? defaultNodeSize : _d, _e = uProps.nodeLabelCfg, nodeLabelCfg = _e === void 0 ? defaultLabelCfg : _e, _f = uProps.edgeLabelCfg, edgeLabelCfg = _f === void 0 ? defaultLabelCfg : _f, _g = uProps.nodeAnchorPoints, nodeAnchorPoints = _g === void 0 ? defaultNodeAnchorPoints : _g, _h = uProps.layout, layout = _h === void 0 ? defaultLayout : _h, minimapCfg = uProps.minimapCfg, _j = uProps.nodeStyle, nodeStyle = _j === void 0 ? defaultNodeStyle : _j, _k = uProps.edgeStyle, edgeStyle = _k === void 0 ? defaultEdgeStyle : _k, _l = uProps.nodeStateStyles, nodeStateStyles = _l === void 0 ? defaultStateStyles : _l, _m = uProps.edgeStateStyles, edgeStateStyles = _m === void 0 ? defaultStateStyles : _m, _o = uProps.autoFit, autoFit = _o === void 0 ? true : _o, graphRef = uProps.graphRef, onReady = uProps.onReady, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate;
    var container = React.useRef(null);
    var graph = React.useRef(null);
    var graphId = getGraphId(graph);
    useGraph(graphs[graphId], uProps, container);
    useEffect(function () {
        var graphSize = getGraphSize(width, height, container);
        var graph = graphs[graphId];
        if (!graph) {
            graph = new G6.Graph({
                container: container.current,
                width: graphSize[0],
                height: graphSize[1],
                modes: {
                    default: behaviors,
                },
                defaultNode: {
                    type: nodeType,
                    size: nodeSize,
                    style: nodeStyle,
                    anchorPoints: nodeAnchorPoints,
                    labelCfg: nodeLabelCfg,
                },
                defaultEdge: {
                    type: edgeType,
                    style: edgeStyle,
                    labelCfg: edgeLabelCfg,
                },
                nodeStateStyles: nodeStateStyles,
                edgeStateStyles: edgeStateStyles,
                layout: layout,
                fitView: autoFit,
            });
            graphs[graphId] = graph;
        }
        if (graphRef) {
            graphRef.current = graph;
        }
        renderMinimap(minimapCfg, graph);
        renderGraph(graph, data);
        if (onReady) {
            onReady(graph);
        }
        bindEvents(graph, props);
        return function () {
            if (graphs[graphId]) {
                graphs[graphId].destroy();
                delete graphs[graphId];
            }
        };
    }, []);
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default DagreGraph;
