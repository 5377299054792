var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { defaultFlowGraphAnchorPoints, defaultNodeSize, defaultNodeStyle, defaultStateStyles } from '../../constants';
import ErrorBoundary from '../../errorBoundary';
import useGraph from '../../hooks/useGraphs';
import useProps from '../../hooks/useProps';
import { ChartLoading } from '../../utils';
import { bindEvents } from '../file-tree-graph/events';
import { registerIndicatorGeometries } from '../flow-analysis-graph/customItem';
registerIndicatorGeometries();
var defaultLayout = {
    type: 'mindmap',
    direction: 'H',
    getId: function (d) {
        return d.id;
    },
    getHeight: function () {
        return 60;
    },
    getWidth: function () {
        return 16;
    },
    getVGap: function () {
        return 16;
    },
    getHGap: function () {
        return 100;
    },
};
var defaultProps = {
    nodeCfg: {
        type: 'indicator-card',
        size: defaultNodeSize,
        style: defaultNodeStyle,
        anchorPoints: defaultFlowGraphAnchorPoints,
        padding: 6,
        layout: 'bundled',
        nodeStateStyles: defaultStateStyles,
        label: {
            style: function (cfg, group, type) {
                var styles = {
                    icon: {
                        width: 10,
                        height: 10,
                    },
                    value: {
                        fill: '#000',
                    },
                    text: {
                        fill: '#aaa',
                    },
                };
                return type ? styles[type] : {};
            },
        },
    },
    edgeCfg: {
        type: 'cubic-horizontal',
        endArrow: {
            type: 'vee',
        },
        edgeStateStyles: defaultStateStyles,
    },
    behaviors: ['zoom-canvas', 'drag-canvas'],
    layout: defaultLayout,
    animate: true,
    autoFit: true,
    fitCenter: true,
    style: {
        position: 'relative',
        height: 'inherit',
        backgroundColor: '#fff',
    },
    level: 100,
};
var MindMapGraph = function (props) {
    var uProps = useProps(props, defaultProps).uProps;
    var className = uProps.className, style = uProps.style, loading = uProps.loading, loadingTemplate = uProps.loadingTemplate, errorTemplate = uProps.errorTemplate, rest = __rest(uProps, ["className", "style", "loading", "loadingTemplate", "errorTemplate"]);
    var container = useGraph('TreeGraph', rest, {
        name: 'MindMapGraph',
        bindEvents: bindEvents,
    }).container;
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
};
export default MindMapGraph;
