var EventMap = new Map();
export var pushAsyncEvent = function (eventId, event) {
    var events = EventMap.get(eventId);
    if (!events) {
        events = [event];
    }
    else {
        events.push(event);
    }
    EventMap.set(eventId, events);
};
export var runAsyncEvent = function (eventId) {
    var events = EventMap.get(eventId) || [];
    events.forEach(function (event) {
        if (typeof event === 'function')
            event.apply(null, arguments);
    });
    EventMap.set(eventId, []);
};
