// 类型检测
export var isType = function (value, type) {
    var toString = {}.toString;
    return toString.call(value) === "[object ".concat(type, "]");
};
export var getType = function (n) {
    return Object.prototype.toString.call(n).slice(8, -1);
};
/**
 * 深克隆
 * @param source 要深克隆的目标对象
 */
export var deepClone = function (source) {
    if (!source || typeof source !== 'object') {
        return source;
    }
    var target;
    if (Array.isArray(source)) {
        target = source.map(function (item) { return deepClone(item); });
    }
    else {
        target = {};
        Object.keys(source).forEach(function (key) {
            return (target[key] = deepClone(source[key]));
        });
    }
    return target;
};
export var clone = function (source) {
    if (!source) {
        return source;
    }
    var target = {};
    // eslint-disable-next-line guard-for-in
    for (var k in source) {
        target[k] = source[k];
    }
    return target;
};
