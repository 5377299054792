import { render } from './render';
import { setStyles } from './set-styles';
var TOOLTIP_CONTAINER_MAPPING = new Map();
export var createNode = function (children, attrs, type, styles, uuid) {
    if (attrs === void 0) { attrs = {}; }
    if (typeof styles === 'string') {
        uuid = styles;
        styles = undefined;
    }
    var mountPoint = document.createElement('div');
    Object.keys(attrs).forEach(function (key) {
        mountPoint[key] = attrs[key];
    });
    if (styles) {
        setStyles(mountPoint, styles);
    }
    if (type === 'tooltip') {
        mountPoint.setAttribute('data-uuid', uuid);
        if (TOOLTIP_CONTAINER_MAPPING.has(uuid)) {
            mountPoint = TOOLTIP_CONTAINER_MAPPING.get(uuid);
        }
        else {
            TOOLTIP_CONTAINER_MAPPING.set(uuid, mountPoint);
        }
        mountPoint.className = 'g2-tooltip';
    }
    render(children, mountPoint);
    return mountPoint;
};
