import { isFunction } from '@antv/util';
import { prefix } from '../constants';
import { createNode } from './create-node';
import { setStyles } from './set-styles';
/** 开启加载动画， 不支持同时存在多个 */
export var createFetchLoading = function (node, fetchLoading) {
    var loadingClassName = "".concat(prefix, "-antd-loading");
    if (fetchLoading) {
        var loadingTemplate = isFunction(fetchLoading) ? fetchLoading(node) : fetchLoading;
        document.body.appendChild(createNode(loadingTemplate, {
            className: loadingClassName,
        }, 'fetchloading'));
    }
    else {
        var container = document.createElement('div');
        container.className = loadingClassName;
        var styles = {
            position: 'fixed',
            left: '0',
            top: '0',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0,0,0, 0.25)',
            color: '#fff',
            fontSize: '16px',
            zIndex: 999,
        };
        setStyles(container, styles);
        var span = document.createElement('span');
        span.innerText = 'loading...';
        container.appendChild(span);
        document.body.appendChild(container);
    }
};
